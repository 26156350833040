import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Chart} from 'chart.js';
import Annotation from 'chartjs-plugin-annotation';
import {BaseChartDirective} from 'ng2-charts';
import {
  DeviceManagementImplService,
  ImpedanceManagementControllerImplService
} from 'src/generated/hydroponics-device-management-api/services';
import {interval, Subscription} from 'rxjs';
import {FormControl, FormGroup} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {
  DeviceDto,
  ImpedanceSensorDataDto,
  ImpedanceSensorDataResponse
} from 'src/generated/hydroponics-device-management-api/models';
import {ExcelService} from '../../../../util/excel.service';
import * as moment from 'moment-timezone';
import {MatDialog} from '@angular/material/dialog';
import {environment} from "../../../../../environments/environment";
import {CookieService} from "ngx-cookie-service";
import {
  ImpedanceDataDialogData,
  ImpedanceSensoDataDialogComponent
} from "../impedance-sensor-data-dialog/impedance-senso-data-dialog.component";
import {ExportComponent} from "../export/export.component";
import {noDataPlugin} from "../../../../util/chart-settings";
import {MessageService} from "../../../../service/message.service";
import {ImpedanceManualMeasureComponent} from "../impedance-manual-measure/impedance-manual-measure.component";
import {SensorDataShowManualMeasurementEvent} from "../../sensorboard/sensor-data/sensor-data.component";
import {DateService} from "../../../../util/date.service";

export interface SensorDataShowInTableEvent {
  dataType: String;
}

@Component({
  selector: 'app-impedance-sensor',
  templateUrl: './impedance-sensor.component.html',
  styleUrls: ['./impedance-sensor.component.sass']
})
export class ImpedanceSensorComponent implements OnInit, OnDestroy {

  @Input() device: DeviceDto;


  private static readonly COOKIE_PICKED_FREQUENCES = 'picked-frequences';
  public rangeEndMaxDate = moment();

  dateRange = new FormGroup({
    start: new FormControl(moment().startOf('hour').subtract(environment.substractAmount, "hours")),
    end: new FormControl(),
  });


  public frequencies = new FormControl('');
  public frequenciesList: number[] = [];
  public pickedFrequencies: number[] = [];


  public impedanceChartData: Array<any> = [];
  public impedancePhaseChartData: Array<any> = [];

  public impedanceSensorDataList: ImpedanceSensorDataDto[];
  public firstSensorDataTimestamp: Date;


  constructor(
    private deviceManagementImplService: DeviceManagementImplService,
    private impedanceManagerControllerImplService: ImpedanceManagementControllerImplService,
    private excel: ExcelService,
    private translate: TranslateService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private cookieService: CookieService,
    private messageService: MessageService
  ) {
  }

  private updateSubscription: Subscription;
  private label: string;

  ngOnInit(): void {

    Chart.register(noDataPlugin);
    Chart.register(Annotation);

    this.getFrequencies();

    this.loadFirstSensorDataTimestamp();

    //Auto refresh
    this.updateSubscription = interval(60 * 1000).subscribe(() => {
      this.loadImpedanceSensorData();
    });
  }

  loadFirstSensorDataTimestamp() {
    this.impedanceManagerControllerImplService.getFirstSensorDataTimestamp1({
      deviceId: this.device.deviceId
    }).subscribe({
      next: firstSensorDataTimestamp => {
        if (firstSensorDataTimestamp) {
          this.firstSensorDataTimestamp = new Date(firstSensorDataTimestamp);
        }

      }
    });
  }


  public frequenceSelectionChanged() {
    this.loadImpedanceSensorData();
    this.saveSelectedFrequences();
  }

  private getStartTime() {
    return this.dateRange.value.start.tz('Europe/Budapest').format('yyyy-MM-DDTHH:mm:ss.SSSZ');
  }

  private getEndTime() {
    return this.dateRange.value.end ? this.dateRange.value.end.tz('Europe/Budapest').format('yyyy-MM-DDTHH:mm:ss.SSSZ') : null;
  }

  public loadImpedanceSensorData() {

    if (this.dateRange.value.start > this.dateRange.value.end) {
      this.dateRange.controls['end'].setValue(undefined);
    }

    const startTime = this.getStartTime();
    const endTime = this.getEndTime();


    this.impedanceManagerControllerImplService.getImpedanceSensorData({
      start: startTime, end: endTime, deviceId: this.device.deviceId, body: this.pickedFrequencies
    }).subscribe({
        next: impedanceSensorDataList => {
          this.impedanceSensorDataList = impedanceSensorDataList;
          this.impedanceChartData = this.fillChartData(impedanceSensorDataList, 'impedance');
          this.impedancePhaseChartData = this.fillChartData(impedanceSensorDataList, 'impedancePhase');
        },
        error: e => {
          this.messageService.error(e.message, true);
        },
        complete: () => {
          this.rangeEndMaxDate = moment();
        }
      }
    )
  }

  private fillChartData(impedanceSensorDataList: Array<ImpedanceSensorDataDto>, value: string) {
    let chartData = [];

    if (impedanceSensorDataList.length != 0) {
      let chartDataByFrequenyMap = new Map<number, any[]>;
      impedanceSensorDataList.forEach(impedanceSensorData => {
          impedanceSensorData.impedanceSensorDataParams.forEach(impedanceSensorDataParam => {
            if (chartDataByFrequenyMap.get(impedanceSensorDataParam.frequency) == null) {
              chartDataByFrequenyMap.set(impedanceSensorDataParam.frequency, []);
            }
            chartDataByFrequenyMap.get(impedanceSensorDataParam.frequency).push({
              x: new Date(impedanceSensorData.timestamp),
              y: impedanceSensorDataParam[value]
            });
          })
        }
      )
      chartDataByFrequenyMap.forEach((value, key) => {
          chartData.push({
            //dátum szerint rendezni kell
            data: value.sort(((a, b) => {
              return <any>new Date(b.x) - <any>new Date(a.x)
            })),
            label: key,
            pointRadius: 0
          })
        }
      )
    }

    return chartData;
  }


  public getFrequencies() {
    this.impedanceManagerControllerImplService.getFrequencies().subscribe(value => {
      value.forEach(value1 => this.frequenciesList.push(Number(value1)));
      let cookiePickedFrequences = this.cookieService.get(ImpedanceSensorComponent.COOKIE_PICKED_FREQUENCES);
      if (cookiePickedFrequences != null) {
        this.pickedFrequencies = JSON.parse(cookiePickedFrequences);
      }
      this.frequenceSelectionChanged();
    });
  }


  public clearEndDate() {
    this.dateRange.controls['end'].setValue(undefined);
  }

  public exportImpedance(impedancePickedFrequency: number[]) {
    const startTime = this.dateRange.value.start.tz('Europe/Budapest').format('yyyy-MM-DDTHH:mm:ss.SSSZ');
    const endTime = this.dateRange.value.end ? this.dateRange.value.end.tz('Europe/Budapest').format('yyyy-MM-DDTHH:mm:ss.SSSZ') : null;

    this.impedanceManagerControllerImplService.getImpedanceSensorDataWithOriginalData({
      start: startTime, end: endTime, deviceId: this.device.deviceId, body: impedancePickedFrequency
    }).subscribe(value => {
      let impedanceChartDataWithRawData: Array<ImpedanceSensorDataResponse>;
      impedanceChartDataWithRawData = value.sort(((a, b) => {
        return <any>new Date(b.timestamp) - <any>new Date(a.timestamp);
      }));

      let transformedImpedanceSensorDataResponse: any[][] = [];
      let headerRow: any[] = ["timestamp"];
      impedancePickedFrequency.forEach(frequency => {
        headerRow.push(frequency + "_raw");
        headerRow.push(frequency);
      });
      transformedImpedanceSensorDataResponse.push(headerRow);
      impedanceChartDataWithRawData.forEach(ImpedanceSensorDataResponse => {
          console.log("ImpedanceSensorDataResponse: " + JSON.stringify(ImpedanceSensorDataResponse));
          let aRow: any[] = [];
          aRow.push(ImpedanceSensorDataResponse.timestamp);
          ImpedanceSensorDataResponse.dataRawPair.forEach(ImpedanceSensorDataRawPairDto => {
            aRow.push(ImpedanceSensorDataRawPairDto.rawValue);
            aRow.push(ImpedanceSensorDataRawPairDto.calibratedValue);
          })
          transformedImpedanceSensorDataResponse.push(aRow);
        }
      );
      this.excel.exportAsExcelFileWithMatrix(transformedImpedanceSensorDataResponse, 'hyroponics-impedance-data-' + new Date().toISOString());
    });
  }

  public exportImpedancePhase(impedancePhasePickedFrequency: number[]) {
    const startTime = this.dateRange.value.start.tz('Europe/Budapest').format('yyyy-MM-DDTHH:mm:ss.SSSZ');
    const endTime = this.dateRange.value.end ? this.dateRange.value.end.tz('Europe/Budapest').format('yyyy-MM-DDTHH:mm:ss.SSSZ') : null;


    this.impedanceManagerControllerImplService.getImpedanceSensorData({
      start: startTime, end: endTime, deviceId: this.device.deviceId, body: impedancePhasePickedFrequency
    }).subscribe(
      impedanceSensorDataList => {
        let exportDataMap = new Map<string, any>;
        let chartData = this.fillChartData(impedanceSensorDataList, 'impedancePhase');
        chartData.forEach(data => {
          data.data.forEach(dataf => {
            let timestamp = moment(dataf.x).format('yyyy-MM-DD HH:mm:ss');
            if (exportDataMap.get(timestamp) == null) {
              exportDataMap.set(timestamp, {});
            }
            let currdta = exportDataMap.get(timestamp);
            currdta[data.label] = dataf.y;
          });

        });
        let exportData: any[][] = [];
        let headerRow: any[] = ["timestamp"];
        impedancePhasePickedFrequency.forEach(frequency => {
          headerRow.push(frequency);
        });
        exportData.push(headerRow);

        exportDataMap.forEach((value, key) => {
          let aRow: any[] = [];
          aRow.push(key);
          Object.values(value).forEach(value1 => aRow.push(value1));
          exportData.push(aRow);
        });
        this.excel.exportAsExcelFileWithMatrix(exportData, 'hyroponics-impedancePhase-data-' + new Date().toISOString());
      }
    )
  }

  public openExport(event: SensorDataShowInTableEvent) {
    let dialogRef = this.dialog.open(ExportComponent, {
      data: {
        frequenciesList: this.frequenciesList,
        pickedFrequenciesList: this.pickedFrequencies,
        dataType: event.dataType
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != null)
        switch (result["dataType"]) {
          case "impedance": {
            this.exportImpedance(result["pickedFrequencies"]);
            break;
          }
          case "impedancePhase": {
            this.exportImpedancePhase(result["pickedFrequencies"]);
            break;
          }
        }
    });
  }

  public showInTable(event: SensorDataShowInTableEvent) {
    this.impedanceManagerControllerImplService.getImpedanceSensorDataWithOriginalData({
      start: DateService.getLocalISOString(this.dateRange.value.start.toDate()),
      end: DateService.getLocalISOString(this.dateRange.value.end ? this.dateRange.value.end.toDate() : new Date()),
      deviceId: this.device.deviceId,
      body: this.pickedFrequencies
    }).subscribe(value => {
      let datasource: ImpedanceDataDialogData[] = [];

      value.forEach(impedanceSensorDataResponse => {
        let tmpImpedanceDataDialogData: ImpedanceDataDialogData = {} as ImpedanceDataDialogData;
        tmpImpedanceDataDialogData.timestamp = DateService.formatDate(new Date(impedanceSensorDataResponse.timestamp));

        impedanceSensorDataResponse.dataRawPair.forEach(impedanceSensorDataRawPairDto => {
          const frequency = impedanceSensorDataRawPairDto.frequency.toString();
          tmpImpedanceDataDialogData[frequency] = impedanceSensorDataRawPairDto.calibratedValue;
          tmpImpedanceDataDialogData[frequency + '_raw'] = impedanceSensorDataRawPairDto.rawValue;
        });

        datasource.push(tmpImpedanceDataDialogData);
      });

      let frequencyPairs = [];
      //rendezzük az oszlopokat frekvenciák szerint (megjelenítés miatt), majd a frekvenciákat hozzá adjuk a frequencyPairs-hoz,
      // hogy megjelenjen oszlopként a felületen
      this.pickedFrequencies.sort()
        .forEach(frequency => {
          frequencyPairs.push(frequency.toString());
          frequencyPairs.push(frequency + '_raw');
        });

      datasource.sort((a, b) => b.timestamp.localeCompare(a.timestamp));

      this.dialog.open(ImpedanceSensoDataDialogComponent, {
        maxWidth: '95vw',
        maxHeight: '95vw',
        width: '95%',
        height: '95%',
        panelClass: 'sensor-data-modal',
        data: {
          deviceId: this.device.deviceId,
          datasource: datasource,
          frequencies: frequencyPairs,
        },
      });

    });
  }

  public showManualMeasurements(event: SensorDataShowManualMeasurementEvent) {
    this.impedanceManagerControllerImplService.getManualMeasurementDataHistory({
      start: this.getStartTime(),
      end: this.getEndTime(),
      deviceId: this.device.deviceId,
      body: this.pickedFrequencies
    }).subscribe(value => {
      let datasource: ImpedanceDataDialogData[] = [];

      value.forEach(impedanceSensorDataDto => {
        let currentTimestamp = DateService.formatDate(new Date(impedanceSensorDataDto.timestamp));

        let tmpImpedanceDataDialogData: ImpedanceDataDialogData = {} as ImpedanceDataDialogData;
        tmpImpedanceDataDialogData.timestamp = currentTimestamp;
        impedanceSensorDataDto.impedanceSensorDataParams.forEach(impedanceSensorDataParam => {
          const frequency = impedanceSensorDataParam.frequency.toString();
          tmpImpedanceDataDialogData[frequency] = impedanceSensorDataParam[event.dataType.toString()];
        });

        datasource.push(tmpImpedanceDataDialogData);
      });

      let frequencies = [];
      //rendezzük az oszlopokat frekvenciák szerint (megjelenítés miatt), majd a frekvenciákat hozzá adjuk a frequencyPairs-hoz,
      // hogy megjelenjen oszlopként a felületen
      this.pickedFrequencies.sort()
        .forEach(frequency => {
          frequencies.push(frequency.toString());
        });

      this.dialog.open(ImpedanceSensoDataDialogComponent, {
        maxWidth: '95vw',
        maxHeight: '95vw',
        width: '95%',
        height: '95%',
        panelClass: 'sensor-data-modal',
        data: {
          datasource: datasource,
          frequencies: frequencies,
          deviceId: this.device.deviceId
        },
      });
    })
  }

  @ViewChild(BaseChartDirective)
  chart ?: BaseChartDirective;

  //Felületen kiválasztott frekvenciák cookie-ba mentése
  saveSelectedFrequences() {
    this.cookieService.set(ImpedanceSensorComponent.COOKIE_PICKED_FREQUENCES, JSON.stringify(this.pickedFrequencies));
  }

  deSelectAllFrequencies() {
    this.pickedFrequencies = [];
    this.frequenceSelectionChanged();
  }

  selectAllFrequencies() {
    this.pickedFrequencies = this.frequenciesList;
    this.frequenceSelectionChanged();
  }

  openManualMeasure() {
    this.dialog.open(ImpedanceManualMeasureComponent, {data: {deviceId: this.device.deviceId}});
  }

  ngOnDestroy(): void {
    this.updateSubscription.unsubscribe();
  }

}
