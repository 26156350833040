import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  WorksheetControllerImplService
} from "../../../generated/hydroponics-device-management-api/services/worksheet-controller-impl.service";
import {
  SaveWorksheetCommentRequestDto
} from "../../../generated/hydroponics-device-management-api/models/save-worksheet-comment-request-dto";
import {WorksheetCommentDto} from "../../../generated/hydroponics-device-management-api/models/worksheet-comment-dto";
import {WorkTypeEnum} from "../worksheet-create/worksheet-create.component";
import {LocationReferenceDto} from "../../../generated/hydroponics-device-management-api/models/location-reference-dto";
import {DateService} from "../../util/date.service";
import {EventDto} from "../../../generated/hydroponics-device-management-api/models/event-dto";
import {WorksheetDto} from "../../../generated/hydroponics-device-management-api/models/worksheet-dto";

@Component({
  selector: 'app-worksheet',
  templateUrl: './worksheet.component.html',
  styleUrls: ['./worksheet.component.sass']
})
export class WorksheetComponent implements OnInit {
  @Input() workElement: WorksheetDto|EventDto;
  @Input() isExtended: boolean = false;
  @Output() onWorksheetChange: EventEmitter<number> = new EventEmitter();
  inProcess: boolean = false;
  comment: string;
  closeWorksheetOnSave: boolean = false;
  comments: Array<WorksheetCommentDto>;
  locationName: string;

  protected readonly WorkTypeEnum = WorkTypeEnum;


  constructor(public worksheetControllerImplService: WorksheetControllerImplService,
              public dateService: DateService,) {
  }

  ngOnInit(): void {
    if(this.workElement.workElementTypeEnum == WorkTypeEnum.WORKSHEET && this.isExtended){
      this.getComments();
    }
    this.concatlocationNames()
  }

  concatlocationNames() {
    let locationReferenceDto: LocationReferenceDto;

    locationReferenceDto = this.workElement.device.locationReferenceDTO;

    const locationNames: string[] = [];

    while (locationReferenceDto?.name) {
      locationNames.push(locationReferenceDto.name);
      locationReferenceDto = locationReferenceDto.parent;
    }

    this.locationName = locationNames.join(' - ');
  }

  worksheetInProcess(){
    this.inProcess = true;
    this.comment = null;
    this.closeWorksheetOnSave = null;
  }

  saveComment(){
    let request : SaveWorksheetCommentRequestDto = {};
    request.comment = this.comment;
    request.worksheetId = (<WorksheetDto>this.workElement).id;
    this.worksheetControllerImplService.createWorksheetComment({body: request}).subscribe(next => {
      if (this.closeWorksheetOnSave && this.closeWorksheetOnSave == true){
        this.worksheetControllerImplService.closeWorksheet({worksheetId: request.worksheetId}).subscribe(next => {
          this.inProcess = false;
          this.onWorksheetChange.emit((<WorksheetDto>this.workElement).id);
        })
      } else {
        this.inProcess = false;
        this.onWorksheetChange.emit((<WorksheetDto>this.workElement).id);
      }
    });
  }

  getComments(){
    this.worksheetControllerImplService.getWorksheetComments({worksheetId: (<WorksheetDto>this.workElement).id}).subscribe(comments => {
      this.comments = comments;
    })
  }

  canSaveComment(): boolean {
    return !(this.inProcess && this.closeWorksheetOnSave || this.comment);
  }
}
