/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreateDeviceOperationFlowRequest } from '../models/create-device-operation-flow-request';
import { DeviceOperationFlowDto } from '../models/device-operation-flow-dto';
import { DeviceOperationFlowDefinitionDto } from '../models/device-operation-flow-definition-dto';
import { PageDeviceOperationFlowDto } from '../models/page-device-operation-flow-dto';
import { Pageable } from '../models/pageable';

@Injectable({
  providedIn: 'root',
})
export class DeviceOperationFlowControllerImplService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation cancelDeviceOperationFlow
   */
  static readonly CancelDeviceOperationFlowPath = '/device-operation-flow/cancel';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cancelDeviceOperationFlow()` instead.
   *
   * This method doesn't expect any request body.
   */
  cancelDeviceOperationFlow$Response(params: {
    deviceOperationFlowId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, DeviceOperationFlowControllerImplService.CancelDeviceOperationFlowPath, 'post');
    if (params) {
      rb.query('deviceOperationFlowId', params.deviceOperationFlowId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `cancelDeviceOperationFlow$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  cancelDeviceOperationFlow(params: {
    deviceOperationFlowId: number;
    context?: HttpContext
  }
): Observable<void> {

    return this.cancelDeviceOperationFlow$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation createDeviceOperationFlow
   */
  static readonly CreateDeviceOperationFlowPath = '/device-operation-flow/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createDeviceOperationFlow()` instead.
   *
   * This method doesn't expect any request body.
   */
  createDeviceOperationFlow$Response(params: {
    request: CreateDeviceOperationFlowRequest;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<DeviceOperationFlowDto>> {

    const rb = new RequestBuilder(this.rootUrl, DeviceOperationFlowControllerImplService.CreateDeviceOperationFlowPath, 'post');
    if (params) {
      rb.query('request', params.request, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DeviceOperationFlowDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createDeviceOperationFlow$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  createDeviceOperationFlow(params: {
    request: CreateDeviceOperationFlowRequest;
    context?: HttpContext
  }
): Observable<DeviceOperationFlowDto> {

    return this.createDeviceOperationFlow$Response(params).pipe(
      map((r: StrictHttpResponse<DeviceOperationFlowDto>) => r.body as DeviceOperationFlowDto)
    );
  }

  /**
   * Path part for operation getInProgressStepDefintions
   */
  static readonly GetInProgressStepDefintionsPath = '/device-operation-flow/in-progress-definitions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getInProgressStepDefintions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInProgressStepDefintions$Response(params: {
    deviceOperationFlowDefinitionId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<string>>> {

    const rb = new RequestBuilder(this.rootUrl, DeviceOperationFlowControllerImplService.GetInProgressStepDefintionsPath, 'get');
    if (params) {
      rb.query('deviceOperationFlowDefinitionId', params.deviceOperationFlowDefinitionId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getInProgressStepDefintions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInProgressStepDefintions(params: {
    deviceOperationFlowDefinitionId: number;
    context?: HttpContext
  }
): Observable<Array<string>> {

    return this.getInProgressStepDefintions$Response(params).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }

  /**
   * Path part for operation getDeviceOperationFlow
   */
  static readonly GetDeviceOperationFlowPath = '/device-operation-flow/flow-by-id';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDeviceOperationFlow()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDeviceOperationFlow$Response(params: {
    id: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<DeviceOperationFlowDto>> {

    const rb = new RequestBuilder(this.rootUrl, DeviceOperationFlowControllerImplService.GetDeviceOperationFlowPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DeviceOperationFlowDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDeviceOperationFlow$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDeviceOperationFlow(params: {
    id: number;
    context?: HttpContext
  }
): Observable<DeviceOperationFlowDto> {

    return this.getDeviceOperationFlow$Response(params).pipe(
      map((r: StrictHttpResponse<DeviceOperationFlowDto>) => r.body as DeviceOperationFlowDto)
    );
  }

  /**
   * Path part for operation getDeviceOperationFlowDefinitions
   */
  static readonly GetDeviceOperationFlowDefinitionsPath = '/device-operation-flow/definitions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDeviceOperationFlowDefinitions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDeviceOperationFlowDefinitions$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<DeviceOperationFlowDefinitionDto>>> {

    const rb = new RequestBuilder(this.rootUrl, DeviceOperationFlowControllerImplService.GetDeviceOperationFlowDefinitionsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DeviceOperationFlowDefinitionDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDeviceOperationFlowDefinitions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDeviceOperationFlowDefinitions(params?: {
    context?: HttpContext
  }
): Observable<Array<DeviceOperationFlowDefinitionDto>> {

    return this.getDeviceOperationFlowDefinitions$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DeviceOperationFlowDefinitionDto>>) => r.body as Array<DeviceOperationFlowDefinitionDto>)
    );
  }

  /**
   * Path part for operation getDeviceOperationFlowDefinitionByName
   */
  static readonly GetDeviceOperationFlowDefinitionByNamePath = '/device-operation-flow/by-name';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDeviceOperationFlowDefinitionByName()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDeviceOperationFlowDefinitionByName$Response(params: {
    name: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<DeviceOperationFlowDefinitionDto>> {

    const rb = new RequestBuilder(this.rootUrl, DeviceOperationFlowControllerImplService.GetDeviceOperationFlowDefinitionByNamePath, 'get');
    if (params) {
      rb.query('name', params.name, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DeviceOperationFlowDefinitionDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDeviceOperationFlowDefinitionByName$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDeviceOperationFlowDefinitionByName(params: {
    name: string;
    context?: HttpContext
  }
): Observable<DeviceOperationFlowDefinitionDto> {

    return this.getDeviceOperationFlowDefinitionByName$Response(params).pipe(
      map((r: StrictHttpResponse<DeviceOperationFlowDefinitionDto>) => r.body as DeviceOperationFlowDefinitionDto)
    );
  }

  /**
   * Path part for operation getDeviceOperationFlowsToDefinition
   */
  static readonly GetDeviceOperationFlowsToDefinitionPath = '/device-operation-flow/by-definition';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDeviceOperationFlowsToDefinition()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDeviceOperationFlowsToDefinition$Response(params: {
    deviceOperationFlowDefinitionId: number;
    pageable: Pageable;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<PageDeviceOperationFlowDto>> {

    const rb = new RequestBuilder(this.rootUrl, DeviceOperationFlowControllerImplService.GetDeviceOperationFlowsToDefinitionPath, 'get');
    if (params) {
      rb.query('deviceOperationFlowDefinitionId', params.deviceOperationFlowDefinitionId, {});
      rb.query('pageable', params.pageable, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PageDeviceOperationFlowDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDeviceOperationFlowsToDefinition$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDeviceOperationFlowsToDefinition(params: {
    deviceOperationFlowDefinitionId: number;
    pageable: Pageable;
    context?: HttpContext
  }
): Observable<PageDeviceOperationFlowDto> {

    return this.getDeviceOperationFlowsToDefinition$Response(params).pipe(
      map((r: StrictHttpResponse<PageDeviceOperationFlowDto>) => r.body as PageDeviceOperationFlowDto)
    );
  }

}
