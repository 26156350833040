/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { EventDto } from '../models/event-dto';
import { EventRequestDto } from '../models/event-request-dto';
import { EventTypeDto } from '../models/event-type-dto';
import { PageEventDto } from '../models/page-event-dto';
import { Pageable } from '../models/pageable';
import { SaveEventDataRequest } from '../models/save-event-data-request';
import { UpdateEventDataRequest } from '../models/update-event-data-request';

@Injectable({
  providedIn: 'root',
})
export class EventManagerControllerImplService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation updateEvent
   */
  static readonly UpdateEventPath = '/events/update-event';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateEvent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateEvent$Response(params: {
    context?: HttpContext
    body: UpdateEventDataRequest
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, EventManagerControllerImplService.UpdateEventPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateEvent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateEvent(params: {
    context?: HttpContext
    body: UpdateEventDataRequest
  }
): Observable<void> {

    return this.updateEvent$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation saveEvent
   */
  static readonly SaveEventPath = '/events/save-event';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveEvent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveEvent$Response(params: {
    context?: HttpContext
    body: SaveEventDataRequest
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, EventManagerControllerImplService.SaveEventPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `saveEvent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveEvent(params: {
    context?: HttpContext
    body: SaveEventDataRequest
  }
): Observable<void> {

    return this.saveEvent$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getRecentEvents
   */
  static readonly GetRecentEventsPath = '/events/get-recent-events';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRecentEvents()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getRecentEvents$Response(params: {
    context?: HttpContext
    body: EventRequestDto
  }
): Observable<StrictHttpResponse<Array<EventDto>>> {

    const rb = new RequestBuilder(this.rootUrl, EventManagerControllerImplService.GetRecentEventsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<EventDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getRecentEvents$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getRecentEvents(params: {
    context?: HttpContext
    body: EventRequestDto
  }
): Observable<Array<EventDto>> {

    return this.getRecentEvents$Response(params).pipe(
      map((r: StrictHttpResponse<Array<EventDto>>) => r.body as Array<EventDto>)
    );
  }

  /**
   * Path part for operation getEvents
   */
  static readonly GetEventsPath = '/events/get-events';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEvents()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getEvents$Response(params: {
    pageable: Pageable;
    context?: HttpContext
    body: EventRequestDto
  }
): Observable<StrictHttpResponse<PageEventDto>> {

    const rb = new RequestBuilder(this.rootUrl, EventManagerControllerImplService.GetEventsPath, 'post');
    if (params) {
      rb.query('pageable', params.pageable, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PageEventDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEvents$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getEvents(params: {
    pageable: Pageable;
    context?: HttpContext
    body: EventRequestDto
  }
): Observable<PageEventDto> {

    return this.getEvents$Response(params).pipe(
      map((r: StrictHttpResponse<PageEventDto>) => r.body as PageEventDto)
    );
  }

  /**
   * Path part for operation getAllEvents
   */
  static readonly GetAllEventsPath = '/events/get-all-events';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllEvents()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getAllEvents$Response(params: {
    context?: HttpContext
    body: EventRequestDto
  }
): Observable<StrictHttpResponse<Array<EventDto>>> {

    const rb = new RequestBuilder(this.rootUrl, EventManagerControllerImplService.GetAllEventsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<EventDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllEvents$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getAllEvents(params: {
    context?: HttpContext
    body: EventRequestDto
  }
): Observable<Array<EventDto>> {

    return this.getAllEvents$Response(params).pipe(
      map((r: StrictHttpResponse<Array<EventDto>>) => r.body as Array<EventDto>)
    );
  }

  /**
   * Path part for operation getAllEventTypes
   */
  static readonly GetAllEventTypesPath = '/events/event-types';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllEventTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllEventTypes$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<EventTypeDto>>> {

    const rb = new RequestBuilder(this.rootUrl, EventManagerControllerImplService.GetAllEventTypesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<EventTypeDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllEventTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllEventTypes(params?: {
    context?: HttpContext
  }
): Observable<Array<EventTypeDto>> {

    return this.getAllEventTypes$Response(params).pipe(
      map((r: StrictHttpResponse<Array<EventTypeDto>>) => r.body as Array<EventTypeDto>)
    );
  }

  /**
   * Path part for operation getEventsToDevice
   */
  static readonly GetEventsToDevicePath = '/events/data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEventsToDevice()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEventsToDevice$Response(params: {
    deviceId: string;
    pageable: Pageable;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<PageEventDto>> {

    const rb = new RequestBuilder(this.rootUrl, EventManagerControllerImplService.GetEventsToDevicePath, 'get');
    if (params) {
      rb.query('deviceId', params.deviceId, {});
      rb.query('pageable', params.pageable, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PageEventDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEventsToDevice$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEventsToDevice(params: {
    deviceId: string;
    pageable: Pageable;
    context?: HttpContext
  }
): Observable<PageEventDto> {

    return this.getEventsToDevice$Response(params).pipe(
      map((r: StrictHttpResponse<PageEventDto>) => r.body as PageEventDto)
    );
  }

  /**
   * Path part for operation deleteEvent
   */
  static readonly DeleteEventPath = '/events/delete/{eventId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteEvent()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteEvent$Response(params: {
    eventId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, EventManagerControllerImplService.DeleteEventPath, 'delete');
    if (params) {
      rb.path('eventId', params.eventId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteEvent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteEvent(params: {
    eventId: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.deleteEvent$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
