<h5 class="alert-user">{{ 'hydroponics.dashboard.userWarning' | translate }}</h5>
<app-alert></app-alert>

<app-chart-filter
  [visible]="false"
  (onDateChange)="onDateChange($event)"
  (onAttributeChange)="onAttributeChange($event)"
  (onLocationChange)="onLocationChange($event)"
  (onFrequenciesChange)="onFrequenciesChange($event)"
  [deviceTypes]="[DeviceTypeEnum.SENSORBOARD]">
</app-chart-filter>
<div>
  <div [ngClass]="{'dashboard-container': true}">
    <app-sensor-charts
      [isFavOnly]="true"
      [operationTypes]="[OperationType.OPTIMAL_ATTRIBUTE,OperationType.DOWNLOAD_AUTO,OperationType.DOWNLOAD_MANUAL, OperationType.M_MEASURE]"
      [chartFilterChangeSubjectSensor]="chartFilterChangeSubjectSensor"
      [chartFilterFrequenciesChangeSubjectSensor]="chartFilterFrequenciesChangeSubjectSensor"
      [chartFilterAttributeChangeSubjectSensor]="chartFilterAttributeChangeSubjectSensor">
    </app-sensor-charts>
    <app-worksheet-event-list
      [chartFilterChangeSubjectSensor]="chartFilterChangeSubjectSensor">
    </app-worksheet-event-list>
  </div>
</div>
