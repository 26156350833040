import { Component, OnInit } from '@angular/core';
import {ProfileType} from "../plant-profile-attribute-manager/plant-profile-attribute-manager.component";

@Component({
  selector: 'app-plant-profile',
  templateUrl: './plant-profile.component.html',
  styleUrls: ['./plant-profile.component.sass']
})
export class PlantProfileComponent implements OnInit {
  constructor() {
  }

  ngOnInit(): void {

  }

  protected readonly ProfileType = ProfileType;
}
