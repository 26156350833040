<div class="chart-container">
  <ng-container *hasRole="'calibration'">
    <button class="calibrationButton" *ngIf="canBeCalibrated" mat-raised-button color="primary" (click)="openDialog()">
      {{'hydroponics.common.calibration' | translate}}
    </button>
  </ng-container>
  <h3 class="title">{{dataType}}</h3>

  <div class="chart-actions">
    <button mat-icon-button (click)="showManualMeasurements()"
            [matTooltip]="'hydroponics.dashboard.manualMeasureReport' | translate">
      <img
        class="icon"
        src="assets/img/microscope-icon.png"
      />
    </button>
    <button mat-icon-button #tooltip="matTooltip" [matTooltip]="'hydroponics.dashboard.exportToExcel' | translate"
            (click)="openExportComponent()">
      <mat-icon>save</mat-icon>
    </button>
    <button mat-icon-button #tooltip="matTooltip" [matTooltip]="'hydroponics.dashboard.showInTable' | translate"
            (click)="showInTable()">
      <mat-icon>list</mat-icon>
    </button>
  </div>
  <canvas baseChart
          [datasets]="chartData"
          [options]="chartOptions"
          type="line"></canvas>
</div>
