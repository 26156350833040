import {Injectable} from "@angular/core";
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LoadAlertsAndWorksheetsNumberService {
  private eventSubject = new Subject<void>();

  event$ = this.eventSubject.asObservable();

  emitEvent() {
    this.eventSubject.next();
  }
}
