<div class="dashboard">
  <mat-form-field appearance="fill" class="device-selector">
    <mat-label>Choose device:</mat-label>
    <mat-select [formControl]="devicesForm" [(value)]="this.device" (valueChange)="deviceSelected">
      <mat-option *ngFor="let device of this.device.deviceGroup.devices"
                  [value]="device">{{device.searchName}}</mat-option>
    </mat-select>

  </mat-form-field>
  <app-device-parameters [device]=this.device></app-device-parameters>
  <app-device-operations [device]=this.device></app-device-operations>
</div>


