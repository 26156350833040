import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import {RouterModule} from "@angular/router";
import {TranslateModule, TranslatePipe, TranslateService} from "@ngx-translate/core";
import {MatIconModule} from "@angular/material/icon";
import { AppMenuComponent } from './app-menu/app-menu.component';
import {MenuModule} from "primeng/menu";

@NgModule({
  declarations: [HeaderComponent, AppMenuComponent],
  exports: [HeaderComponent, AppMenuComponent],
    imports: [CommonModule, RouterModule, TranslateModule, MatIconModule, MenuModule],
})
export class TemplateModule {}
