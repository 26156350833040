import {Component, Input, OnInit} from '@angular/core';

import {NutritionDispenserSubstanceReloadHistoryDto} from "../../../../generated/hydroponics-device-management-api/models/nutrition-dispenser-substance-reload-history-dto";
import {NutritionDispenserManagementImplService} from "../../../../generated/hydroponics-device-management-api/services/nutrition-dispenser-management-impl.service";
import {DeviceDto} from "../../../../generated/hydroponics-device-management-api/models/device-dto";
import {PageEvent} from "@angular/material/paginator";
import {MatTableDataSource} from "@angular/material/table";
import {DateService} from "../../../util/date.service";
import {MatDialog} from "@angular/material/dialog";
import {RefillNutritionDispenserDto} from "../../../../generated/hydroponics-device-management-api/models/refill-nutrition-dispenser-dto";
import {NutritionDispenserService} from "../nutrition-dispenser/nutrition-dispenser.service";

@Component({
  selector: 'app-device-parameters',
  templateUrl: './device-parameters.component.html',
  styleUrls: ['./device-parameters.component.sass']
})
export class DeviceParametersComponent implements OnInit {
  _device: DeviceDto;

  @Input() set device(device: DeviceDto) {
    this._device = device;
    this.refreshData();
  }

  get device(): DeviceDto {
    return this._device;
  }

  constructor(private nutritionDispenserManagementImplService: NutritionDispenserManagementImplService, public dateService: DateService, public dialog: MatDialog,
              private nutritionDispenserService: NutritionDispenserService) {
  }

  ngOnInit(): void {
    this.refreshData();
  }

  refreshData() {
    this.getNutritionDeviceCurrentParameters();
    this.getNutritionDispenserSubstanceReloadHistory();
  }

  isLoading = false;
  totalRows = 0;
  pageSize = 5;
  currentPage = 0;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  public nutritionDispenserDeviceDto: RefillNutritionDispenserDto = {};
  public newNutritionDispenserDeviceDto: RefillNutritionDispenserDto = {};
  displayedColumns: string[] = ['substance', 'quantity', 'concentratum', 'modify_date'];
  dataSource: MatTableDataSource<NutritionDispenserSubstanceReloadHistoryDto> = new MatTableDataSource();

  settingValues = false;


  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.getNutritionDispenserSubstanceReloadHistory();
  }

  getNutritionDeviceCurrentParameters() {
    this.nutritionDispenserService.getNutritionDeviceCurrentParameters().subscribe(value => {
      this.newNutritionDispenserDeviceDto = {};
      if (value) {
        this.nutritionDispenserDeviceDto.substance = value.substance;
        this.nutritionDispenserDeviceDto.concentratum = value.concentratum;
        this.nutritionDispenserDeviceDto.quantity = value.quantity;

        this.newNutritionDispenserDeviceDto.substance = value.substance;
      }
    });
  }

  getNutritionDispenserSubstanceReloadHistory() {
    this.nutritionDispenserManagementImplService.getNutritionDispenserHistory({
      deviceId: this.device.deviceId,
      pageable: {page: this.currentPage, size: this.pageSize, sort: ["changeDate", "desc"]}
    }).subscribe(data => {
      this.totalRows = data.totalElements;
      this.dataSource.data = data.content;
      this.isLoading = false;
    });
  }

  saveDeviceParameters() {
    this.nutritionDispenserManagementImplService.refillNutritionDispenser({
      deviceId: this.device.deviceId,
      body: this.newNutritionDispenserDeviceDto
    }).subscribe(() => {
      this.getNutritionDeviceCurrentParameters()
      this.getNutritionDispenserSubstanceReloadHistory()
    });

  }
}
