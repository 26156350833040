<h2>
  <div class="tittle">{{this.data.dataType | uppercase }} calibration</div>
</h2>
<div class="calibration-row">
  <mat-form-field appearance="fill">
    <mat-label>Frequencies</mat-label>
    <mat-select [formControl]="frequencies" [(value)]="pickedFrequency">
      <mat-option *ngFor="let topping of this.data.frequenciesList"
                  [value]="topping">{{topping}}</mat-option>
    </mat-select>

  </mat-form-field>
  <mat-form-field>
    <input matInput [ngxMatDatetimePicker]="pickerStart" [(ngModel)]="chosedDate"
           [placeholder]="'Choose calibration date'"
    />
    <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
    <ngx-mat-datetime-picker #pickerStart [hideTime]="false"></ngx-mat-datetime-picker>
  </mat-form-field>
</div>


<div class="calibration-row">
  <mat-form-field appearance="outline">
    <mat-label>{{data.dataType}}</mat-label>
    <input matInput [(ngModel)]="this.saveImpedanceCalibrationDataRequest.calibrationPoints[0].valueX">
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Measured value</mat-label>
    <input matInput [(ngModel)]="this.saveImpedanceCalibrationDataRequest.calibrationPoints[0].valueY">
  </mat-form-field>
</div>
<div class="calibration-row">
  <mat-form-field appearance="outline" type="number">
    <mat-label>{{data.dataType}}</mat-label>
    <input matInput [(ngModel)]="this.saveImpedanceCalibrationDataRequest.calibrationPoints[1].valueX">
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Measured value</mat-label>
    <input matInput [(ngModel)]="this.saveImpedanceCalibrationDataRequest.calibrationPoints[1].valueY">
  </mat-form-field>
</div>


<div class="buttons">
  <button mat-raised-button mat-dialog-close="true" color="warn">Cancel</button>
  <button mat-raised-button mat-dialog-close="true" color="primary" (click)="saveCalibration()">Save</button>
</div>


<div class="mat-elevation-z8">
  <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
  <table mat-table [dataSource]="dataSource">

    <!-- Position Column -->
    <ng-container matColumnDef="record_timestamp">
      <th mat-header-cell *matHeaderCellDef> Record date</th>
      <td mat-cell
          *matCellDef="let element"> {{element.timestamp | date: dateService.longdateFormatPattern.toString() }} </td>
    </ng-container>

    <ng-container matColumnDef="calibration_timestamp">
      <th mat-header-cell *matHeaderCellDef> Calibration date</th>
      <td mat-cell
          *matCellDef="let element"> {{element.calibrationDate | date: dateService.longdateFormatPattern.toString() }} </td>
    </ng-container>

    frequency
    <ng-container matColumnDef="frequency">
      <th mat-header-cell *matHeaderCellDef> Frequency</th>
      <td mat-cell *matCellDef="let element"> {{element.frequency}} </td>
    </ng-container>

    <ng-container matColumnDef="reference_value_1">
      <th mat-header-cell *matHeaderCellDef> Reference value 1</th>
      <td mat-cell *matCellDef="let element"> {{element.impedanceSensorCalibrationPoints[0].valueX}} </td>
    </ng-container>

    <ng-container matColumnDef="measured_value_1">
      <th mat-header-cell *matHeaderCellDef> Measured value 1</th>
      <td mat-cell *matCellDef="let element"> {{element.impedanceSensorCalibrationPoints[0].valueY}} </td>
    </ng-container>

    <ng-container matColumnDef="reference_value_2">
      <th mat-header-cell *matHeaderCellDef> Reference value 2</th>
      <td mat-cell *matCellDef="let element"> {{element.impedanceSensorCalibrationPoints[1].valueX}} </td>
    </ng-container>

    <ng-container matColumnDef="measured_value_2">
      <th mat-header-cell *matHeaderCellDef> Measured value 2</th>
      <td mat-cell *matCellDef="let element"> {{element.impedanceSensorCalibrationPoints[1].valueY}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [length]="totalRows" [pageIndex]="currentPage" [pageSize]="pageSize"
                 [pageSizeOptions]="pageSizeOptions" (page)="pageChanged($event)" aria-label="Select page">
  </mat-paginator>
</div>
