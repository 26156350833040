import { Injectable } from '@angular/core';
import {
  PlantProfileManagerControllerImplService
} from "../../../generated/hydroponics-device-management-api/services/plant-profile-manager-controller-impl.service";
import {PlantProfileDto} from "../../../generated/hydroponics-device-management-api/models/plant-profile-dto";
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class PlantProfileService {
  plantProfiles: PlantProfileDto[] = [];
  loadingProfiles: boolean = false;
  profilesLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private plantProfileManagerControllerImplService: PlantProfileManagerControllerImplService) { }

  loadPlantProfiles() {
    // Ha már fut a betöltés, ne fusson újra
    if (this.loadingProfiles) {
      return;
    }

    // Ha már betöltődtek a profilok, ne fusson újra
    if (this.profilesLoaded.value) {
      return;
    }

    // Betöltés folyamatának jelzése
    this.loadingProfiles = true;

    // Profilok lekérése
    this.plantProfileManagerControllerImplService.getAllPlantProfiles().subscribe(
      profiles => {
        this.plantProfiles = Array.from(profiles);
        this.loadingProfiles = false; // Betöltés befejezése
        this.profilesLoaded.next(true); // Profilok betöltve
      },
      error => {
        console.error('Error loading plant profiles:', error);
        this.loadingProfiles = false; // Betöltés befejezése hiba esetén is
      }
    );
  }
}
