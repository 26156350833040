<mat-dialog-content maxWidth="100vh">
  <table mat-table [dataSource]="datasource" class="mat-elevation-z8">
    <ng-container matColumnDef="timestamp">
      <th mat-header-cell *matHeaderCellDef="let element"> {{ 'hydroponics.dashboard.timestamp' | translate }} </th>
      <td mat-cell *matCellDef="let element"> {{element.timestamp}} </td>
    </ng-container>
    <ng-container [matColumnDef]="column" *ngFor="let column of frequencies">
      <th mat-header-cell *matHeaderCellDef="let element"> {{column}} </th>
      <td mat-cell *matCellDef="let element"> {{element[column] | number: '1.0-1'}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="visibleColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: visibleColumns;"></tr>
  </table>
</mat-dialog-content>
