import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {SensorDataDto} from 'src/generated/hydroponics-device-management-api/models';
import {DateService} from "../../../../util/date.service";

export interface SensorDataDialogData {
  datasource: SensorDataDto[];
  visibleColumns: string[];
}

@Component({
  selector: 'app-sensor-data-dialog',
  templateUrl: 'sensor-data-dialog.component.html',
  styleUrls: ['./sensor-data.component.sass'],
})
export class SensorDataDialogComponent {
  public datasource: SensorDataDto[];
  public visibleColumns: string[];

  constructor(@Inject(MAT_DIALOG_DATA) public data: SensorDataDialogData, public dateService: DateService) {
    this.datasource = data.datasource;
    this.visibleColumns = data.visibleColumns;
  }
}
