import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {DateService} from "../../../../util/date.service";
import {SensorDataExportEvent} from "../sensor-data/sensor-data.component";
import {
  WorkbookControllerImplService
} from "../../../../../generated/hydroponics-device-management-api/services/workbook-controller-impl.service";
import { saveAs } from 'file-saver';
import * as moment from "moment-timezone";
import {
  SensorManagementImplService
} from "../../../../../generated/hydroponics-device-management-api/services/sensor-management-impl.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-workbook-export',
  templateUrl: './workbook-export.component.html',
  styleUrls: ['./workbook-export.component.sass']
})
export class WorkbookExportComponent implements OnInit, OnDestroy  {
  @Output() onExport: EventEmitter<SensorDataExportEvent> = new EventEmitter();

  public rangeDates: Date[] | undefined;
  public firstSensorDataTimestamp: Date;
  public rangeEndMaxDate = new Date;
  measureTypeOptions: any[] = [
    { label: this.translate.instant('hydroponics.charts.autoMeasurements'), value: 'auto' },
    { label: this.translate.instant('hydroponics.charts.manualMeasurements' ), value: 'manual' }];
  measureType: string = this.config.data.measureType;

  constructor(public ref: DynamicDialogRef,
              public config: DynamicDialogConfig,
              public dateService: DateService,
              public workbookService: WorkbookControllerImplService,
              public sensorManagementService: SensorManagementImplService,
              public translate: TranslateService) {
  }

  ngOnInit(): void {
    this.loadFirstSensorDataTimestamp();
  }

  loadFirstSensorDataTimestamp() {
    this.sensorManagementService.getFirstSensorDataTimestamp({
      deviceId: this.config.data.deviceId
    }).subscribe({
      next: firstSensorDataTimestamp => {
        console.log("firstSensorDataTimestamp:" + firstSensorDataTimestamp)
        if (firstSensorDataTimestamp) {
          this.firstSensorDataTimestamp = new Date(firstSensorDataTimestamp);
        }

      }
    });
  }

  getStartTime() {
    return moment(this.rangeDates[0]).tz('Europe/Budapest').format('yyyy-MM-DDTHH:mm:ss.SSSZ');
  }

  getEndTime() {
    return this.rangeDates[1]
      ? moment(this.rangeDates[1]).tz('Europe/Budapest').format('yyyy-MM-DDTHH:mm:ss.SSSZ')
      : moment.tz('Europe/Budapest').format('yyyy-MM-DDTHH:mm:ss.SSSZ');
  }

  exportToExcel(): void {
    const startTime = this.getStartTime();
    const endTime = this.getEndTime();

    this.workbookService.createExcel({
      type: this.config.data.dataType.toString(),
      startTime: startTime,
      endTime: endTime,
      deviceId: this.config.data.deviceId,
      isManual: this.measureType == 'manual' ? true : false
      //frequencies: this.config.data.frequencies lekertül mert túl sok a kérésnek
    }).subscribe((response: any) => {
      // Válasz tartalmának Blobként való mentése
      const blob = new Blob([response], { type: 'application/octet-stream' });

      // Fájl letöltése
      saveAs(blob, "hyroponics-" + this.config.data.dataType.toString() + "-data-" + new Date().toString() + '.xlsx');
    });
  }

  closeManualMeasurement() {
    this.ref.close();
  }

  ngOnDestroy(): void {
    this.closeManualMeasurement();
  }
}
