import { Component, OnInit } from '@angular/core';
import {DashboardService} from "../dashboard/dashboard.service";

@Component({
  selector: 'app-demo',
  templateUrl: './demo.component.html',
  styleUrls: ['./demo.component.sass']
})
export class DemoComponent implements OnInit {

  constructor(   public dashboardService: DashboardService) {
    dashboardService.device= {deviceId: "base-sensor-demo", name:"Sensorboard"}
  }

  ngOnInit(): void {
  }

}
