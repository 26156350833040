import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  SaveEventDataRequest
} from "../../../generated/hydroponics-device-management-api/models/save-event-data-request";
import {
  SaveWorksheetRequestDto
} from "../../../generated/hydroponics-device-management-api/models/save-worksheet-request-dto";
import {
  WorksheetControllerImplService
} from "../../../generated/hydroponics-device-management-api/services/worksheet-controller-impl.service";
import {
  EventManagerControllerImplService
} from "../../../generated/hydroponics-device-management-api/services/event-manager-controller-impl.service";
import {DeviceTypeEnum, LocationTreeLocationChangeEvent} from "../location-tree/location-tree.component";
import {NodeType} from "../locations/locations.component";
import {LoadAlertsAndWorksheetsNumberService} from "../../service/load-alerts-and-worksheets-number.service";

export enum WorkTypeEnum {
  EVENT = "EVENT",
  WORKSHEET = "WORKSHEET"
}

@Component({
  selector: 'app-worksheet-create',
  templateUrl: './worksheet-create.component.html',
  styleUrls: ['./worksheet-create.component.sass']
})
export class WorksheetCreateComponent implements OnInit, OnDestroy{
  dialogVisible: boolean = false;
  deviceDialogDataLoading: boolean = false;
  isDeviceDialogVisible: boolean = false;
  creationTypes: any[] = [{label: 'Event', value: WorkTypeEnum.EVENT}, {
    label: 'Worksheet',
    value: WorkTypeEnum.WORKSHEET
  }];
  creationType: string;
  description: string;
  deviceId: string = null;
  locationId: number = null;
  protected readonly DeviceTypeEnum = DeviceTypeEnum;
  protected locationName: string = null;
  protected deviceName: string = null;

  constructor(public worksheetControllerImplService: WorksheetControllerImplService,
              public eventManagerControllerImplService: EventManagerControllerImplService,
              private loadAlertsAndWorksheetsNumberService: LoadAlertsAndWorksheetsNumberService,
  ) {
  }

  ngOnInit(): void {
  }

  locationTreeLocationChanged(event: LocationTreeLocationChangeEvent) {
    this.deviceId = null
    this.locationId = null
    this.locationName = null
    this.deviceName = null

    let selectedNode = event.selectedNode;
    if (selectedNode && selectedNode.nodeType == NodeType.LOCATION) {
      this.locationId = event.selectedNode.id;
      this.locationName = event.selectedNode.label;
    } else if (selectedNode && selectedNode.nodeType == NodeType.DEVICE) {
      this.deviceId = event.selectedNode.id;
      this.deviceName = event.selectedNode.label;
    }
  }

  loadDeviceData(): void {
    this.deviceDialogDataLoading = true;
  }

  closeDeviceDialog(): void {
    this.deviceDialogDataLoading = false;
    this.isDeviceDialogVisible = false;
  }

  isDialogContentLoaded(): void {
    this.isDeviceDialogVisible = true;
  }

  onCreate() {
    this.creationType = null;
    this.dialogVisible = true;
  }

  onSave() {
    if (this.creationType == WorkTypeEnum.EVENT) {
      let request: SaveEventDataRequest = {};
      request.description = this.description;
      request.deviceId = this.deviceId;
      request.locationId = this.locationId;
      request.eventTypeName = WorkTypeEnum.WORKSHEET
      this.eventManagerControllerImplService.saveEvent({body: request}).subscribe(next => {
        this.dialogVisible = false;
        this.loadAlertsAndWorksheetsNumberService.emitEvent();
      })
    } else if (this.creationType == WorkTypeEnum.WORKSHEET) {
      let request: SaveWorksheetRequestDto = {};
      request.description = this.description;
      request.deviceId = this.deviceId;
      request.locationId = this.locationId;
      this.worksheetControllerImplService.createWorksheet({body: request}).subscribe(next => {
        this.dialogVisible = false;
        this.loadAlertsAndWorksheetsNumberService.emitEvent();
      })
    }
  }

  onClose() {
    this.dialogVisible = false;
    this.creationType = null;
    this.description = null;
    this.deviceName = null;
    this.deviceId = null;
    this.locationId = null;
  }

  ngOnDestroy(): void {
    this.dialogVisible = false;
  }

  isFormValid() {
    return !!this.creationType && !!this.deviceId && !!this.description;
  }
}
