<div class="content card">
  <h2>
    <div class="page-title">{{'hydroponics.flow.title' | translate}}</div>
  </h2>

  <mat-form-field appearance="fill">
    <mat-label>{{'hydroponics.flow.operationFlowDefs' | translate}}</mat-label>
    <mat-select [(value)]="selectedOperationFlowDefinition" (selectionChange)="definitionSelectedEvent($event.value)">
      <mat-option *ngFor="let flowDefinition of deviceOperationFlowDefinitions"
                  [value]="flowDefinition">{{flowDefinition.name}}</mat-option>
    </mat-select>

  </mat-form-field>

  <button mat-raised-button (click)="createOperationFlow()" color="primary" class="new-flow-button"
          [disabled]="existInProgressFlow"
          *ngIf="this.selectedOperationFlowDefinition">
    {{'hydroponics.flow.create' | translate}}
  </button>

  <!--  <div *ngIf="viewCreateOperationFlow">-->
  <!--    <app-device-operation-flow-create-->
  <!--      [deviceOperationFlowDefinitionId]="this.selectedOperationFlowDefinition.id"-->
  <!--    (onFlowCreated)="onFlowCreated($event)"-->
  <!--    (onCreationCancel)="onCreationCancel()"-->
  <!--    ></app-device-operation-flow-create>-->

  <!--  </div>-->

  <ng-container *ngIf="deviceOperationFlowHistory.data?.length>0">
    <mat-accordion class="flow-history-accordion">
      <mat-expansion-panel
        *ngFor="let operationFlow of deviceOperationFlowHistory.data;  trackBy: expansionPanelTracker">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{operationFlow.deviceOperationFlowDefinition.name}}
          </mat-panel-title>
          <mat-panel-description>
            {{'hydroponics.flow.steps.status.'.concat(operationFlow.status) | translate}}
            <!--                    {{operationFlow.}}-->
            <!--          <mat-icon>account_circle</mat-icon>-->
          </mat-panel-description>
        </mat-expansion-panel-header>


        <button mat-raised-button (click)="stopOperationFlow(operationFlow)" color="error" class="stop-flow-button"
                [disabled]="!operationFlow.isCancelFlowEnabledButton">
          {{'hydroponics.flow.cancel' | translate}}
        </button>

        <table class="step-table">
          <th>{{'hydroponics.flow.steps.id' | translate}}</th>
          <th>{{'hydroponics.flow.steps.order' | translate}}</th>
          <th>{{'hydroponics.flow.steps.name' | translate}}</th>
          <th>{{'hydroponics.flow.steps.type.name' | translate}}</th>
          <th>{{'hydroponics.flow.steps.status.name' | translate}}</th>
          <th>{{'hydroponics.flow.steps.result.name' | translate}}</th>
          <ng-container *ngFor="let step of operationFlow.deviceOperationFlowSteps">

            <tr>
              <td>{{step.deviceOperation?.id }}</td>
              <td>{{step.deviceOperationFlowStepDefinition.stepOrder }}</td>
              <td> {{step.deviceOperationFlowStepDefinition.name}} </td>
              <td> {{ 'hydroponics.flow.steps.type.'.concat(step.deviceOperationFlowStepDefinition.stepType) | translate}} </td>
              <td> {{'hydroponics.flow.steps.status.'.concat(step.status) | translate}}</td>

              <td>  {{deviceOperationUtilService.getDeviceOperationResult(step.deviceOperation)}}</td>
            </tr>


          </ng-container>


          <!--    <ng-container matColumnDef="quantity">-->
          <!--      <th mat-header-cell *matHeaderCellDef> {{ 'hydroponics.deviceOperation.param.quantity' | translate}}</th>-->
          <!--      <td mat-cell-->
          <!--          *matCellDef="let element"> {{getDeviceOperationParamValue('quantity', element.deviceOperation)}} </td>-->
          <!--    </ng-container>-->
          <!--    <ng-container matColumnDef="reverse">-->
          <!--      <th mat-header-cell *matHeaderCellDef> {{ 'hydroponics.deviceOperation.param.reverse' | translate}}</th>-->
          <!--      <td mat-cell-->
          <!--          *matCellDef="let element"> {{getDeviceOperationParamValue('reverse', element.deviceOperation)}} </td>-->
          <!--    </ng-container>-->

          <!--    <ng-container matColumnDef="response_date">-->
          <!--      <th mat-header-cell *matHeaderCellDef>{{ 'hydroponics.nutritionDispenser.responseDate' | translate}}</th>-->
          <!--      <td mat-cell-->
          <!--          *matCellDef="let element"> {{element.deviceOperation.confirmationDate | date: dateService.longdateFormatPattern.toString()}} </td>-->
          <!--    </ng-container>-->

          <!--    <ng-container matColumnDef="result">-->
          <!--      <th mat-header-cell *matHeaderCellDef> {{ 'hydroponics.nutritionDispenser.result' | translate}}</th>-->
          <!--      <td mat-cell *matCellDef="let element" class="device-operation-status-{{element.deviceOperation.status}}">-->
          <!--        <div-->
          <!--          *ngIf="isDeviceOperationOnError(element.deviceOperation)"> {{element.deviceOperation.errorCode + ':' + element.deviceOperation.errorDesc}}</div>-->
          <!--        <div *ngIf="!isDeviceOperationOnError(element.deviceOperation)">-->
          <!--          {{'hydroponics.nutritionDispenser.status.' + element.deviceOperation.status | translate}} </div>-->
          <!--      </td>-->
          <!--    </ng-container>-->

          <!--    <ng-container matColumnDef="dispensed_quantity">-->
          <!--      <th mat-header-cell *matHeaderCellDef> {{ 'hydroponics.nutritionDispenser.dispensedQuantity' | translate}}</th>-->
          <!--      <td mat-cell-->
          <!--          *matCellDef="let element"> {{element.dispensedQuantity}} </td>-->
          <!--    </ng-container>-->
          <!--        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>-->
          <!--        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>-->
        </table>


      </mat-expansion-panel>
    </mat-accordion>
    <mat-paginator #paginator [length]="totalRows" [pageSize]="pageSize"
                   [pageSizeOptions]="pageSizeOptions" (page)="getDeviceOperationFlowHistory($event)"
                   showFirstLastButtons></mat-paginator>
  </ng-container>

</div>
