<mat-dialog-content maxWidth="100vh">
  <table mat-table [dataSource]="datasource" class="mat-elevation-z8 sensor-table">
    <!-- Timestamp -->
    <ng-container matColumnDef="timestamp">
      <th mat-header-cell *matHeaderCellDef>{{ 'hydroponics.dashboard.timestamp' | translate }}</th>
      <td mat-cell
          *matCellDef="let element">{{ element.timestamp | date: dateService.longdateFormatPattern.toString() }}</td>
    </ng-container>
    <!-- Device ID -->
    <ng-container matColumnDef="deviceId">
      <th mat-header-cell *matHeaderCellDef>{{ 'hydroponics.dashboard.deviceId' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.deviceId }}</td>
    </ng-container>
    <!-- EC -->
    <ng-container matColumnDef="ec">
      <th mat-header-cell *matHeaderCellDef>{{ 'hydroponics.common.measurement.ec' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.ec | number: '1.0-1'}}</td>
    </ng-container>
    <!-- EC -->
    <ng-container matColumnDef="ec_raw">
      <th mat-header-cell *matHeaderCellDef>{{ 'hydroponics.common.measurement.ec_raw' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.naSensorRawData.ec }}</td>
    </ng-container>
      <!-- Iodine -->
      <ng-container matColumnDef="iodine">
      <th mat-header-cell *matHeaderCellDef>{{ 'hydroponics.common.measurement.iodine' | translate }}</th>
          <td mat-cell *matCellDef="let element">{{ element.iodine | number: '1.0-1' }}</td>
    </ng-container>
      <ng-container matColumnDef="iodine_raw">
          <th mat-header-cell *matHeaderCellDef>{{ 'hydroponics.common.measurement.iodine_raw' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.naSensorRawData.iodine }}</td>
    </ng-container>
    <!-- NA -->
    <ng-container matColumnDef="na">
      <th mat-header-cell *matHeaderCellDef>{{ 'hydroponics.common.measurement.na' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.na | number: '1.0-1' }}</td>
    </ng-container>
    <ng-container matColumnDef="na_raw">
      <th mat-header-cell *matHeaderCellDef>{{ 'hydroponics.common.measurement.na_raw' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.naSensorRawData.na }}</td>
    </ng-container>
    <!-- ORP -->
    <ng-container matColumnDef="orp">
      <th mat-header-cell *matHeaderCellDef>{{ 'hydroponics.common.measurement.orp' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.orp | number: '1.0-1'}}</td>
    </ng-container>
    <ng-container matColumnDef="orp_raw">
      <th mat-header-cell *matHeaderCellDef>{{ 'hydroponics.common.measurement.orp_raw' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.naSensorRawData.orp }}</td>
    </ng-container>
    <!-- pH -->
    <ng-container matColumnDef="ph">
      <th mat-header-cell *matHeaderCellDef>{{ 'hydroponics.common.measurement.ph' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.ph | number: '1.0-1'}} </td>
    </ng-container>
    <ng-container matColumnDef="ph_raw">
      <th mat-header-cell *matHeaderCellDef>{{ 'hydroponics.common.measurement.ph_raw' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.naSensorRawData.ph }}</td>
    </ng-container>
    <!-- RH -->
    <ng-container matColumnDef="rh">
      <th mat-header-cell *matHeaderCellDef>{{ 'hydroponics.common.measurement.rh' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.rh | number: '1.0-1'}}</td>
    </ng-container>
    <ng-container matColumnDef="rh_raw">
      <th mat-header-cell *matHeaderCellDef>{{ 'hydroponics.common.measurement.rh_raw' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.naSensorRawData.rh }}</td>
    </ng-container>
    <!-- Tamb -->
    <ng-container matColumnDef="tamb">
      <th mat-header-cell *matHeaderCellDef>{{ 'hydroponics.common.measurement.tamb' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.tamb | number: '1.0-1'}}</td>
    </ng-container>
    <ng-container matColumnDef="tamb_raw">
      <th mat-header-cell *matHeaderCellDef>{{ 'hydroponics.common.measurement.tamb_raw' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.naSensorRawData.tamb }}</td>
    </ng-container>
    <!-- TLiquid -->
    <ng-container matColumnDef="tliquid">
      <th mat-header-cell *matHeaderCellDef>{{ 'hydroponics.common.measurement.tliquid' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.tliquid | number: '1.0-1'}}</td>
    </ng-container>
    <ng-container matColumnDef="tliquid_raw">
      <th mat-header-cell *matHeaderCellDef>{{ 'hydroponics.common.measurement.tliquid_raw' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.naSensorRawData.tliquid }}</td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="visibleColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: visibleColumns"></tr>
  </table>
</mat-dialog-content>
