import {DeviceOperationDto} from "../../../generated/hydroponics-device-management-api/models/device-operation-dto";
import {TranslateService} from "@ngx-translate/core";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root',
})
export class DeviceOperationUtilService {

  constructor(private translate: TranslateService) {
  }

  getDeviceOperationResult(deviceOperation: DeviceOperationDto) {
    if (deviceOperation) {
      if (deviceOperation.status == "START_FAILED"
        || deviceOperation.status == "COMMAND_SEND_ERROR"
        || deviceOperation.status == "CONFIRMATION_ERROR") {
        if ('noFlow' == deviceOperation.errorCode) {
          return this.translate.instant('hydroponics.deviceOperation.error.'.concat(deviceOperation.errorCode));
        }

        return deviceOperation.errorCode + ': ' + deviceOperation.errorDesc;
      } else {
        return this.translate.instant('hydroponics.deviceOperation.status.'.concat(deviceOperation.status));
      }
    }
  }


}


