import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

export interface ImpedanceDataDialogData {
  timestamp: string;
}

export interface SensorDataDialogData {
  datasource: ImpedanceDataDialogData[];
  deviceId: string,
  frequencies: string[];
}

@Component({
  selector: 'app-impedance-senso-data-dialog',
  templateUrl: './impedance-senso-data-dialog.component.html',
  styleUrls: ['./impedance-senso-data-dialog.component.sass']
})

export class ImpedanceSensoDataDialogComponent implements OnInit {

  public visibleColumns: string[] = ["timestamp"];
  public frequencies: string[];
  public datasource: ImpedanceDataDialogData[];

  constructor(@Inject(MAT_DIALOG_DATA) public data: SensorDataDialogData) {
  }

  ngOnInit(): void {
    this.visibleColumns = this.visibleColumns.concat(this.data.frequencies);
    this.frequencies = this.data.frequencies;
    this.datasource = this.data.datasource;
  }

}
