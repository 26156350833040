import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {DeviceTypeEnum} from "../location-tree/location-tree.component";
import {
  ChartFilterAttributeChangeEvent,
  ChartFilterDateChangeEvent, ChartFilterFrequenciesChangeEvent,
  ChartFilterLocationChangeEvent
} from "../chart-filter/chart-filter.component";
import {Subject, Subscription} from "rxjs";
import {NodeElement, NodeType} from "../locations/locations.component";
import {LocationTreeService} from "../location-tree/location-tree.service";
import {OperationType} from "../device/sensorboard/sensor-data/sensor-data.component";
import {ExtendedChartData} from "../sensor-charts/sensor-charts.component";
import {
  DeviceAlertsCountDto
} from "../../../generated/hydroponics-device-management-api/models/device-alerts-count-dto";

export interface ChartDevice {
  deviceId: string,
  settlement: string,
  deviceName: string,
  locations: string,
  alerts: number,
  chartAlerts: DeviceAlertsCountDto,
  worksheets: number
}

@Component({
  selector: 'app-sensor-measure-dashboard',
  templateUrl: './sensor-measure-dashboard.component.html',
  styleUrls: ['./sensor-measure-dashboard.component.sass']
})
export class SensorMeasureDashboardComponent implements OnInit, OnDestroy {
  protected readonly DeviceTypeEnum = DeviceTypeEnum;
  chartFilterChangeSubjectSensor: Subject<any> = new Subject<any>();
  chartFilterAttributeChangeSubjectSensor: Subject<string[]> = new Subject<string[]>();
  chartFilterFrequenciesChangeSubjectSensor: Subject<number[]> = new Subject<number[]>();

  protected readonly OperationType = OperationType;
  protected chartDevices: ChartDevice[] = [];
  protected locationIds: any[] = [];
  private rangeDates: Date[];
  private locationTreeSubscription: Subscription;

  constructor(private locationTreeService: LocationTreeService) { }

  ngOnInit(): void {
    // TODO device id kezdetben minden eszköz kell.
    /*this.locationTreeSubscription = this.locationTreeService.isInitialized().subscribe((initialized: boolean) => {
      if (initialized){
        // ha kész a fa, akkor a rootalapján szűrhetünk elsőre
        if (this.locationTreeService.getSelectedNode()){
          this.addDevicesToChart(this.locationTreeService.getSelectedNode());
        } else {
          this.addDevicesToChart(this.locationTreeService.getRoot());
        }

        this.callChartFilterChangeSubjectSensor();
      }
    });*/
    //this.callChartFilterChangeSubjectSensor();

  }

  onDateChange(event: ChartFilterDateChangeEvent) {
    this.rangeDates = event.rangeDates;
    this.callChartFilterChangeSubjectSensor();
  }

  addDevicesToChart(nodeElement : NodeElement){
    if (nodeElement.nodeType && nodeElement.nodeType == NodeType.DEVICE){
      this.chartDevices.push({
        deviceId: nodeElement.id,
        settlement: nodeElement.settlement,
        deviceName: nodeElement.deviceName,
        locations: nodeElement.locations,
        alerts: nodeElement.alerts,
        chartAlerts: nodeElement.chartAlerts,
        worksheets: nodeElement.worksheets
      })
    } else if (nodeElement.nodeType && nodeElement.nodeType == NodeType.LOCATION){
      this.locationIds.push(nodeElement.id);
    }

    if (nodeElement.children){
      nodeElement.children.forEach(child =>{
        this.addDevicesToChart(child);
      })
    }
  }

  onLocationChange(event: ChartFilterLocationChangeEvent) {
    this.chartDevices = [];
    this.locationIds = [];
    if (event.selectedNodes){
      event.selectedNodes.forEach((nodeElement, key) => {
        this.addDevicesToChart(nodeElement);
      });
    }

    this.callChartFilterChangeSubjectSensor();
  }

  callChartFilterChangeSubjectSensor(){
    this.chartFilterChangeSubjectSensor.next({rangeDates: this.rangeDates, chartDevices: this.chartDevices, locationIds: this.locationIds});
  }

  onAttributeChange(event: ChartFilterAttributeChangeEvent) {
    this.chartFilterAttributeChangeSubjectSensor.next(event.attributeNames);
  }

  onFrequenciesChange(event: ChartFilterFrequenciesChangeEvent) {
    this.chartFilterFrequenciesChangeSubjectSensor.next(event.frequencies);
  }
  ngOnDestroy(): void {
    if (this.locationTreeSubscription){
      this.locationTreeSubscription.unsubscribe();
    }
  }
}
