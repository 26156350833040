<app-chart-filter
  [deviceTypes]="[DeviceTypeEnum.SENSORBOARD]"
  [isOnlyDeviceSelectable]="false"
  (onDateChange)="onDateChange($event)"
  (onAttributeChange)="onAttributeChange($event)"
  (onFrequenciesChange)="onFrequenciesChange($event)"
  (onLocationChange)="locationChanged($event)"
  [isFrequencyFilter]="false"
  [isWorksheetFilter]="false"
  [usedAttributes]="Array.of(SensorDataType.EC, SensorDataType.PH, SensorDataType.ORP)">
</app-chart-filter>

<h3 class="no-chosen-device-or-location-warning"
    *ngIf="this.chartDevices.length == 0 && this.locationIds.length == 0">
  {{ 'hydroponics.worksheet.noLocationOrDeviceChosen' | translate }}
</h3>

<div>
  <app-sensor-charts
    [operationTypes]="[OperationType.CALIBRATION, OperationType.OPTIMAL_ATTRIBUTE]"
    [calibrationTypes]="['EC','PH','ORP']"
    [chartFilterChangeSubjectSensor]="chartFilterChangeSubjectSensor"
    [chartFilterFrequenciesChangeSubjectSensor]="chartFilterFrequenciesChangeSubjectSensor"
    [chartFilterAttributeChangeSubjectSensor]="chartFilterAttributeChangeSubjectSensor">
  </app-sensor-charts>
</div>
