<div class="flex align-items-center justify-content-center">
  <div class="card">
    <div class="header">
      <h2>{{ 'hydroponics.profile.profileData' | translate }}</h2>
      <mat-icon *ngIf="!isUserProfileEdit" (click)="this.startEditProfileData()" aria-hidden="false">edit</mat-icon>
    </div>

    <div *ngIf="!isUserProfileEdit">
      <table class="disabled-table-data">
        <tr>
          <td>{{ 'hydroponics.profile.lastName' | translate }}:</td>
          <td>{{ this.userProfile?.user.lastName }}</td>
        </tr>
        <tr>
          <td>{{ 'hydroponics.profile.firstName' | translate }}:</td>
          <td>{{ this.userProfile?.user.firstName }}</td>
        </tr>
        <tr>
          <td>{{ 'hydroponics.profile.organization' | translate }}:</td>
          <td>{{ this.userProfile?.user.organization }}</td>
        </tr>
        <tr>
          <td>{{ 'hydroponics.profile.email' | translate }}:</td>
          <td>{{ this.userProfile?.user.email }}</td>
        </tr>
        <tr>
          <td>{{ 'hydroponics.profile.receiveEmail' | translate }}:</td>
          <td>
            {{ this.userProfile?.user.receiveEmail }}
          </td>
        </tr>
      </table>
      <mat-divider class="mat-divider mat-divider-horizontal"></mat-divider>
      <div class="change-password-wapper">
        <p-button (click)="startChangePassword()" label="{{ 'hydroponics.profile.changePassword' | translate }}">
        </p-button>
      </div>
    </div>
    <form *ngIf="this.isUserProfileEdit" [formGroup]="userProfileForm" class="input-fields" novalidate>
      <p-checkbox class="mb-5" label="{{ 'hydroponics.profile.receiveEmail' | translate }}"
                  binary="true" formControlName="receiveEmail" [value]=this.userProfile?.user.receiveEmail></p-checkbox>

      <div class="buttons">
        <p-button
            class="change-button mr-1"
            (click)="this.isUserProfileEdit=false"
            label="{{ 'hydroponics.button.cancel' | translate }}">
        </p-button>
        <p-button
            (click)="onSubmitUserProfile()"
            class="change-button"
            [disabled]="userProfileForm.invalid"
            label="{{ 'hydroponics.button.save' | translate }}">
        </p-button>
      </div>
    </form>

    <!--  <form *ngIf="this.isPasswordChange" [formGroup]="passwordForm" (ngSubmit)="onSubmitChangePassword()"-->
    <!--        class="input-fields" novalidate>-->

    <!--    <mat-form-field appearance="outline">-->
    <!--      <mat-label> {{'hydroponics.profile.currentPassword' | translate}}</mat-label>-->
    <!--      <input matInput formControlName="currentPassword" type="password">-->
    <!--      <mat-error ng>-->
    <!--        {{'hydroponics.profile.currentPassword' | translate}} {{'hydroponics.validation.required' | translate}}-->
    <!--      </mat-error>-->
    <!--    </mat-form-field>-->

    <!--    <mat-form-field appearance="outline">-->
    <!--      <mat-label> {{'hydroponics.profile.newPassword' | translate}}</mat-label>-->
    <!--      <input matInput formControlName="newPassword" type="password">-->
    <!--      <mat-error ng>-->
    <!--        {{'hydroponics.profile.newPassword' | translate}} {{'hydroponics.validation.required' | translate}}-->
    <!--      </mat-error>-->
    <!--    </mat-form-field>-->

    <!--    <mat-form-field appearance="outline">-->
    <!--      <mat-label> {{'hydroponics.profile.newPasswordAgain' | translate}}</mat-label>-->
    <!--      <input matInput formControlName="newPasswordAgain" type="password">-->
    <!--      <mat-error ng>-->
    <!--        {{'hydroponics.profile.newPasswordAgain' | translate}} {{'hydroponics.validation.required' | translate}}-->
    <!--      </mat-error>-->
    <!--    </mat-form-field>-->
    <!--    <mat-error *ngIf="passwordFormValidator()" ng>-->
    <!--      {{'hydroponics.profile.emailIsRequired' | translate}}-->
    <!--    </mat-error>-->

    <!--    <div class="buttons">-->
    <!--      <button mat-raised-button class="change-button" color="warn" (click)="this.isUserProfileEdit=false">{{'hydroponics.button.cancel' | translate}}-->
    <!--      </button>-->
    <!--      <button mat-raised-button class="change-button" color="primary" >{{'hydroponics.button.save' | translate}}</button>-->
    <!--    </div>-->
    <!--  </form>-->
  </div>
  <div>
</div>
</div>
