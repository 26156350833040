import {Component, Inject, OnInit} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {PageEvent} from "@angular/material/paginator";
import {FormControl} from "@angular/forms";
import {Observable, startWith} from "rxjs";
import {map} from "rxjs/operators";
import {
  EventManagerControllerImplService
} from "../../generated/hydroponics-device-management-api/services/event-manager-controller-impl.service";
import {EventDto, SaveEventDataRequest} from "../../generated/hydroponics-device-management-api/models";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {DateService} from "../util/date.service";


@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.sass']
})
export class EventComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: { deviceId: string }, private eventManagerControllerImplService: EventManagerControllerImplService,
              public dateService: DateService) {
  }

  ngOnInit() {
    this.loadEvents();
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
    this.eventTimestamp = new Date(Date.now());
  }


  isLoading = false;
  totalRows = 0;
  pageSize = 5;
  currentPage = 0;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  myControl = new FormControl('');
  options: string[] = [];
  filteredOptions: Observable<string[]>;
  eventTimestamp: Date;
  eventDescription: string;
  eventName: string;

  displayedColumns: string[] = ['timestamp', 'name', 'description'];
  dataSource: MatTableDataSource<EventDto> = new MatTableDataSource();

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.loadEvents();
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }


  saveEvent() {
    let saveEventDataRequest = {} as SaveEventDataRequest;
    saveEventDataRequest.deviceId = this.data.deviceId;
    saveEventDataRequest.eventTypeName = this.eventName;
    saveEventDataRequest.description = this.eventDescription;
    saveEventDataRequest.eventTime = DateService.getLocalISOString(new Date(this.eventTimestamp));
    this.eventManagerControllerImplService.saveEvent({body: saveEventDataRequest}).subscribe();
  }

  loadEvents() {
    this.isLoading = true;
    this.eventManagerControllerImplService.getEventsToDevice({
      deviceId: this.data.deviceId,
      pageable: {page: this.currentPage, size: this.pageSize, sort: ["timestamp", "desc"]}
    }).subscribe(value => {
      this.totalRows = value.totalElements;
      this.dataSource.data = value.content;
    });


    this.eventManagerControllerImplService.getAllEventTypes().subscribe(value => {
      value.forEach(value1 => this.options.push(value1.name));
    })
    this.isLoading = false;
  }
}
