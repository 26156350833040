<div class="event-container">
  <h2>
    <div class="tittle"> Events</div>
  </h2>

  <mat-form-field class="event-input-param">
    <mat-label>Event date</mat-label>
    <input
      matInput
      [ngxMatDatetimePicker]="pickerStart"
      [(ngModel)]="this.eventTimestamp"/>
    <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
    <ngx-mat-datetime-picker #pickerStart [hideTime]="false"></ngx-mat-datetime-picker>
  </mat-form-field>

  <form class="example-form">
    <mat-form-field class="event-input-param" appearance="fill">
      <mat-label>Event</mat-label>
      <input type="text"
             placeholder="Pick one"
             aria-label="Number"
             matInput
             [formControl]="myControl"
             [matAutocomplete]="auto"
             [(ngModel)]="this.eventName">
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
          {{option}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </form>

  <mat-form-field class="event-input-param">
    <mat-label>Description</mat-label>
    <textarea [rows]="5" matInput [(ngModel)]="this.eventDescription"></textarea>
  </mat-form-field>

  <div class="buttons">
    <button mat-raised-button mat-dialog-close="true" color="warn">Cancel</button>
    <button mat-raised-button mat-dialog-close="true" color="primary" (click)="saveEvent()">Record event</button>
  </div>

  <div class="mat-elevation-z8">
    <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
    <table mat-table [dataSource]="dataSource">

      <!-- Position Column -->
      <ng-container matColumnDef="timestamp">
        <th mat-header-cell *matHeaderCellDef> Event date</th>
        <td mat-cell
            *matCellDef="let element"> {{element.timestamp | date: dateService.longdateFormatPattern.toString() }} </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Event</th>
        <td mat-cell *matCellDef="let element"> {{element.type.name}} </td>
      </ng-container>
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef> Description</th>
        <td mat-cell *matCellDef="let element"> {{element.description}} </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [length]="totalRows" [pageIndex]="currentPage" [pageSize]="pageSize"
                   [pageSizeOptions]="pageSizeOptions" (page)="pageChanged($event)" aria-label="Select page">
    </mat-paginator>
  </div>
</div>
