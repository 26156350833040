import {APP_INITIALIZER, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {NgChartsModule} from 'ng2-charts';
import {DashboardComponent} from './dashboard/dashboard.component';
import {TemplateModule} from './template/template.module';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {
  ApiModule as HydroponicsDeviceManagementApiModule
} from 'src/generated/hydroponics-device-management-api/api.module';
import {
  ApiModule as HydroponicsUserManagementApiModule
} from 'src/generated/hydroponics-user-management-api/api.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatNativeDateModule} from '@angular/material/core';
import {MatTableModule} from '@angular/material/table';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import {
  NGX_MAT_DATE_FORMATS,
  NgxMatDateFormats,
  NgxMatDatetimePickerModule,
  NgxMatTimepickerModule,
} from '@angular-material-components/datetime-picker';
import {MatInputModule} from '@angular/material/input';
import {
  getConfig,
  getHydroponicsDeviceManagementApiConfigurationParams,
  getHydroponicsUserManagementApiConfigurationParams
} from './configuration';
import {SensorDataComponent} from './components/device/sensorboard/sensor-data/sensor-data.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {ExcelService} from './util/excel.service';
import {NgxMatMomentModule} from '@angular-material-components/moment-adapter';
import {MatDialogModule} from '@angular/material/dialog';
import {SensorDataDialogComponent} from './components/device/sensorboard/sensor-data/sensor-data-dialog.component';
import {CalibrationComponent} from './components/device/sensorboard/calibration/calibration.component';
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {EventComponent} from "./event/event.component";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatSelectModule} from "@angular/material/select";
import {BaseSensorComponent} from './components/device/sensorboard/base-sensor/base-sensor.component';
import {ImpedanceSensorComponent} from './components/device/impedance/impedance-sensor/impedance-sensor.component';
import {
  ImpedanceSensorDataComponent
} from "./components/device/sensorboard/impedance-sensor-data/impedance-sensor-data.component";
import {
  ImpedanceSensorCalibrationComponent
} from "./components/device/impedance/impedance-sensor-calibration/impedance-sensor-calibration.component";
import {
  NutritionDispenserComponent
} from "./components/nutrition-dispenser/nutrition-dispenser/nutrition-dispenser.component";
import {CookieService} from "ngx-cookie-service";
import {
  ImpedanceSensoDataDialogComponent
} from './components/device/impedance/impedance-sensor-data-dialog/impedance-senso-data-dialog.component';
import {DateService} from "./util/date.service";
import {
  DeviceParametersComponent
} from './components/nutrition-dispenser/device-parameters/device-parameters.component';
import {
  DeviceOperationsComponent
} from './components/nutrition-dispenser/device-operations/device-operations.component';
import {ExportComponent} from './components/device/impedance/export/export.component';
import {AuthComponent} from './auth/auth.component';
import {KeycloakAngularModule, KeycloakService} from "keycloak-angular";
import {ProfileComponent} from './profile/profile/profile.component';
import {MatDividerModule} from "@angular/material/divider";
import {ApiCallInterceptor} from "./service/api-call-interceptor.service";
import {OverlayModule} from "@angular/cdk/overlay";
import {DemoComponent} from './demo/demo.component';
import {DemoNatriumSensorComponent} from './demo/demo-natrium-sensor/demo-natrium-sensor.component';
import {DemoAppHeaderComponent} from './demo/demo-app-header/demo-app-header.component';
import {RoleDirective} from "./auth/role/directive/role.directive";
import {ProgressSpinnerComponent} from "./components/progress-spinner/progress-spinner.component";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {SensorboardComponent} from './components/device/sensorboard/sensorboard.component';
import {MatCheckboxModule} from "@angular/material/checkbox";
import {
  SensorboardManualMeasureComponent
} from './components/device/sensorboard/sensorboard-manual-measure/sensorboard-manual-measure.component';
import {
  ImpedanceManualMeasureComponent
} from './components/device/impedance/impedance-manual-measure/impedance-manual-measure.component';
import {DeviceOperationFlowComponent} from './device-operation-flow/device-operation-flow.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {PlantProfileComponent} from './components/plant-profile/plant-profile.component';
import {DropdownModule} from "primeng/dropdown";
import {LocationsComponent} from './components/locations/locations.component';
import {OrganizationChartModule} from "primeng/organizationchart";
import {TreeModule} from "primeng/tree";
import {StyleClassModule} from "primeng/styleclass";
import {DividerModule} from "primeng/divider";
import {ContextMenuModule} from "primeng/contextmenu";
import {DialogModule} from "primeng/dialog";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {ConfirmationService, MessageService as PrimeMessageService} from "primeng/api";
import {CheckboxModule} from "primeng/checkbox";
import {AlertComponent} from './components/alerts/alert/alert.component';
import {CardModule} from "primeng/card";
import {AlertTableComponent} from './components/alerts/alert-table/alert-table.component';
import {SelectButtonModule} from "primeng/selectbutton";
import {ChartFilterComponent} from './components/chart-filter/chart-filter.component';
import {CalendarModule} from "primeng/calendar";
import {
  PlantProfileAttributeManagerComponent
} from './components/plant-profile-attribute-manager/plant-profile-attribute-manager.component';
import {WorkbookExportComponent} from './components/device/sensorboard/workbook-export/workbook-export.component';
import {LocationTreeComponent} from './components/location-tree/location-tree.component';
import {DosageComponent} from './components/dosage/dosage.component';
import {InputNumberModule} from "primeng/inputnumber";
import {TableModule} from "primeng/table";
import {PaginatorModule} from "primeng/paginator";
import {ToastModule} from 'primeng/toast';
import {
  SensorMeasureDashboardComponent
} from './components/sensor-measure-dashboard/sensor-measure-dashboard.component';
import {SensorChartsComponent} from './components/sensor-charts/sensor-charts.component';
import {WorksheetComponent} from './components/worksheet/worksheet.component';
import {WorksheetDashboardComponent} from './components/worksheet-dashboard/worksheet-dashboard.component';
import {WorksheetCreateComponent} from './components/worksheet-create/worksheet-create.component';
import {AccordionModule} from "primeng/accordion";
import {WorksheetCommentComponent} from './components/worksheet-comment/worksheet-comment.component';
import {CalibrationBoardComponent} from './components/calibration-board/calibration-board.component';
import {ChartModule} from "primeng/chart";
import {MessageService} from "./service/message.service";
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {MultiSelectModule} from "primeng/multiselect";
import {InputTextareaModule} from "primeng/inputtextarea";
import {MatButtonToggleModule} from "@angular/material/button-toggle";

const CUSTOM_DATE_FORMATS: NgxMatDateFormats = {

  parse: {
    dateInput: 'l, LTS',
  },
  display: {
    dateInput: 'YYYY-MM-DD HH:mm:ss',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

//Keycloak init
function initializeKeycloak(keycloak: KeycloakService) {
  return async () => {

    let keycloakUrlp;
    await getConfig().then(config => {
      keycloakUrlp = config.keycloakUrl;
    });

    return keycloak.init({
      config: {
        realm: 'HydroponicsRealm',
        url: keycloakUrlp,
        clientId: 'hydroponics-frontend'
      },
      loadUserProfileAtStartUp: true,
      initOptions: {
        onLoad: 'check-sso',
        checkLoginIframe: false,
      },
      enableBearerInterceptor: true,
      bearerExcludedUrls: ['/assets', '/configuration']
    });
  }
}

function initializeTranslate(translate: TranslateService): () => Promise<any> {
  return () => new Promise<any>((resolve) => {
    translate.setDefaultLang('en');
    translate.use('user-preferred-language').subscribe(() => {
      console.log("Translations loaded");
      resolve(null);
    }, err => {
      console.log("Error loading translations", err);
      resolve(null);
    });
  });
}

@NgModule({
  declarations: [AppComponent, AuthComponent, DashboardComponent, SensorDataComponent, SensorDataDialogComponent, CalibrationComponent, BaseSensorComponent, ImpedanceSensorComponent, ImpedanceSensorDataComponent, ImpedanceSensorCalibrationComponent, ImpedanceSensoDataDialogComponent, EventComponent, NutritionDispenserComponent, DeviceParametersComponent, DeviceOperationsComponent, ExportComponent, ProfileComponent, DemoComponent, DemoNatriumSensorComponent, DemoAppHeaderComponent, RoleDirective, ProgressSpinnerComponent, SensorboardComponent, SensorboardManualMeasureComponent, ImpedanceManualMeasureComponent, DeviceOperationFlowComponent, PlantProfileComponent, LocationsComponent, AlertComponent, ChartFilterComponent, PlantProfileAttributeManagerComponent, WorkbookExportComponent, LocationTreeComponent, DosageComponent, SensorMeasureDashboardComponent, SensorChartsComponent, WorksheetComponent, WorksheetDashboardComponent, AlertTableComponent, WorksheetCreateComponent, WorksheetCommentComponent, CalibrationBoardComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        NgChartsModule,
        TemplateModule,
        ToastModule,
        HydroponicsUserManagementApiModule.forRoot(getHydroponicsUserManagementApiConfigurationParams()),
        HydroponicsDeviceManagementApiModule.forRoot(getHydroponicsDeviceManagementApiConfigurationParams()),
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        BrowserAnimationsModule,
        ReactiveFormsModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatInputModule,
        MatNativeDateModule,
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
        MatTableModule,
        MatDialogModule,
        MatCheckboxModule,
        NgxMatTimepickerModule,
        NgxMatDatetimePickerModule,
        NgxMatMomentModule,
        MatSnackBarModule,
        MatPaginatorModule,
        FormsModule,
        MatProgressBarModule,
        MatSelectModule,
        MatAutocompleteModule,
        MatDividerModule,
        MatProgressSpinnerModule,
        KeycloakAngularModule,
        OverlayModule,
        MatExpansionModule,
        DropdownModule,
        OrganizationChartModule,
        TreeModule,
        StyleClassModule,
        DividerModule,
        ContextMenuModule,
        DialogModule,
        ConfirmDialogModule,
        CheckboxModule,
        SelectButtonModule,
        CalendarModule,
        InputNumberModule,
        TableModule,
        PaginatorModule,
        CardModule,
        AccordionModule,
        ChartModule,
        ProgressSpinnerModule,
        MultiSelectModule,
      InputTextareaModule,
      MatButtonToggleModule
    ],
  providers: [{
    provide: APP_INITIALIZER,
    useFactory: initializeKeycloak,
    multi: true,
    deps: [KeycloakService]
  },
  {
    provide: APP_INITIALIZER,
    useFactory: initializeTranslate,
    multi: true,
    deps: [TranslateService]
  },
    {provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS},
    {provide: HTTP_INTERCEPTORS, useClass: ApiCallInterceptor, multi: true},
    ExcelService, CookieService, DateService, ConfirmationService, MessageService, PrimeMessageService],
  bootstrap: [AppComponent],
})

export class AppModule {
}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
