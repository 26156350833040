import {Component, OnInit} from '@angular/core';
import {AuthComponent} from "../../auth/auth.component";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {
  ModifyUserProfileRequest
} from "../../../generated/hydroponics-user-management-api/models/modify-user-profile-request";
import {
  AuthorizedUserManagementControllerImplService
} from "../../../generated/hydroponics-user-management-api/services/authorized-user-management-controller-impl.service";
import {GetUserByIdResponse} from "../../../generated/hydroponics-user-management-api/models/get-user-by-id-response";
import {MessageService} from "../../service/message.service";
import {LoadingSpinnerService} from "../../service/loading-spinner.service";

interface MyProfileForm {
  email?: string,
  lastName?: string,
  firstName?: string,
  receiveEmail?: boolean,

}

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.sass'],
})
export class ProfileComponent implements OnInit {
  //Profilt szerkeszt?
  public isUserProfileEdit: boolean = false;
  //User adat form
  public userProfileForm: FormGroup;
  //Backend által visszaadott response
  public userProfile: GetUserByIdResponse;

  //Jelszót változtat?
  public isPasswordChange: boolean = false;
  //Jelszó változtató form
  public passwordForm: FormGroup;

  constructor(private readonly authComponent: AuthComponent,
              private fb: FormBuilder,
              private readonly userManagementService: AuthorizedUserManagementControllerImplService,
              private readonly messageService: MessageService,
              private loadingSpinner: LoadingSpinnerService) {
  }

  ngOnInit(): void {
    this.userManagementService.getMyUserProfile().subscribe(data => {
        this.userProfile = data;
      }
    );
    // this.loadingSpinner.show();
  }

  public errorHandling = (control: string, error: string) => {
    return this.userProfileForm.controls[control].hasError(error);
  }

  onSubmitUserProfile() {
    if (this.userProfileForm.invalid) {
      return;
    }

    let profileData: ModifyUserProfileRequest = {
      firstName: this.userProfileForm.controls.firstName.value,
      lastName: this.userProfileForm.controls.lastName.value,
      email: this.userProfileForm.controls.email.value,
      receiveEmail: this.userProfileForm.controls.receiveEmail.value,
    }
    this.userManagementService.modifyMyUserProfile({body: profileData}).subscribe(data => {
      this.userProfile = data;
      this.messageService.success();
      this.authComponent.userProfile.next(data.user);

    }, error => {
      this.messageService.error(error.message, true);
    });
    this.isUserProfileEdit = false;

  }


  startEditProfileData() {
    this.userProfileForm = this.fb.group({
      email: this.fb.control(this.userProfile.user.email, [Validators.required, Validators.email]),
      lastName: this.fb.control(this.userProfile.user.lastName, [Validators.required]),
      firstName: this.fb.control(this.userProfile.user.firstName, [Validators.required]),
      receiveEmail: this.fb.control(this.userProfile.user.receiveEmail),

    });
    this.isUserProfileEdit = true;
  }

  startChangePassword() {
    this.userManagementService.resetPassword().subscribe(data => {
      this.messageService.success("An email sent to change password", true);
    }, error => {
      this.messageService.error(error.message, true);
    });
  }

  public passwordFormValidator = () => {
    return this.passwordForm.controls.newPassword.invalid ||
      this.passwordForm.controls.newPassword.value != this.passwordForm.controls.newPasswordAgain.value;
  }
}
