/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { FindReceivingEmailUsersRequest } from '../models/find-receiving-email-users-request';
import { FindReceivingEmailUsersResponse } from '../models/find-receiving-email-users-response';
import { FindUsersByOrganizationRequest } from '../models/find-users-by-organization-request';
import { FindUsersByOrganizationResponse } from '../models/find-users-by-organization-response';
import { GetUserByIdResponse } from '../models/get-user-by-id-response';
import { ModifyUserProfileRequest } from '../models/modify-user-profile-request';

@Injectable({
  providedIn: 'root',
})
export class AuthorizedUserManagementControllerImplService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation resetPassword
   */
  static readonly ResetPasswordPath = '/authorized/users/reset-password';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resetPassword()` instead.
   *
   * This method doesn't expect any request body.
   */
  resetPassword$Response(params?: {
    'X-Authenticated-User'?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AuthorizedUserManagementControllerImplService.ResetPasswordPath, 'post');
    if (params) {
      rb.header('X-Authenticated-User', params['X-Authenticated-User'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `resetPassword$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  resetPassword(params?: {
    'X-Authenticated-User'?: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.resetPassword$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getMyUserProfile
   */
  static readonly GetMyUserProfilePath = '/authorized/users/my-profile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMyUserProfile()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMyUserProfile$Response(params?: {
    'X-Authenticated-User'?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<GetUserByIdResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AuthorizedUserManagementControllerImplService.GetMyUserProfilePath, 'get');
    if (params) {
      rb.header('X-Authenticated-User', params['X-Authenticated-User'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetUserByIdResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getMyUserProfile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMyUserProfile(params?: {
    'X-Authenticated-User'?: string;
    context?: HttpContext
  }
): Observable<GetUserByIdResponse> {

    return this.getMyUserProfile$Response(params).pipe(
      map((r: StrictHttpResponse<GetUserByIdResponse>) => r.body as GetUserByIdResponse)
    );
  }

  /**
   * Path part for operation modifyMyUserProfile
   */
  static readonly ModifyMyUserProfilePath = '/authorized/users/my-profile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `modifyMyUserProfile()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  modifyMyUserProfile$Response(params: {
    'X-Authenticated-User'?: string;
    context?: HttpContext
    body: ModifyUserProfileRequest
  }
): Observable<StrictHttpResponse<GetUserByIdResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AuthorizedUserManagementControllerImplService.ModifyMyUserProfilePath, 'post');
    if (params) {
      rb.header('X-Authenticated-User', params['X-Authenticated-User'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetUserByIdResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `modifyMyUserProfile$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  modifyMyUserProfile(params: {
    'X-Authenticated-User'?: string;
    context?: HttpContext
    body: ModifyUserProfileRequest
  }
): Observable<GetUserByIdResponse> {

    return this.modifyMyUserProfile$Response(params).pipe(
      map((r: StrictHttpResponse<GetUserByIdResponse>) => r.body as GetUserByIdResponse)
    );
  }

  /**
   * Path part for operation getUserName
   */
  static readonly GetUserNamePath = '/authorized/users/user-name';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserName()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserName$Response(params?: {
    'X-Authenticated-User'?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, AuthorizedUserManagementControllerImplService.GetUserNamePath, 'get');
    if (params) {
      rb.header('X-Authenticated-User', params['X-Authenticated-User'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUserName$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserName(params?: {
    'X-Authenticated-User'?: string;
    context?: HttpContext
  }
): Observable<string> {

    return this.getUserName$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation findReceivingEmailUsers
   */
  static readonly FindReceivingEmailUsersPath = '/authorized/users/find-receiving-email';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findReceivingEmailUsers()` instead.
   *
   * This method doesn't expect any request body.
   */
  findReceivingEmailUsers$Response(params: {
    request: FindReceivingEmailUsersRequest;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<FindReceivingEmailUsersResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AuthorizedUserManagementControllerImplService.FindReceivingEmailUsersPath, 'get');
    if (params) {
      rb.query('request', params.request, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FindReceivingEmailUsersResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findReceivingEmailUsers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findReceivingEmailUsers(params: {
    request: FindReceivingEmailUsersRequest;
    context?: HttpContext
  }
): Observable<FindReceivingEmailUsersResponse> {

    return this.findReceivingEmailUsers$Response(params).pipe(
      map((r: StrictHttpResponse<FindReceivingEmailUsersResponse>) => r.body as FindReceivingEmailUsersResponse)
    );
  }

  /**
   * Path part for operation findUsersByOrganization
   */
  static readonly FindUsersByOrganizationPath = '/authorized/users/find-by-organization';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findUsersByOrganization()` instead.
   *
   * This method doesn't expect any request body.
   */
  findUsersByOrganization$Response(params: {
    request: FindUsersByOrganizationRequest;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<FindUsersByOrganizationResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AuthorizedUserManagementControllerImplService.FindUsersByOrganizationPath, 'get');
    if (params) {
      rb.query('request', params.request, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FindUsersByOrganizationResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findUsersByOrganization$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findUsersByOrganization(params: {
    request: FindUsersByOrganizationRequest;
    context?: HttpContext
  }
): Observable<FindUsersByOrganizationResponse> {

    return this.findUsersByOrganization$Response(params).pipe(
      map((r: StrictHttpResponse<FindUsersByOrganizationResponse>) => r.body as FindUsersByOrganizationResponse)
    );
  }

}
