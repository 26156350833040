import {Component, Inject, OnInit} from '@angular/core';
import {FormControl} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.sass']
})
export class ExportComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ExportComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { frequenciesList: number[], pickedFrequenciesList: number[], dataType: string }) {
    this.pickedFrequencies = this.data.pickedFrequenciesList;
    this.frequenciesForm.setValue(this.pickedFrequencies);
  }

  ngOnInit(): void {
  }

  public frequenciesForm = new FormControl([]);
  public pickedFrequencies: number[] = [];

  selectAllFrequencies() {
    this.pickedFrequencies = this.data.frequenciesList;
  }

  diselectAllFrequencies() {
    this.pickedFrequencies = [];
  }

  exportData() {
    let result = {"dataType": this.data.dataType, "pickedFrequencies": this.pickedFrequencies};
    this.dialogRef.close(result);
  }
}
