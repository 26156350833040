<div>
  <div class="mb-5">
    <p-calendar [(ngModel)]="rangeDates"
                [showTime]="true"
                selectionMode="range"
                [minDate]="firstSensorDataTimestamp"
                [maxDate]="rangeEndMaxDate"
                [placeholder]="'hydroponics.common.rangeTimePlaceholder' | translate"
                appendTo="body" class="w-full d-grid"></p-calendar>
  </div>
  <div class="mb-5">
    <p-selectButton [options]="measureTypeOptions" [(ngModel)]="measureType" optionLabel="label" optionValue="value"></p-selectButton>
  </div>
  <div class="mb-5">
    <p-button (click)="exportToExcel()">{{'hydroponics.dashboard.exportToExcel' | translate}}</p-button>
  </div>
</div>
