import {Component, OnDestroy, OnInit} from '@angular/core';
import {DeviceOperationFlowControllerImplService} from "../../generated/hydroponics-device-management-api/services/device-operation-flow-controller-impl.service";
import {DeviceOperationFlowDefinitionDto} from "../../generated/hydroponics-device-management-api/models/device-operation-flow-definition-dto";
import {MatTableDataSource} from "@angular/material/table";
import {DeviceOperationFlowDto} from "../../generated/hydroponics-device-management-api/models/device-operation-flow-dto";
import {CreateDeviceOperationFlowRequest} from "../../generated/hydroponics-device-management-api/models/create-device-operation-flow-request";
import {TranslateService} from "@ngx-translate/core";
import {DeviceOperationUtilService} from "../util/deviceoperation/device-operation-util.service";
import {PageEvent} from "@angular/material/paginator";
import {interval, Subscription, takeUntil} from "rxjs";

@Component({
  selector: 'app-device-operation-flow',
  templateUrl: './device-operation-flow.component.html',
  styleUrls: ['./device-operation-flow.component.sass']
})
export class DeviceOperationFlowComponent implements OnInit, OnDestroy {

  private pollDeviceOperationFlowHistory: Subscription;
  private pollExistInProgressFlow: Subscription;
  //Flow indítás után ennyi másodpercenként frissítjük a history-t
  private readonly DEVICE_OPERATON_FLOW_HISTORY_REFRESH_INTERVAL = 3;
  //Max ennyi ideig várunk összesen a flow indítása után a státusz változásra
  private readonly DEVICE_OPERATON_FLOW_HISTORY_MAX_REFRESH_INTERVAL = 300;

  //Flow indítás után ennyi másodpercenként frissítjük a existInProgressFlow-t
  private readonly DEVICE_OPERATON_FLOW_IN_PROGRESS_REFRESH_INTERVAL = 3;
  //Max ennyi ideig várunk összesen a flow indítása után a existInProgressFlow változásra
  private readonly DEVICE_OPERATON_FLOW_IN_PROGRESS_MAX_REFRESH_INTERVAL = 300;

  private readonly = 3;

  selectedOperationFlowDefinition: DeviceOperationFlowDefinitionDto;
  deviceOperationFlowDefinitions: Array<DeviceOperationFlowDefinitionDto>;
  totalRows = 0;
  pageSize = 5;
  currentPage = 0;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  displayedColumns: string[] = ['name'];
  existInProgressFlow: boolean = true;
  isCancelFlowEnabledButton: boolean = false;
  deviceOperationFlowHistory: MatTableDataSource<DeviceOperationFlowDto> = new MatTableDataSource();
  expansionPanelTracker = (index: number, item: any) => item.id

  constructor(
    private deviceOperationFlowService: DeviceOperationFlowControllerImplService,
    private translate: TranslateService,
    private deviceOperationUtilService: DeviceOperationUtilService
  ) {
  }

  ngOnInit(): void {
    this.getDeviceOperationFlowDefinitions();
  }

  getDeviceOperationFlowDefinitions() {
    this.deviceOperationFlowService.getDeviceOperationFlowDefinitions().subscribe(value =>
      this.deviceOperationFlowDefinitions = value);
  }

  getInProgressStepDefintions() {
    this.deviceOperationFlowService.getInProgressStepDefintions({deviceOperationFlowDefinitionId: this.selectedOperationFlowDefinition.id}).subscribe(value => {
      if (value && value.length > 0) {
        this.existInProgressFlow = true;
      } else {
        this.existInProgressFlow = false;
      }

      if (this.pollExistInProgressFlow) {
        this.pollExistInProgressFlow.unsubscribe();
      }

      if (this.existInProgressFlow) {
        this.pollExistInProgressFlow = interval(this.DEVICE_OPERATON_FLOW_IN_PROGRESS_REFRESH_INTERVAL * 1000).pipe(takeUntil(interval(this.DEVICE_OPERATON_FLOW_IN_PROGRESS_MAX_REFRESH_INTERVAL * 1000))).subscribe(() => {

          if (this.existInProgressFlow) {
            this.getInProgressStepDefintions();
          } else {
            this.pollExistInProgressFlow.unsubscribe();
          }
        });
      }
    });
  }

  definitionSelectedEvent(flowDefinition: DeviceOperationFlowDefinitionDto) {
    this.getInProgressStepDefintions();
    this.getDeviceOperationFlowHistory();
  }

  getDeviceOperationFlowHistory(event?: PageEvent) {
    if (event) {
      this.pageSize = event.pageSize;
      this.currentPage = event.pageIndex;
    }


    this.deviceOperationFlowService.getDeviceOperationFlowsToDefinition({
      deviceOperationFlowDefinitionId: this.selectedOperationFlowDefinition.id,
      pageable: {page: this.currentPage, size: this.pageSize, sort: ["createTimestamp", "desc"]}
    }).subscribe(value => {
      this.deviceOperationFlowHistory.data = value.content;
      this.deviceOperationFlowHistory.data.forEach(deviceOperationFlow => {
        //order szerint rendezni a stepeket
        deviceOperationFlow.deviceOperationFlowSteps = deviceOperationFlow.deviceOperationFlowSteps.sort((a, b) => a.deviceOperationFlowStepDefinition.stepOrder -
          b.deviceOperationFlowStepDefinition.stepOrder);
        let notScheduledSteps = deviceOperationFlow.deviceOperationFlowSteps.filter(deviceOperationFlowStep => deviceOperationFlowStep.status != 'SCHEDULED');
        if (notScheduledSteps && notScheduledSteps.length > 0) {
          if (notScheduledSteps[notScheduledSteps.length - 1] === undefined) {
            console.log("Warn: notScheduledSteps[notScheduledSteps.length - 1] is undefined: " + JSON.stringify(notScheduledSteps));
            console.log("details: " + JSON.stringify(deviceOperationFlow.deviceOperationFlowSteps));
          }
          deviceOperationFlow['status'] = notScheduledSteps[notScheduledSteps.length - 1].status;
        } else {
          deviceOperationFlow['status'] = 'SCHEDULED';
        }

        deviceOperationFlow['isCancelFlowEnabledButton'] = deviceOperationFlow['status'] == 'IN_PROGRESS' || deviceOperationFlow['status'] == 'SCHEDULED';

      });

      this.pageSize = value.size;
      this.totalRows = value.totalElements


      if (this.pollDeviceOperationFlowHistory) {
        this.pollDeviceOperationFlowHistory.unsubscribe();
      }
      this.pollDeviceOperationFlowHistory = interval(this.DEVICE_OPERATON_FLOW_HISTORY_REFRESH_INTERVAL * 1000).pipe(takeUntil(interval(this.DEVICE_OPERATON_FLOW_HISTORY_MAX_REFRESH_INTERVAL * 1000))).subscribe(() => {

        const areInProgresFlows = this.deviceOperationFlowHistory.data.filter(p => p['status'] == "SCHEDULED" || p['status'] == "IN_PROGRESS").length;
        if (areInProgresFlows) {
          this.getDeviceOperationFlowHistory();
        } else {
          console.log("stop history poll: " + JSON.stringify(this.deviceOperationFlowHistory.data));
          this.pollDeviceOperationFlowHistory.unsubscribe();
        }
      });
    })
  }

  createOperationFlow() {
    this.existInProgressFlow = true;

    let createDeviceOperationFlowRequest: CreateDeviceOperationFlowRequest = {
      deviceOperationFlowDefinitionId: this.selectedOperationFlowDefinition.id,
    }

    this.deviceOperationFlowService.createDeviceOperationFlow({
      request: createDeviceOperationFlowRequest
    }).subscribe(value => {
      this.getInProgressStepDefintions();
      this.getDeviceOperationFlowHistory();
    })
  }

  ngOnDestroy(): void {
    if (this.pollDeviceOperationFlowHistory) {
      this.pollDeviceOperationFlowHistory.unsubscribe();
    }
    if (this.pollExistInProgressFlow) {
      this.pollExistInProgressFlow.unsubscribe();
    }

  }

  stopOperationFlow(deviceOperationFlow: DeviceOperationFlowDto) {
    this.deviceOperationFlowService.cancelDeviceOperationFlow({
      deviceOperationFlowId: deviceOperationFlow.id
    }).subscribe()
  }

  isCancelFlowEnabled(deviceOperationFlow: DeviceOperationFlowDto) {
    this.isCancelFlowEnabledButton = deviceOperationFlow.deviceOperationFlowSteps.filter(value => value.status == 'SCHEDULED').length > 0
      && deviceOperationFlow.deviceOperationFlowSteps.filter(value => value.status == 'CANCELED').length == 0;
  }

  calibration() {
    let substanceToMeasuringCup2Concentratum = 20 * 1000;
    let liveSystemToMeasuringCup1DispensedQuantity = 10.0;
    let substanceToMeasuringCup1DispensedQuantity = 10.0;
    let substanceToMeasuringCup2DispensedQuantity = 10.0;
    let impedanceMeasure3Impedance = 140.0;
    let impedanceMeasure2Impedance = 110.0;
    let m = (substanceToMeasuringCup2Concentratum / 1000.0 * substanceToMeasuringCup2DispensedQuantity) / (impedanceMeasure3Impedance * (liveSystemToMeasuringCup1DispensedQuantity + substanceToMeasuringCup1DispensedQuantity + substanceToMeasuringCup2DispensedQuantity) - impedanceMeasure2Impedance * (liveSystemToMeasuringCup1DispensedQuantity + substanceToMeasuringCup1DispensedQuantity));

    return m;
  }
}

