<app-chart-filter
  (onDateChange)="onDateChange($event)"
  [isAttributeFilter]="false"
  [deviceTypes]="[DeviceTypeEnum.SENSORBOARD]"
  [isOnlyDeviceSelectable]="false"
  [isFrequencyFilter]="false"
  (onLocationChange)="locationChanged($event)">
</app-chart-filter>

<app-worksheet-create></app-worksheet-create>

<h3 class="no-chosen-device-or-location-warning"
    *ngIf="this.deviceIds.length == 0 && this.locationIds.length == 0">
  {{ 'hydroponics.worksheet.noLocationOrDeviceChosen' | translate }}
</h3>

<ng-container *ngFor="let workElement of workElements">
  <app-worksheet
    [isExtended]="true"
    [workElement]="workElement">
  </app-worksheet>
</ng-container>

<p-paginator
  class="paginator-component"
  (onPageChange)="pageChanged($event)"
  [first]="first"
  [rows]="pageSize"
  [totalRecords]="totalRows"
  [rowsPerPageOptions]="pageSizeOptions"></p-paginator>
