<div class="dashboard">

<div class="card actual-values-container">
  <div class="title">
    <h2>{{ this.device.name }}</h2>
    <span class="timestamp">{{ lastReading?.timestamp | date: 'medium' }}</span>
  </div>

  <div class="calibrationButton" *hasRole="'event'">
    <button mat-raised-button color="primary"
            (click)="openEvents()">{{'hydroponics.common.events' | translate}}</button>
  </div>
  <div>
    <button mat-raised-button color="primary"
            (click)="openManualMeasure()">{{'hydroponics.manualMeasure.manualMeasure' | translate}}</button>
  </div>


  <div class="values">
    <div class="values-item">
      <h4>{{ 'hydroponics.common.measurement.ec' | translate }}</h4>
      <h2>{{ lastReading ? (lastReading.ec | number: '1.0-1') : 'N/A' }}</h2>
    </div>
    <div class="values-item">
      <h4>{{ 'hydroponics.common.measurement.ph' | translate }}</h4>
      <h2>{{ lastReading ? (lastReading.ph | number: '1.0-1') : 'N/A' }}</h2>
    </div>
    <div class="values-item">
      <h4>{{ 'hydroponics.common.measurement.iodine' | translate }}</h4>
      <h2>{{ lastReading ? (lastReading.iodine | number: '1.0-1') : 'N/A' }}</h2>
    </div>
    <div class="values-item">
      <h4>{{ 'hydroponics.common.measurement.na' | translate }}</h4>
      <h2>{{ lastReading ? (lastReading.na | number: '1.0-1') : 'N/A' }}</h2>
    </div>
    <div class="values-item">
      <h4>{{ 'hydroponics.common.measurement.orp' | translate }}</h4>
      <h2>{{ lastReading ? (lastReading.orp | number: '1.0-1') : 'N/A' }}</h2>
    </div>
    <div class="values-item">
      <h4>{{ 'hydroponics.common.measurement.rh' | translate }}</h4>
      <h2>{{ lastReading ? (lastReading.rh | number: '1.0-1') : 'N/A' }}</h2>
    </div>
    <div class="values-item">
      <h4>{{ 'hydroponics.common.measurement.tamb' | translate }}</h4>
      <h2>{{ lastReading ? (lastReading.tamb | number: '1.0-1') : 'N/A' }}</h2>
    </div>
    <div class="values-item">
      <h4>{{ 'hydroponics.common.measurement.tliquid' | translate }}</h4>
      <h2>{{ lastReading ? (lastReading.tliquid | number: '1.0-1') : 'N/A' }}</h2>
    </div>
  </div>
</div>

  <div class="card">
    <div class="actions">
      <div class="action-buttons">
        <button
          mat-icon-button
          [matTooltip]="'hydroponics.dashboard.exportToExcel' | translate"
          (click)="exportAllData()">
          <mat-icon>save</mat-icon>
        </button>
        <button
          mat-icon-button
          [matTooltip]="'hydroponics.dashboard.showInTable' | translate"
          (click)="showAllInTable()">
          <mat-icon>list</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div class="flex">
    <div class="flex-item card">
      <app-sensor-data
        [dataType]="ec"
        [option]="chartOptions"
        [data]="ecChartData"
        [deviceId]="this.device.deviceId"></app-sensor-data>
    </div>
    <div class="flex-item card">
      <app-sensor-data
        [dataType]="ph"
        [option]="chartOptions"
        [data]="phChartData"
        [deviceId]="this.device.deviceId"></app-sensor-data>
    </div>
    <div class="flex-item card">
      <app-sensor-data
        [dataType]="orp"
        [option]="chartOptions"
        [data]="orpChartData"
        [deviceId]="this.device.deviceId"></app-sensor-data>
    </div>
    <div class="flex-item card">
      <app-sensor-data
        [dataType]="rh"
        [option]="chartOptions"
        [data]="rhChartData"
        [deviceId]="this.device.deviceId"></app-sensor-data>
    </div>
    <div class="flex-item card">
      <app-sensor-data
        [dataType]="tamb"
        [option]="chartOptions"
        [data]="tAmbChartData"
        [deviceId]="this.device.deviceId"></app-sensor-data>
    </div>
    <div class="flex-item card">
      <app-sensor-data
        [dataType]="tliquid"
        [option]="chartOptions"
        [data]="tLiquidChartData"
        [deviceId]="this.device.deviceId"></app-sensor-data>
    </div>
    <div class="flex-item card">
      <app-sensor-data
        [dataType]="na"
        [option]="chartOptions"
        [data]="naChartData"
        [deviceId]="this.device.deviceId"></app-sensor-data>
    </div>
    <div class="flex-item card">
      <app-sensor-data
        [dataType]="iodine"
        [option]="chartOptions"
        [data]="iodineChartData"
        [deviceId]="this.device.deviceId"></app-sensor-data>
    </div>
  </div>
  <!-- Footer -->
</div>
