import {DatePipe} from "@angular/common";

export class DateService {
  private static readonly pipe = new DatePipe('en-US');
  private static readonly dateFormatPattern = 'MM.dd HH:mm:ss';

  public static readonly staticLongdateFormatPattern = 'yyyy-MM-dd HH:mm:ss';

  public readonly longdateFormatPattern = DateService.staticLongdateFormatPattern;

  public static formatDate(date: Date): string | null {
    return this.pipe.transform(date, this.staticLongdateFormatPattern);
  }

  public static getLocalISOString(date) {
    const offset = date.getTimezoneOffset()
    const offsetAbs = Math.abs(offset)
    const isoString = new Date(date.getTime() - offset * 60 * 1000).toISOString()
    return `${isoString.slice(0, -1)}${offset > 0 ? '-' : '+'}${String(Math.floor(offsetAbs / 60)).padStart(2, '0')}:${String(offsetAbs % 60).padStart(2, '0')}`
  }


}


