import {Component, OnInit} from '@angular/core';
import {ChartFilterAttributeChangeEvent, ChartFilterLocationChangeEvent} from "../chart-filter/chart-filter.component";
import {NodeType} from "../locations/locations.component";
import {DeviceDto} from "../../../generated/hydroponics-device-management-api/models/device-dto";
import {
  DeviceManagementImplService
} from "../../../generated/hydroponics-device-management-api/services/device-management-impl.service";
import {DeviceTypeEnum} from "../location-tree/location-tree.component";

@Component({
  selector: 'app-dosage',
  templateUrl: './dosage.component.html',
  styleUrls: ['./dosage.component.sass']
})
export class DosageComponent implements OnInit {
  deviceId: string;
  device: DeviceDto;

  constructor(private deviceManagementImplService: DeviceManagementImplService) { }

  ngOnInit(): void {
  }

  onLocationChanged(event: ChartFilterLocationChangeEvent) {
    this.deviceId = null;
    this.device = null;
    if (event.selectedNode && event.selectedNode.nodeType == NodeType.DEVICE){
      this.deviceId = event.selectedNode.id;
      this.deviceManagementImplService.getDevice({deviceId: this.deviceId}).subscribe(value => {
        this.device = value;
      });
    }
    // TODO location/establishments/organization
  }

  onAttributeChanged(event: ChartFilterAttributeChangeEvent) {
    event.attributeNames;
  }

  protected readonly DeviceTypeEnum = DeviceTypeEnum;
}
