import {Component, OnInit} from '@angular/core';
import {AuthComponent} from "../../auth/auth.component";
import {TranslateService} from "@ngx-translate/core";
import {MenuItem} from 'primeng/api';
import {PROFILE_ROUTE} from "../../app-routing.module";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass'],
  // providers: [AuthComponent]
})
export class HeaderComponent implements OnInit{
  items: MenuItem[];

  constructor(public readonly authService: AuthComponent, public translate: TranslateService) {
  }

  ngOnInit() {
    this.items = [
      {label: this.translate.instant('hydroponics.navigation.profile'), icon: 'pi pi-user', routerLink: PROFILE_ROUTE},
      {label: this.translate.instant('hydroponics.navigation.logout'), escape: false, icon: 'pi pi-sign-out', command: () => this.authService.logout()}
    ];
  }

  logout(){
    this.authService.logout();
  }

  public onMenuButtonClick(){

  }

  public onSidebarButtonClick(){

  }
}
