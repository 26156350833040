<app-chart-filter
  [visible]="true"
  [isDateFilter]="false"
  [isAttributeFilter]="false"
  [deviceTypes]="[DeviceTypeEnum.NUTRITION_DISPENSER]"
  [isOnlyDeviceSelectable]="true"
  [isMultiple]="false"
  [isFrequencyFilter]="false"
  (onLocationChange)="onLocationChanged($event)"
  (onAttributeChange)="onAttributeChanged($event)">
</app-chart-filter>

<div *ngIf="deviceId">
  <app-device-operations [device]=device></app-device-operations>
</div>

