import {Injectable} from '@angular/core';
import {Overlay, OverlayRef} from "@angular/cdk/overlay";
import {BehaviorSubject, map, tap, scan, timer} from "rxjs";
import {ComponentPortal} from "@angular/cdk/portal";
import {ProgressSpinnerComponent} from "../components/progress-spinner/progress-spinner.component";

@Injectable({
  providedIn: 'root'
})
export class LoadingSpinnerService {

  private readonly API_CALL_SPINNER_SHOW_DELAY : number= 500;

  private spinnerRef: OverlayRef = this.cdkSpinnerCreate();
  _loading = new BehaviorSubject<boolean>(false);
  public readonly loading$ = this._loading.asObservable();
  private counter = 0

  constructor(private overlay: Overlay) {
    this.loading$.pipe(
      map(val => val ? 1 : -1 ),
      scan((acc, one) => (acc + one) >= 0 ? acc + one : 0, 0),
      tap(val=>this.counter = val)
    ).subscribe(
      (res) => {
        if(res === 1) {
          timer(this.API_CALL_SPINNER_SHOW_DELAY).subscribe((result) => {
            if (this.counter > 0) {
              !this.spinnerRef.hasAttached() ? this.spinnerRef.attach(new ComponentPortal(ProgressSpinnerComponent)) : null;
            }
          });
        }
        else if( res === 0 ){
          this.spinnerRef.hasAttached() ? this.spinnerRef.detach(): null;
        }
      }
    )
  }

  private cdkSpinnerCreate() {
    return this.overlay.create({
      hasBackdrop: true,
      panelClass: 'messaging-overlay-container',
      positionStrategy: this.overlay.position()
        .global()
        .centerHorizontally()
        .centerVertically()
    })
  }

  show() {
    this._loading.next(true);
  }

  hide() {
    this._loading.next(false);
  }
}
