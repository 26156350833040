<div class="export-row">
  <mat-form-field appearance="fill">
    <mat-label>Frequencies</mat-label>
    <mat-select [formControl]="frequenciesForm" [(value)]="this.pickedFrequencies" multiple>
      <mat-option *ngFor="let topping of this.data.frequenciesList"
                  [value]="topping">{{topping}}</mat-option>
    </mat-select>
  </mat-form-field>

  <button class="calibrationButton" mat-raised-button color="primary"
          (click)="selectAllFrequencies()">Select all
  </button>
  <button class="calibrationButton" mat-raised-button  color="primary" (click)="diselectAllFrequencies()">Diselect all</button>
</div>


<div class="export-cancel-buttons">
  <button mat-raised-button mat-dialog-close="true" color="warn">Cancel</button>
  <button mat-raised-button mat-dialog-close="true" color="primary"
  (click)="exportData()">Export</button>
</div>


