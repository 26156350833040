/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PlantProfileAttributeCustomDto } from '../models/plant-profile-attribute-custom-dto';
import { PlantProfileDto } from '../models/plant-profile-dto';

@Injectable({
  providedIn: 'root',
})
export class PlantProfileManagerControllerImplService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAllPlantProfiles
   */
  static readonly GetAllPlantProfilesPath = '/plantprofiles/profiles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllPlantProfiles()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllPlantProfiles$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<PlantProfileDto>>> {

    const rb = new RequestBuilder(this.rootUrl, PlantProfileManagerControllerImplService.GetAllPlantProfilesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PlantProfileDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllPlantProfiles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllPlantProfiles(params?: {
    context?: HttpContext
  }
): Observable<Array<PlantProfileDto>> {

    return this.getAllPlantProfiles$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PlantProfileDto>>) => r.body as Array<PlantProfileDto>)
    );
  }

  /**
   * Path part for operation getPlantProfileByDevice
   */
  static readonly GetPlantProfileByDevicePath = '/plantprofiles/profile-by-device';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPlantProfileByDevice()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlantProfileByDevice$Response(params: {
    deviceId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<PlantProfileDto>> {

    const rb = new RequestBuilder(this.rootUrl, PlantProfileManagerControllerImplService.GetPlantProfileByDevicePath, 'get');
    if (params) {
      rb.query('deviceId', params.deviceId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PlantProfileDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPlantProfileByDevice$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlantProfileByDevice(params: {
    deviceId: string;
    context?: HttpContext
  }
): Observable<PlantProfileDto> {

    return this.getPlantProfileByDevice$Response(params).pipe(
      map((r: StrictHttpResponse<PlantProfileDto>) => r.body as PlantProfileDto)
    );
  }

  /**
   * Path part for operation getCustomAttributesByLocation
   */
  static readonly GetCustomAttributesByLocationPath = '/plantprofiles/location-attributes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomAttributesByLocation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomAttributesByLocation$Response(params: {
    location: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<PlantProfileAttributeCustomDto>>> {

    const rb = new RequestBuilder(this.rootUrl, PlantProfileManagerControllerImplService.GetCustomAttributesByLocationPath, 'get');
    if (params) {
      rb.query('location', params.location, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PlantProfileAttributeCustomDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCustomAttributesByLocation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomAttributesByLocation(params: {
    location: number;
    context?: HttpContext
  }
): Observable<Array<PlantProfileAttributeCustomDto>> {

    return this.getCustomAttributesByLocation$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PlantProfileAttributeCustomDto>>) => r.body as Array<PlantProfileAttributeCustomDto>)
    );
  }

  /**
   * Path part for operation getCustomAttributesByDevice
   */
  static readonly GetCustomAttributesByDevicePath = '/plantprofiles/device-attributes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomAttributesByDevice()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomAttributesByDevice$Response(params: {
    device: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<PlantProfileAttributeCustomDto>>> {

    const rb = new RequestBuilder(this.rootUrl, PlantProfileManagerControllerImplService.GetCustomAttributesByDevicePath, 'get');
    if (params) {
      rb.query('device', params.device, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PlantProfileAttributeCustomDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCustomAttributesByDevice$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomAttributesByDevice(params: {
    device: string;
    context?: HttpContext
  }
): Observable<Array<PlantProfileAttributeCustomDto>> {

    return this.getCustomAttributesByDevice$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PlantProfileAttributeCustomDto>>) => r.body as Array<PlantProfileAttributeCustomDto>)
    );
  }

}
