import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Router} from "@angular/router";
import { PrimeNGConfig } from 'primeng/api';
import {DASHBOARD_ROUTE} from "./app-routing.module";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
})
export class AppComponent implements OnInit{
  constructor(private translate: TranslateService, protected  router: Router, private primengConfig: PrimeNGConfig) {
    translate.setDefaultLang('en');
    translate.use('en');
  }

  ngOnInit( ): void {
    this.primengConfig.ripple = true;
  }
}
