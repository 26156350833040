import {Injectable} from '@angular/core';
import {SensorManagementImplService} from "../../generated/hydroponics-device-management-api/services/sensor-management-impl.service";
import {ApiConfiguration} from "../../generated/hydroponics-device-management-api/api-configuration";
import {HttpClient, HttpContext} from "@angular/common/http";
import {Observable} from "rxjs";
import {SensorDataDto} from "../../generated/hydroponics-device-management-api/models/sensor-data-dto";
import * as moment from "moment-timezone";
import * as _ from 'lodash';


@Injectable({
  providedIn: 'root'
})
export class DemoSensorManagementImplService extends SensorManagementImplService {

  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  private initiated = false;
  private demoResponsePromise = fetch('./configuration/demo-data.json').then(response => response.json()).then(configuration => {
    let measureTime = moment();
    let demoResponse = configuration.naSensorData as Array<SensorDataDto>;
    return this.shiftSensorDataTimestamps(demoResponse);
    // subscriber.next(this.filterDates(params.start, params.end));
  });

  override getSensorData(params: {
                  start: string;
                  end: string;
                  deviceId: string;
                  context?: HttpContext
                }
  ): Observable<Array<SensorDataDto>> {
    return new Observable(subscriber => {
      this.demoResponsePromise
        .then(demoResponse => {
          subscriber.next(this.filterDates(demoResponse, params.start, params.end));
        })
    });
  }

  override getFirstSensorDataTimestamp(params: {
                                deviceId: string;
                                context?: HttpContext
                              }
  ): Observable<string> {
    return new Observable(subscriber => {
      this.demoResponsePromise
        .then(demoResponse => {
          if (demoResponse && demoResponse.length > 0) {
            subscriber.next(demoResponse.pop().timestamp);
          }
        });
    });
  }


  override getCurrentSensorData(params: {
                         deviceId: string;
                         context?: HttpContext
                       }
  ): Observable<SensorDataDto> {
    return new Observable(subscriber => {
      this.demoResponsePromise
        .then(demoResponse => {
          let currValue;
          let currTimestamp = moment();
          for (let sensorData of demoResponse) {
            if (moment(new Date(sensorData.timestamp)).isBefore(currTimestamp)) {
              subscriber.next(sensorData);
              return;
            }
          }
          subscriber.next(demoResponse[0]);

        });
    });
  }

  private filterDates(demoResponse, start: string, end: string) {
    let startDate = new Date(start);
    let endDate = end ? new Date(end) : new Date();
    const filteredSensorDataList = demoResponse.filter(dto => moment(new Date(dto.timestamp)).isBetween(start, end));
    return filteredSensorDataList;

  }

  private shiftSensorDataTimestamps(demoResponse) {
    const lastDate = new Date(demoResponse[0].timestamp).getTime();
    const currDate = new Date().getTime();
    let diff = currDate - lastDate;

    //Eltoljuk a dátumokat úgy, hogy a legutóbbi mérés az aktuális pillanatban legyen
    demoResponse.forEach(sensorDataDto => {
      sensorDataDto.timestamp = moment(new Date(sensorDataDto.timestamp).getTime() + diff).toISOString(true);
    });

    const firstDate = new Date(Math.min(...demoResponse.map(o => new Date(o.timestamp).getTime()))).getTime();
    diff = currDate - firstDate;
    let shiftedSensorDataDtos: Array<SensorDataDto> = demoResponse.map((sensorDataDto) => {
      let copySensorDataDto = _.cloneDeep(sensorDataDto);
      copySensorDataDto.timestamp = moment(new Date(sensorDataDto.timestamp).getTime() + diff).toISOString(true);

      return copySensorDataDto;
    });
    //Az utolsó elemet nem rakjuk bele,mert duplikáció lenne
    shiftedSensorDataDtos.pop();
    return demoResponse.concat(shiftedSensorDataDtos).sort((a, b) => {
      const dateA = new Date(a.timestamp);
      const dateB = new Date(b.timestamp);
      return dateB.getTime() - dateA.getTime();
    });

  }


}
