/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { NutritionDispenserDeviceDto } from '../models/nutrition-dispenser-device-dto';
import { PageNutritionDispenserDispenseHistoryDto } from '../models/page-nutrition-dispenser-dispense-history-dto';
import { PageNutritionDispenserSubstanceReloadHistoryDto } from '../models/page-nutrition-dispenser-substance-reload-history-dto';
import { Pageable } from '../models/pageable';
import { RefillNutritionDispenserDto } from '../models/refill-nutrition-dispenser-dto';

@Injectable({
  providedIn: 'root',
})
export class NutritionDispenserManagementImplService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation findNutritionDispenser
   */
  static readonly FindNutritionDispenserPath = '/nutrition-dispenser';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findNutritionDispenser()` instead.
   *
   * This method doesn't expect any request body.
   */
  findNutritionDispenser$Response(params: {
    deviceId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<NutritionDispenserDeviceDto>> {

    const rb = new RequestBuilder(this.rootUrl, NutritionDispenserManagementImplService.FindNutritionDispenserPath, 'get');
    if (params) {
      rb.query('deviceId', params.deviceId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<NutritionDispenserDeviceDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findNutritionDispenser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findNutritionDispenser(params: {
    deviceId: string;
    context?: HttpContext
  }
): Observable<NutritionDispenserDeviceDto> {

    return this.findNutritionDispenser$Response(params).pipe(
      map((r: StrictHttpResponse<NutritionDispenserDeviceDto>) => r.body as NutritionDispenserDeviceDto)
    );
  }

  /**
   * Path part for operation refillNutritionDispenser
   */
  static readonly RefillNutritionDispenserPath = '/nutrition-dispenser';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `refillNutritionDispenser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  refillNutritionDispenser$Response(params: {
    deviceId: string;
    context?: HttpContext
    body: RefillNutritionDispenserDto
  }
): Observable<StrictHttpResponse<NutritionDispenserDeviceDto>> {

    const rb = new RequestBuilder(this.rootUrl, NutritionDispenserManagementImplService.RefillNutritionDispenserPath, 'put');
    if (params) {
      rb.query('deviceId', params.deviceId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<NutritionDispenserDeviceDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `refillNutritionDispenser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  refillNutritionDispenser(params: {
    deviceId: string;
    context?: HttpContext
    body: RefillNutritionDispenserDto
  }
): Observable<NutritionDispenserDeviceDto> {

    return this.refillNutritionDispenser$Response(params).pipe(
      map((r: StrictHttpResponse<NutritionDispenserDeviceDto>) => r.body as NutritionDispenserDeviceDto)
    );
  }

  /**
   * Path part for operation getNutritionDispenserDeviceHistoryWithDeviceOperation
   */
  static readonly GetNutritionDispenserDeviceHistoryWithDeviceOperationPath = '/nutrition-dispenser/nutrition-dispenser-history';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNutritionDispenserDeviceHistoryWithDeviceOperation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNutritionDispenserDeviceHistoryWithDeviceOperation$Response(params: {
    deviceId: string;
    pageable: Pageable;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<PageNutritionDispenserDispenseHistoryDto>> {

    const rb = new RequestBuilder(this.rootUrl, NutritionDispenserManagementImplService.GetNutritionDispenserDeviceHistoryWithDeviceOperationPath, 'get');
    if (params) {
      rb.query('deviceId', params.deviceId, {});
      rb.query('pageable', params.pageable, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PageNutritionDispenserDispenseHistoryDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getNutritionDispenserDeviceHistoryWithDeviceOperation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNutritionDispenserDeviceHistoryWithDeviceOperation(params: {
    deviceId: string;
    pageable: Pageable;
    context?: HttpContext
  }
): Observable<PageNutritionDispenserDispenseHistoryDto> {

    return this.getNutritionDispenserDeviceHistoryWithDeviceOperation$Response(params).pipe(
      map((r: StrictHttpResponse<PageNutritionDispenserDispenseHistoryDto>) => r.body as PageNutritionDispenserDispenseHistoryDto)
    );
  }

  /**
   * Path part for operation getNutritionDispenserHistory
   */
  static readonly GetNutritionDispenserHistoryPath = '/nutrition-dispenser/get-nutrition-dispenser-history';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNutritionDispenserHistory()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNutritionDispenserHistory$Response(params: {
    deviceId: string;
    pageable: Pageable;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<PageNutritionDispenserSubstanceReloadHistoryDto>> {

    const rb = new RequestBuilder(this.rootUrl, NutritionDispenserManagementImplService.GetNutritionDispenserHistoryPath, 'get');
    if (params) {
      rb.query('deviceId', params.deviceId, {});
      rb.query('pageable', params.pageable, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PageNutritionDispenserSubstanceReloadHistoryDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getNutritionDispenserHistory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNutritionDispenserHistory(params: {
    deviceId: string;
    pageable: Pageable;
    context?: HttpContext
  }
): Observable<PageNutritionDispenserSubstanceReloadHistoryDto> {

    return this.getNutritionDispenserHistory$Response(params).pipe(
      map((r: StrictHttpResponse<PageNutritionDispenserSubstanceReloadHistoryDto>) => r.body as PageNutritionDispenserSubstanceReloadHistoryDto)
    );
  }

}
