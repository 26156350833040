import {Plugin} from "chart.js";

export const noDataPlugin: Plugin = {
  id: 'no_data_plugin',
  afterDatasetsDraw: (chart, args, options) => {
    // No data is present
    const {ctx, data, chartArea: {width, height, top, left}} = chart;
    ctx.save();
    if (data.datasets.length === 0 || (data.datasets.length === 1 && data.datasets[0].data.length === 0)) {
      ctx.fillStyle = 'rgba(102,102,102,0.5)';
      ctx.fillRect(left, top, width, height);
      ctx.font = "bold 24px sans-serif";
      ctx.textAlign = 'center';
      ctx.fillText('No data to display', left + width / 2, top + height / 2);
    }
  },
}


export const defaultChartOptions: any = {
  // maintainAspectRatio: false,
  elements: {
    line: {
      tension: 0.1,
    },
  },
  scales: {
    x: {
      type: 'time',
      time: {

        // unit: 'minute',
        // unitStepSize: 1,
        displayFormats: {
          'millisecond': 'HH:mm:ss.SSS',
          'second': 'HH:mm:ss',
          'minute': 'MMM DD HH:mm',
          'hour': 'MMM DD HH:mm',
          'day': 'MMM DD',
          'week': 'MMM DD',
          'month': 'MMM DD',
          'year': 'MMM DD',
        },


      }, ticks: {
        display: true,
        maxTicksLimit: 10,
      }, gridLines: {
        display: false
      }
    },
  },

  plugins: {
    legend: {display: false},
    annotation: {},
    tooltip: {
      displayColors: false
    }
  },
  animations: false
};
