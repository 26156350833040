<app-chart-filter
  [visible]="true"
  [isDateFilter]="false"
  [isAttributeFilter]="false"
  [deviceTypes]="[DeviceTypeEnum.NUTRITION_DISPENSER]"
  [isOnlyDeviceSelectable]="true"
  [isMultiple]="false"
  [isFrequencyFilter]="false"
  [isWorksheetFilter]="false"
  (onLocationChange)="onLocationChanged($event)"
  (onAttributeChange)="onAttributeChanged($event)">
</app-chart-filter>

<h3 class="no-chosen-device-or-location-warning"
    *ngIf="!deviceId">
  {{ 'hydroponics.worksheet.noLocationOrDeviceChosen' | translate }}
</h3>

<div *ngIf="deviceId">
  <app-device-operations [device]=device></app-device-operations>
</div>

