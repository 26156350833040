<!-- Toolbar -->
<div class="toolbar" role="banner">
  <div class="header-content">
    <div class="header-wraper-left">
      <div class="logo"></div>
    </div>
    <div class="header-wraper-center">
      <div class="page-title">Demo - {{'hydroponics.header.pageTitle' | translate}}</div>
    </div>
    <div class="header-wraper-right">
      <div class="nav-menu">
      </div>
      <div class="user-header-info"><div></div>
        <div class="logout">
        </div>
      </div>
    </div>
  </div>
</div>
