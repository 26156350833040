import {Component, OnDestroy, OnInit} from '@angular/core';
import {DeviceTypeEnum} from "../location-tree/location-tree.component";
import {ActivatedRoute} from "@angular/router";
import * as moment from "moment";
import {ChartFilterLocationChangeEvent, ChartFilterWorkTypeChangeEvent} from "../chart-filter/chart-filter.component";
import {NodeElement, NodeType} from "../locations/locations.component";
import {Subject, Subscription} from "rxjs";
import {ChartFilterService} from "../chart-filter/chart-filter.service";
import {LoadAlertsAndWorksheetsNumberService} from "../../service/load-alerts-and-worksheets-number.service";
import {
  WorksheetControllerImplService
} from "../../../generated/hydroponics-device-management-api/services/worksheet-controller-impl.service";
import {
  EventManagerControllerImplService
} from "../../../generated/hydroponics-device-management-api/services/event-manager-controller-impl.service";
import {WorksheetRequestDto} from "../../../generated/hydroponics-device-management-api/models/worksheet-request-dto";
import {WorksheetDto} from "../../../generated/hydroponics-device-management-api/models/worksheet-dto";
import {EventRequestDto} from "../../../generated/hydroponics-device-management-api/models/event-request-dto";
import {EventDto} from "../../../generated/hydroponics-device-management-api/models/event-dto";
import {WorkTypeEnum} from "../worksheet-create/worksheet-create.component";

interface PageEvent {
  first: number;
  rows: number;
  page: number;
  pageCount: number;
}

export interface ChartFilterDateChangeEvent {
  rangeDates: Date[];
}

@Component({
  selector: 'app-worksheet-dashboard',
  templateUrl: './worksheet-dashboard.component.html',
  styleUrls: ['./worksheet-dashboard.component.sass']
})
export class WorksheetDashboardComponent implements OnInit, OnDestroy {
  protected readonly DeviceTypeEnum = DeviceTypeEnum;
  protected deviceIds: string[] = [];
  protected locationIds: any[] = [];
  protected workElements: WorksheetDto[] | EventDto[] = [];
  private type: string;
  totalRows = 0;
  pageSize = 10;
  first = 0;
  currentPage = 0;
  pageSizeOptions: number[] = [5, 10, 15, 30, 50, 100];
  worksheetType: string;
  private rangeDates: Date[];
  chartFilterChangeSubjectSensor: Subject<any> = new Subject<any>();
  private loadAlertsAndWorksheetsNumberServiceSubscription: Subscription;

  constructor(private route: ActivatedRoute,
              public worksheetControllerImplService: WorksheetControllerImplService,
              public eventControllerImplService: EventManagerControllerImplService,
              public chartFilterService: ChartFilterService,
              private loadAlertsAndWorksheetsNumberService: LoadAlertsAndWorksheetsNumberService,) {
  }

  ngOnInit(): void {
    this.type = this.route.snapshot.paramMap.get('type') || '';

    if (this.route.snapshot.paramMap.get('id')) {
      this.worksheetType = 'WORKSHEET'
    }

    if (this.type == 'LOCATION') {
      this.locationIds.push(this.route.snapshot.paramMap.get('id') || '');
    } else if (this.type == 'DEVICE') {
      this.deviceIds.push(this.route.snapshot.paramMap.get('id') || '');
    }
    this.chartFilterService.markInitialized();

   // this.callChartFilterChangeSubjectSensor();

    if (this.rangeDates && this.rangeDates.length > 0) {
      this.loadData(this.rangeDates[0], this.rangeDates[1]);
    }

    this.loadAlertsAndWorksheetsNumberServiceSubscription = this.loadAlertsAndWorksheetsNumberService.event$.subscribe(value => {
      this.loadData(this.rangeDates[0], null);
    });
  }

  addDevicesToChart(nodeElement: NodeElement) {
    if (nodeElement.nodeType && nodeElement.nodeType == NodeType.DEVICE) {
      this.deviceIds.push(nodeElement.id)
    } else if (nodeElement.nodeType && nodeElement.nodeType == NodeType.LOCATION) {
      this.locationIds.push(nodeElement.id);
    }

    if (nodeElement.children) {
      nodeElement.children.forEach(child => {
        this.addDevicesToChart(child);
      })
    }
  }

  locationChanged(event: ChartFilterLocationChangeEvent) {
    this.deviceIds = [];
    this.locationIds = [];
    if (event.selectedNodes) {
      event.selectedNodes.forEach((nodeElement, key) => {
        this.addDevicesToChart(nodeElement);
      });
      if(this.worksheetType){
        this.loadData(this.rangeDates[0], this.rangeDates[1]);
      }
    }
  }

  eventTypeChanged(event: ChartFilterWorkTypeChangeEvent) {
    if (event.worksheetType) {
      this.worksheetType = event.worksheetType;
      if(this.locationIds.length >0 || this.deviceIds.length > 0){
        this.loadData(this.rangeDates[0], this.rangeDates[1]);
      }
    }
  }

  private loadData(startDate: Date, endDate: Date) {
    this.workElements = [];
    if(this.worksheetType === WorkTypeEnum.WORKSHEET){
      let request :WorksheetRequestDto = {
        startDate: moment(startDate).tz('Europe/Budapest').format('yyyy-MM-DDTHH:mm:ss.SSS'),
        endDate: endDate == null ? null : moment(endDate).tz('Europe/Budapest').format('yyyy-MM-DDTHH:mm:ss.SSS'),
        deviceIds: this.deviceIds,
        locationIds: this.locationIds,
      }
      this.worksheetControllerImplService.getWorksheets({
        body: request,
        pageable: {page: this.currentPage, size: this.pageSize}
      }).subscribe(worksheets =>{
        this.totalRows = worksheets.totalElements;
        this.workElements = worksheets.content;
      })
    }else if(this.worksheetType === WorkTypeEnum.EVENT){
      let request :EventRequestDto = {
        startDate: moment(startDate).tz('Europe/Budapest').format('yyyy-MM-DDTHH:mm:ss.SSS'),
        endDate: endDate == null ? null : moment(endDate).tz('Europe/Budapest').format('yyyy-MM-DDTHH:mm:ss.SSS'),
        deviceIds: this.deviceIds,
        locationIds: this.locationIds,
      }
      this.eventControllerImplService.getEvents({
        body: request,
        pageable: {page: this.currentPage, size: this.pageSize}
      }).subscribe(events =>{
        this.totalRows = events.totalElements;
        this.workElements = events.content;
      })
    }
  }

  ngOnDestroy(): void {
    if (this.loadAlertsAndWorksheetsNumberServiceSubscription) {
      this.loadAlertsAndWorksheetsNumberServiceSubscription.unsubscribe();
    }
  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.rows;
    this.first = event.first;
    this.currentPage = event.page

    this.loadData(this.rangeDates[0], this.rangeDates[1]);
  }

  onDateChange(event: ChartFilterDateChangeEvent) {
    this.rangeDates = event.rangeDates;
    this.callChartFilterChangeSubjectSensor();
  }

  onWorksheetChange(workElementId:number) {
    this.loadData(this.rangeDates[0], this.rangeDates[1]);
    const element = document.getElementById('workElement-' + workElementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  callChartFilterChangeSubjectSensor(){
    this.chartFilterChangeSubjectSensor.next({rangeDates: this.rangeDates, locationIds: this.locationIds});
    this.loadData(this.rangeDates[0], this.rangeDates[1]);
  }
}
