import {AfterViewInit, Component, OnInit} from '@angular/core';
import {KeycloakService} from 'keycloak-angular';
import {KeycloakProfile} from 'keycloak-js';
import {AuthorizedUserManagementControllerImplService} from "../../generated/hydroponics-user-management-api/services/authorized-user-management-controller-impl.service";
import {UserDto} from "../../generated/hydroponics-user-management-api/models/user-dto";
import {BehaviorSubject, Subscription} from "rxjs";

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.sass']
})
export class AuthComponent {

  public isLoggedIn = false;
  public roles: string[];
  public userProfile=new BehaviorSubject<KeycloakProfile>(null);
  constructor(private readonly keycloak: KeycloakService,
              private readonly userManagementService: AuthorizedUserManagementControllerImplService) {
    this.initUserProfile();
  }

  /**
   * User profile adatok lekérése
   */
  public async initUserProfile() {
    this.keycloak.isLoggedIn().then((res)=>{
      if(res){
        this.keycloak.loadUserProfile().then((res) => this.userProfile.next(res));
        this.roles = this.keycloak.getUserRoles(true);
      }
    })
  }

  public hasAllRoles(roles: string[]): boolean{
    return roles.every(r =>  this.roles?.includes(r));
  }

  public hasRole(role: string): boolean{
    return this.roles?.includes(role);
  }

  public login() {
    this.keycloak.login();
  }

  public logout() {
    this.keycloak.logout();
  }

}
