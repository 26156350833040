import {Component, Input, OnInit} from '@angular/core';
import {WorksheetCommentDto} from "../../../generated/hydroponics-device-management-api/models/worksheet-comment-dto";
import {DateService} from "../../util/date.service";

@Component({
  selector: 'app-worksheet-comment',
  templateUrl: './worksheet-comment.component.html',
  styleUrls: ['./worksheet-comment.component.sass']
})
export class WorksheetCommentComponent implements OnInit {
  @Input() comment: WorksheetCommentDto
  constructor(protected dateService: DateService) { }

  ngOnInit(): void {
  }

}
