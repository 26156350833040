import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {DateService} from "../../../../util/date.service";
import {DeviceOperationControllerImplService} from "../../../../../generated/hydroponics-device-management-api/services/device-operation-controller-impl.service";
import {OperateOnDeviceRequest} from "../../../../../generated/hydroponics-device-management-api/models/operate-on-device-request";
import {DeviceTypeOperationParamDto} from "../../../../../generated/hydroponics-device-management-api/models/device-type-operation-param-dto";
import {DeviceOperationParamDto} from "../../../../../generated/hydroponics-device-management-api/models/device-operation-param-dto";
import {OperateOnDeviceResponse} from "../../../../../generated/hydroponics-device-management-api/models/operate-on-device-response";
import {interval, Subscription} from "rxjs";
import {DeviceOperationDto} from "../../../../../generated/hydroponics-device-management-api/models/device-operation-dto";
import {ImpedanceManagementControllerImplService} from "../../../../../generated/hydroponics-device-management-api/services/impedance-management-controller-impl.service";
import {ImpedanceSensorDataDto} from "../../../../../generated/hydroponics-device-management-api/models/impedance-sensor-data-dto";

@Component({
  selector: 'app-manual-measure',
  templateUrl: './impedance-manual-measure.component.html',
  styleUrls: ['./impedance-manual-measure.component.sass']
})
export class ImpedanceManualMeasureComponent implements OnInit, OnDestroy {

  private responsePoll: Subscription;
  private readonly dataType:  'IMPEDANCE_SENSOR' ;
  deviceOperationParamDto: Array<{ deviceTypeOperationParam: DeviceTypeOperationParamDto, actualParam: DeviceOperationParamDto }> = [];
  response: OperateOnDeviceResponse;
  manualMeasurementData: ImpedanceSensorDataDto;
  deviceOperationData: DeviceOperationDto;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { deviceId: string },
              private deviceOperationService: DeviceOperationControllerImplService,
              private impedanceManagementService: ImpedanceManagementControllerImplService,
              public dateService: DateService) {

  }

  ngOnInit(): void {
    this.getDeviceTypeOperations();

  }

  startMeasure() {
    let operateOnDeviceRequest: OperateOnDeviceRequest = {};
    operateOnDeviceRequest.operationCode = 'MANUAL_MEASURE';
    this.manualMeasurementData = null;
    this.deviceOperationData = null;

    let param: Array<DeviceOperationParamDto> = [{name: 'dataType', stringValue: this.dataType}];
    operateOnDeviceRequest.deviceOperationParams = param;
    // operateOnDeviceRequest.deviceOperationParams = this.deviceOperationParamDto.map(p => p.actualParam);
    this.deviceOperationService.operateOnDevice({
      deviceId: this.data.deviceId,
      body: operateOnDeviceRequest
    }).subscribe((res) => {
      this.response = res;

      this.responsePoll = interval(1000).subscribe(() => {
        const lastDeviceOperationStatus = this.deviceOperationData?.status;
        if (this.manualMeasurementData
          || lastDeviceOperationStatus == "START_FAILED"
          || lastDeviceOperationStatus == "COMMAND_SEND_ERROR"
          || lastDeviceOperationStatus == "CONFIRMATION_ERROR") {
          this.responsePoll.unsubscribe();
        } else {
          this.refreshDeviceOperationAndSensorData(res.deviceOperationId);
        }

      });
    });

  }

  refreshDeviceOperationAndSensorData(deviceOperationId: number) {
    this.deviceOperationService.getDeviceOperation({
      deviceOperationId: deviceOperationId
    }).subscribe(value => {
      this.deviceOperationData = value;
      if (this.deviceOperationData.status == "CONFIRMED") {
        this.getManualMeasurementData(deviceOperationId);
      }

    });
  }

  getManualMeasurementData(deviceOperationId: number) {
    this.impedanceManagementService.getManualMeasurementData1({
      deviceId: this.data.deviceId,
      deviceOperationId: deviceOperationId.toString()
    }).subscribe(value => {
      this.manualMeasurementData = value;
    });
  }

  getDeviceTypeOperations() {
    this.deviceOperationService.getDeviceTypeOperations({deviceId: this.data.deviceId}).subscribe(value => {
      let deviceTypeOperationDtos = value.deviceTypeOperationDTO;
      let pickedDeviceTypeOperationDto = value.deviceTypeOperationDTO[0];
      if (pickedDeviceTypeOperationDto.deviceTypeOperationParams) {
        this.deviceOperationParamDto = pickedDeviceTypeOperationDto.deviceTypeOperationParams.map((p) => {
          return {
            deviceTypeOperationParam: p,
            actualParam: {
              name: p.name,
            }
          }
        });
      }
    });
  }


  closeManualMeasuerement() {
    if (this.responsePoll && !this.responsePoll.closed) {
      this.responsePoll.unsubscribe();
    }

  }

  ngOnDestroy(): void {
    this.closeManualMeasuerement();
  }
}
