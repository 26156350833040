import {Component, Inject, OnInit} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {CalibrationPointDto} from "../../../../../generated/hydroponics-device-management-api/models/calibration-point-dto";
import {PageEvent} from "@angular/material/paginator";
import {FormControl} from "@angular/forms";
import {ImpedanceManagementControllerImplService} from "../../../../../generated/hydroponics-device-management-api/services/impedance-management-controller-impl.service";
import {ImpedanceCalibrationDto} from "../../../../../generated/hydroponics-device-management-api/models/impedance-calibration-dto";
import {SaveImpedanceCalibrationDataRequest} from "../../../../../generated/hydroponics-device-management-api/models/save-impedance-calibration-data-request";
import {SensorManagementImplService} from "../../../../../generated/hydroponics-device-management-api/services/sensor-management-impl.service";
import {DateService} from "../../../../util/date.service";

@Component({
  selector: 'app-impedance-sensor-calibration',
  templateUrl: './impedance-sensor-calibration.component.html',
  styleUrls: ['./impedance-sensor-calibration.component.sass']
})
export class ImpedanceSensorCalibrationComponent implements OnInit {

  isLoading = false;
  totalRows = 0;
  pageSize = 5;
  currentPage = 0;
  pageSizeOptions: number[] = [5, 10, 25, 100];


  displayedColumns: string[] = ['record_timestamp', 'calibration_timestamp', 'frequency', 'reference_value_1', 'measured_value_1', 'reference_value_2', 'measured_value_2'];
  dataSource: MatTableDataSource<ImpedanceCalibrationDto> = new MatTableDataSource();

  public saveImpedanceCalibrationDataRequest: SaveImpedanceCalibrationDataRequest;
  public pickedFrequency: number;
  public frequencies = new FormControl('');
  chosedDate: Date;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { dataType: String, deviceId: string, frequenciesList: number[] },
              private sensorManagementService: SensorManagementImplService, private impedanceManagementControllerImplService: ImpedanceManagementControllerImplService, public dateService: DateService) {
  }

  ngOnInit() {
    let firstCalibrationPointDto = {} as CalibrationPointDto;
    let secondCalibrationPointDto = {} as CalibrationPointDto;
    this.chosedDate = new Date(Date.now());
    this.getImpedanceCalibrations();
    this.saveImpedanceCalibrationDataRequest = {
      calibrationPoints: [
        firstCalibrationPointDto,
        secondCalibrationPointDto
      ]
    };
  }

  getImpedanceCalibrations() {
    this.isLoading = true;
    this.impedanceManagementControllerImplService.getImpedanceCalibrationData({
      deviceId: this.data.deviceId,
      pageable: {page: this.currentPage, size: this.pageSize, sort: ["timestamp", "desc"]}
    }).subscribe(data => {
      this.totalRows = data.totalElements;
      this.dataSource.data = this.sortCalibrations(data.impedanceCalibrationDTOS);
      this.isLoading = false;
    });
  }

  saveCalibration() {
    this.impedanceManagementControllerImplService.saveImpedanceCalibrationData({
      deviceId: this.data.deviceId,
      frequency: this.pickedFrequency,
      body: this.saveImpedanceCalibrationDataRequest,
      calibrationDate: DateService.getLocalISOString(new Date(this.chosedDate)),
    }).subscribe()
    this.getImpedanceCalibrations();
  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.getImpedanceCalibrations();
  }


  sortCalibrations(impedanceCalibrationDtos: Array<ImpedanceCalibrationDto>):
    Array<ImpedanceCalibrationDto> {
    impedanceCalibrationDtos.forEach(function (value) {
      value.impedanceSensorCalibrationPoints.sort(function compareFn(firstCalibrationPoint, secondCalibrationPoint) {
        if (firstCalibrationPoint.valueX > secondCalibrationPoint.valueX) {
          return 1;
        } else {
          return -1;
        }
      });
    });
    return impedanceCalibrationDtos;
  }
}
