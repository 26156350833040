/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { GetImpedanceCalibrationDataResponse } from '../models/get-impedance-calibration-data-response';
import { ImpedanceSensorDataDto } from '../models/impedance-sensor-data-dto';
import { ImpedanceSensorDataResponse } from '../models/impedance-sensor-data-response';
import { Pageable } from '../models/pageable';
import { SaveImpedanceCalibrationDataRequest } from '../models/save-impedance-calibration-data-request';

@Injectable({
  providedIn: 'root',
})
export class ImpedanceManagementControllerImplService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation saveImpedanceCalibrationData
   */
  static readonly SaveImpedanceCalibrationDataPath = '/impedance/save-impedance-calibration-data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveImpedanceCalibrationData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveImpedanceCalibrationData$Response(params: {
    deviceId: string;
    frequency: number;
    calibrationDate: string;
    context?: HttpContext
    body: SaveImpedanceCalibrationDataRequest
  }
): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, ImpedanceManagementControllerImplService.SaveImpedanceCalibrationDataPath, 'post');
    if (params) {
      rb.query('deviceId', params.deviceId, {});
      rb.query('frequency', params.frequency, {});
      rb.query('calibrationDate', params.calibrationDate, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `saveImpedanceCalibrationData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveImpedanceCalibrationData(params: {
    deviceId: string;
    frequency: number;
    calibrationDate: string;
    context?: HttpContext
    body: SaveImpedanceCalibrationDataRequest
  }
): Observable<{
}> {

    return this.saveImpedanceCalibrationData$Response(params).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Path part for operation getManualMeasurementDataHistory
   */
  static readonly GetManualMeasurementDataHistoryPath = '/impedance/manual-measurement-data-history';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getManualMeasurementDataHistory()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getManualMeasurementDataHistory$Response(params: {
    start: string;
    end?: string;
    deviceId: string;
    context?: HttpContext
    body: Array<number>
  }
): Observable<StrictHttpResponse<Array<ImpedanceSensorDataDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ImpedanceManagementControllerImplService.GetManualMeasurementDataHistoryPath, 'post');
    if (params) {
      rb.query('start', params.start, {});
      rb.query('end', params.end, {});
      rb.query('deviceId', params.deviceId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ImpedanceSensorDataDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getManualMeasurementDataHistory$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getManualMeasurementDataHistory(params: {
    start: string;
    end?: string;
    deviceId: string;
    context?: HttpContext
    body: Array<number>
  }
): Observable<Array<ImpedanceSensorDataDto>> {

    return this.getManualMeasurementDataHistory$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ImpedanceSensorDataDto>>) => r.body as Array<ImpedanceSensorDataDto>)
    );
  }

  /**
   * Path part for operation getImpedanceSensorDataWithOriginalData
   */
  static readonly GetImpedanceSensorDataWithOriginalDataPath = '/impedance/impedance-original-data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getImpedanceSensorDataWithOriginalData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getImpedanceSensorDataWithOriginalData$Response(params: {
    start: string;
    end?: string;
    deviceId: string;
    context?: HttpContext
    body: Array<number>
  }
): Observable<StrictHttpResponse<Array<ImpedanceSensorDataResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, ImpedanceManagementControllerImplService.GetImpedanceSensorDataWithOriginalDataPath, 'post');
    if (params) {
      rb.query('start', params.start, {});
      rb.query('end', params.end, {});
      rb.query('deviceId', params.deviceId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ImpedanceSensorDataResponse>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getImpedanceSensorDataWithOriginalData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getImpedanceSensorDataWithOriginalData(params: {
    start: string;
    end?: string;
    deviceId: string;
    context?: HttpContext
    body: Array<number>
  }
): Observable<Array<ImpedanceSensorDataResponse>> {

    return this.getImpedanceSensorDataWithOriginalData$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ImpedanceSensorDataResponse>>) => r.body as Array<ImpedanceSensorDataResponse>)
    );
  }

  /**
   * Path part for operation getImpedanceSensorData
   */
  static readonly GetImpedanceSensorDataPath = '/impedance/impedance-data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getImpedanceSensorData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getImpedanceSensorData$Response(params: {
    start: string;
    end?: string;
    deviceId: string;
    context?: HttpContext
    body: Array<number>
  }
): Observable<StrictHttpResponse<Array<ImpedanceSensorDataDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ImpedanceManagementControllerImplService.GetImpedanceSensorDataPath, 'post');
    if (params) {
      rb.query('start', params.start, {});
      rb.query('end', params.end, {});
      rb.query('deviceId', params.deviceId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ImpedanceSensorDataDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getImpedanceSensorData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getImpedanceSensorData(params: {
    start: string;
    end?: string;
    deviceId: string;
    context?: HttpContext
    body: Array<number>
  }
): Observable<Array<ImpedanceSensorDataDto>> {

    return this.getImpedanceSensorData$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ImpedanceSensorDataDto>>) => r.body as Array<ImpedanceSensorDataDto>)
    );
  }

  /**
   * Path part for operation getManualMeasurementData1
   */
  static readonly GetManualMeasurementData1Path = '/impedance/manual-measurement-data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getManualMeasurementData1()` instead.
   *
   * This method doesn't expect any request body.
   */
  getManualMeasurementData1$Response(params: {
    deviceId: string;
    deviceOperationId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ImpedanceSensorDataDto>> {

    const rb = new RequestBuilder(this.rootUrl, ImpedanceManagementControllerImplService.GetManualMeasurementData1Path, 'get');
    if (params) {
      rb.query('deviceId', params.deviceId, {});
      rb.query('deviceOperationId', params.deviceOperationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ImpedanceSensorDataDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getManualMeasurementData1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getManualMeasurementData1(params: {
    deviceId: string;
    deviceOperationId: string;
    context?: HttpContext
  }
): Observable<ImpedanceSensorDataDto> {

    return this.getManualMeasurementData1$Response(params).pipe(
      map((r: StrictHttpResponse<ImpedanceSensorDataDto>) => r.body as ImpedanceSensorDataDto)
    );
  }

  /**
   * Path part for operation getImpedanceCalibrationData
   */
  static readonly GetImpedanceCalibrationDataPath = '/impedance/get-impedance-calibration-data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getImpedanceCalibrationData()` instead.
   *
   * This method doesn't expect any request body.
   */
  getImpedanceCalibrationData$Response(params: {
    deviceId: string;
    pageable: Pageable;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<GetImpedanceCalibrationDataResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ImpedanceManagementControllerImplService.GetImpedanceCalibrationDataPath, 'get');
    if (params) {
      rb.query('deviceId', params.deviceId, {});
      rb.query('pageable', params.pageable, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetImpedanceCalibrationDataResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getImpedanceCalibrationData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getImpedanceCalibrationData(params: {
    deviceId: string;
    pageable: Pageable;
    context?: HttpContext
  }
): Observable<GetImpedanceCalibrationDataResponse> {

    return this.getImpedanceCalibrationData$Response(params).pipe(
      map((r: StrictHttpResponse<GetImpedanceCalibrationDataResponse>) => r.body as GetImpedanceCalibrationDataResponse)
    );
  }

  /**
   * Path part for operation getFrequencies
   */
  static readonly GetFrequenciesPath = '/impedance/frequenceies';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFrequencies()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFrequencies$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<string>>> {

    const rb = new RequestBuilder(this.rootUrl, ImpedanceManagementControllerImplService.GetFrequenciesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFrequencies$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFrequencies(params?: {
    context?: HttpContext
  }
): Observable<Array<string>> {

    return this.getFrequencies$Response(params).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }

  /**
   * Path part for operation getFirstSensorDataTimestamp1
   */
  static readonly GetFirstSensorDataTimestamp1Path = '/impedance/first-data-timestamp';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFirstSensorDataTimestamp1()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFirstSensorDataTimestamp1$Response(params: {
    deviceId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, ImpedanceManagementControllerImplService.GetFirstSensorDataTimestamp1Path, 'get');
    if (params) {
      rb.query('deviceId', params.deviceId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFirstSensorDataTimestamp1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFirstSensorDataTimestamp1(params: {
    deviceId: string;
    context?: HttpContext
  }
): Observable<string> {

    return this.getFirstSensorDataTimestamp1$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getCurrentSensorData1
   */
  static readonly GetCurrentSensorData1Path = '/impedance/curr-data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCurrentSensorData1()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentSensorData1$Response(params: {
    deviceId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ImpedanceSensorDataDto>> {

    const rb = new RequestBuilder(this.rootUrl, ImpedanceManagementControllerImplService.GetCurrentSensorData1Path, 'get');
    if (params) {
      rb.query('deviceId', params.deviceId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ImpedanceSensorDataDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCurrentSensorData1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentSensorData1(params: {
    deviceId: string;
    context?: HttpContext
  }
): Observable<ImpedanceSensorDataDto> {

    return this.getCurrentSensorData1$Response(params).pipe(
      map((r: StrictHttpResponse<ImpedanceSensorDataDto>) => r.body as ImpedanceSensorDataDto)
    );
  }

}
