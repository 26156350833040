import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PlantProfileDto} from "../../../generated/hydroponics-device-management-api/models/plant-profile-dto";
import {
  PlantProfileManagerControllerImplService
} from "../../../generated/hydroponics-device-management-api/services/plant-profile-manager-controller-impl.service";
import {
  PlantProfileAttributeDto
} from "../../../generated/hydroponics-device-management-api/models/plant-profile-attribute-dto";
import {
  PlantProfileAttributeCustomDto
} from "../../../generated/hydroponics-device-management-api/models/plant-profile-attribute-custom-dto";
import {Subject} from "rxjs";
import {PlantProfileService} from "./plant-profile.service";

export enum ProfileType {
  DEFAULT = "DEFAULT",
  LOCATION = "LOCATION",
  DEVICE = "DEVICE"
}

export interface PlantProfileSaveEvent {
  selectedProfile: PlantProfileDto,
  profileAttributes: PlantProfileAttributeDto[],
  defaultProfile: boolean
}

@Component({
  selector: 'app-plant-profile-attribute-manager',
  templateUrl: './plant-profile-attribute-manager.component.html',
  styleUrls: ['./plant-profile-attribute-manager.component.sass']
})
export class PlantProfileAttributeManagerComponent implements OnInit {
  protected readonly ProfileType = ProfileType;
  @Input() dataType: String
  @Input() isViewOnly: boolean
  @Input() title: string
  @Input() plantProfileEditable: boolean
  @Input() defaultProfile: boolean
  @Input() id: any
  @Input() type: ProfileType
  @Input() selectedProfile: PlantProfileDto
  @Input('loadPlantProfileSubject') loadPlantProfileSubject:Subject<void>

  @Output() onPlantProfileSave: EventEmitter<PlantProfileSaveEvent> = new EventEmitter()

  plantProfiles: PlantProfileDto[] = []
  modifiedAttributes: PlantProfileAttributeDto[] = []

  constructor(private plantProfileManagerControllerImplService: PlantProfileManagerControllerImplService,
              private plantProfileService: PlantProfileService) {
  }

  ngOnInit(): void {
    this.plantProfileService.loadPlantProfiles();
    this.plantProfiles = this.plantProfileService.plantProfiles;

    if (this.loadPlantProfileSubject){
      this.loadPlantProfileSubject.subscribe(e => {
        this.loadCustomProfileAttributes().then(r => {});
      });
    }
  }

  ngOnDestroy() {
    if (this.loadPlantProfileSubject){
      this.loadPlantProfileSubject.unsubscribe();
    }
  }

  onChangeProfile(){
    this.loadCustomProfileAttributes().then(r => {});
  }

  onUseDefaultAttributes(){
    this.modifiedAttributes = [];
    this.selectedProfile.plantProfileAttributes.forEach(pa =>
    {
      this.modifiedAttributes.push(Object.assign({}, pa));
    })
  }

  private async loadCustomProfileAttributes(){
    this.modifiedAttributes = [];

    if (this.type == ProfileType.DEFAULT) {
      this.processAttributes([]);
    } else if (this.type == ProfileType.LOCATION) {
      await this.plantProfileManagerControllerImplService.getCustomAttributesByLocation({location: this.id}).subscribe(customAttributes => {
        this.processAttributes(customAttributes);
      });
    } else if (this.type == ProfileType.DEVICE){
      await this.plantProfileManagerControllerImplService.getCustomAttributesByDevice({device: this.id}).subscribe(customAttributes => {
        this.processAttributes(customAttributes);
      });
    }
  }

  private processAttributes(customAttributes: PlantProfileAttributeCustomDto[]){
    this.defaultProfile = true;
    if (this.selectedProfile){
      this.selectedProfile.plantProfileAttributes.forEach(pa => {
        let attr = customAttributes.find(attr => attr.plantProfileAttribute.code === pa.code);
        if (attr && attr != undefined) {
          this.defaultProfile = false;
          this.modifiedAttributes.push(Object.assign({}, {
            code: pa.code,
            maxValue: attr.maxValue,
            minValue: attr.minValue
          }));
        } else {
          this.modifiedAttributes.push(Object.assign({}, pa));
        }
      })
    }
  }

  plantProfileSave() {
    this.onPlantProfileSave.emit({
      selectedProfile: this.selectedProfile,
      profileAttributes: this.modifiedAttributes,
      defaultProfile: this.defaultProfile
    });
  }
}
