/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { GetCalibrationDataResponse } from '../models/get-calibration-data-response';
import { Pageable } from '../models/pageable';
import { SaveBaseSensorTelemetryDataRequest } from '../models/save-base-sensor-telemetry-data-request';
import { SaveNaSensorCalibrationDataRequest } from '../models/save-na-sensor-calibration-data-request';
import { SensorDataDto } from '../models/sensor-data-dto';
import { SensorDataRawDto } from '../models/sensor-data-raw-dto';

@Injectable({
  providedIn: 'root',
})
export class SensorManagementImplService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation saveNaSensorData
   */
  static readonly SaveNaSensorDataPath = '/sensor/save-na-sensor-data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveNaSensorData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveNaSensorData$Response(params: {
    context?: HttpContext
    body: SaveBaseSensorTelemetryDataRequest
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, SensorManagementImplService.SaveNaSensorDataPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `saveNaSensorData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveNaSensorData(params: {
    context?: HttpContext
    body: SaveBaseSensorTelemetryDataRequest
  }
): Observable<void> {

    return this.saveNaSensorData$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation saveCalibrationData
   */
  static readonly SaveCalibrationDataPath = '/sensor/save-calibration-data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveCalibrationData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveCalibrationData$Response(params: {
    deviceId: string;
    calibrationDate: string;
    context?: HttpContext
    body: SaveNaSensorCalibrationDataRequest
  }
): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, SensorManagementImplService.SaveCalibrationDataPath, 'post');
    if (params) {
      rb.query('deviceId', params.deviceId, {});
      rb.query('calibrationDate', params.calibrationDate, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `saveCalibrationData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveCalibrationData(params: {
    deviceId: string;
    calibrationDate: string;
    context?: HttpContext
    body: SaveNaSensorCalibrationDataRequest
  }
): Observable<{
}> {

    return this.saveCalibrationData$Response(params).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Path part for operation getManualMeasurementData
   */
  static readonly GetManualMeasurementDataPath = '/sensor/manual-measurement-data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getManualMeasurementData()` instead.
   *
   * This method doesn't expect any request body.
   */
  getManualMeasurementData$Response(params: {
    deviceId: string;
    deviceOperationId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<SensorDataRawDto>> {

    const rb = new RequestBuilder(this.rootUrl, SensorManagementImplService.GetManualMeasurementDataPath, 'get');
    if (params) {
      rb.query('deviceId', params.deviceId, {});
      rb.query('deviceOperationId', params.deviceOperationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SensorDataRawDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getManualMeasurementData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getManualMeasurementData(params: {
    deviceId: string;
    deviceOperationId: string;
    context?: HttpContext
  }
): Observable<SensorDataRawDto> {

    return this.getManualMeasurementData$Response(params).pipe(
      map((r: StrictHttpResponse<SensorDataRawDto>) => r.body as SensorDataRawDto)
    );
  }

  /**
   * Path part for operation getManualMeasurementDataHistory1
   */
  static readonly GetManualMeasurementDataHistory1Path = '/sensor/manual-measurement-data-history';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getManualMeasurementDataHistory1()` instead.
   *
   * This method doesn't expect any request body.
   */
  getManualMeasurementDataHistory1$Response(params: {
    start: string;
    end: string;
    deviceId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<SensorDataRawDto>>> {

    const rb = new RequestBuilder(this.rootUrl, SensorManagementImplService.GetManualMeasurementDataHistory1Path, 'get');
    if (params) {
      rb.query('start', params.start, {});
      rb.query('end', params.end, {});
      rb.query('deviceId', params.deviceId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SensorDataRawDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getManualMeasurementDataHistory1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getManualMeasurementDataHistory1(params: {
    start: string;
    end: string;
    deviceId: string;
    context?: HttpContext
  }
): Observable<Array<SensorDataRawDto>> {

    return this.getManualMeasurementDataHistory1$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SensorDataRawDto>>) => r.body as Array<SensorDataRawDto>)
    );
  }

  /**
   * Path part for operation getManualSensorData
   */
  static readonly GetManualSensorDataPath = '/sensor/manual-data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getManualSensorData()` instead.
   *
   * This method doesn't expect any request body.
   */
  getManualSensorData$Response(params: {
    start: string;
    end: string;
    deviceId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<SensorDataDto>>> {

    const rb = new RequestBuilder(this.rootUrl, SensorManagementImplService.GetManualSensorDataPath, 'get');
    if (params) {
      rb.query('start', params.start, {});
      rb.query('end', params.end, {});
      rb.query('deviceId', params.deviceId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SensorDataDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getManualSensorData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getManualSensorData(params: {
    start: string;
    end: string;
    deviceId: string;
    context?: HttpContext
  }
): Observable<Array<SensorDataDto>> {

    return this.getManualSensorData$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SensorDataDto>>) => r.body as Array<SensorDataDto>)
    );
  }

  /**
   * Path part for operation getCalibrationData
   */
  static readonly GetCalibrationDataPath = '/sensor/get-calibration-data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCalibrationData()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCalibrationData$Response(params: {
    deviceId: string;
    dataType: string;
    pageable: Pageable;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<GetCalibrationDataResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SensorManagementImplService.GetCalibrationDataPath, 'get');
    if (params) {
      rb.query('deviceId', params.deviceId, {});
      rb.query('dataType', params.dataType, {});
      rb.query('pageable', params.pageable, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetCalibrationDataResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCalibrationData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCalibrationData(params: {
    deviceId: string;
    dataType: string;
    pageable: Pageable;
    context?: HttpContext
  }
): Observable<GetCalibrationDataResponse> {

    return this.getCalibrationData$Response(params).pipe(
      map((r: StrictHttpResponse<GetCalibrationDataResponse>) => r.body as GetCalibrationDataResponse)
    );
  }

  /**
   * Path part for operation getFirstSensorDataTimestamp
   */
  static readonly GetFirstSensorDataTimestampPath = '/sensor/first-data-timestamp';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFirstSensorDataTimestamp()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFirstSensorDataTimestamp$Response(params: {
    deviceId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, SensorManagementImplService.GetFirstSensorDataTimestampPath, 'get');
    if (params) {
      rb.query('deviceId', params.deviceId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFirstSensorDataTimestamp$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFirstSensorDataTimestamp(params: {
    deviceId: string;
    context?: HttpContext
  }
): Observable<string> {

    return this.getFirstSensorDataTimestamp$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getSensorData
   */
  static readonly GetSensorDataPath = '/sensor/data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSensorData()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSensorData$Response(params: {
    start: string;
    end: string;
    deviceId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<SensorDataDto>>> {

    const rb = new RequestBuilder(this.rootUrl, SensorManagementImplService.GetSensorDataPath, 'get');
    if (params) {
      rb.query('start', params.start, {});
      rb.query('end', params.end, {});
      rb.query('deviceId', params.deviceId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SensorDataDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSensorData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSensorData(params: {
    start: string;
    end: string;
    deviceId: string;
    context?: HttpContext
  }
): Observable<Array<SensorDataDto>> {

    return this.getSensorData$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SensorDataDto>>) => r.body as Array<SensorDataDto>)
    );
  }

  /**
   * Path part for operation getCurrentSensorData
   */
  static readonly GetCurrentSensorDataPath = '/sensor/curr-data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCurrentSensorData()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentSensorData$Response(params: {
    deviceId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<SensorDataDto>> {

    const rb = new RequestBuilder(this.rootUrl, SensorManagementImplService.GetCurrentSensorDataPath, 'get');
    if (params) {
      rb.query('deviceId', params.deviceId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SensorDataDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCurrentSensorData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentSensorData(params: {
    deviceId: string;
    context?: HttpContext
  }
): Observable<SensorDataDto> {

    return this.getCurrentSensorData$Response(params).pipe(
      map((r: StrictHttpResponse<SensorDataDto>) => r.body as SensorDataDto)
    );
  }

  /**
   * Path part for operation getCalibratedData
   */
  static readonly GetCalibratedDataPath = '/sensor/calibrated-data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCalibratedData()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCalibratedData$Response(params: {
    deviceId: string;
    deviceOperationId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<SensorDataDto>> {

    const rb = new RequestBuilder(this.rootUrl, SensorManagementImplService.GetCalibratedDataPath, 'get');
    if (params) {
      rb.query('deviceId', params.deviceId, {});
      rb.query('deviceOperationId', params.deviceOperationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SensorDataDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCalibratedData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCalibratedData(params: {
    deviceId: string;
    deviceOperationId: string;
    context?: HttpContext
  }
): Observable<SensorDataDto> {

    return this.getCalibratedData$Response(params).pipe(
      map((r: StrictHttpResponse<SensorDataDto>) => r.body as SensorDataDto)
    );
  }

}
