<h2>
  <div class="tittle">{{this.config.data.dataType}} {{'hydroponics.calibration.calibration' | translate}}</div>
</h2>

<div class="mb-5 d-flex">
  <label class="mr-1 calibration-date">{{ 'hydroponics.calibration.calibrationDate' | translate }}:</label>
    <p-calendar [(ngModel)]="chosedDate"
                [showTime]="true"
                [placeholder]="'Choose calibration date'"
                appendTo="body" class="w-20rem"></p-calendar>
  <p-selectButton
    [options]="calibrationTypes"
    [(ngModel)]="calibrationType"
    optionLabel="label"
    optionValue="value">
  </p-selectButton>
</div>

<div class="calibration-row">
  <div class="mb-3">
    <label class="mr-1">{{config.data.dataType}}</label>
    <input class="p-inputtext" pInputText
           [(ngModel)]="this.saveNaSensorCalibrationDataRequest.calibrationPoints[0].valueX">
  </div>

  <div class="mb-3 ml-5">
    <label class="mr-1">{{ 'hydroponics.calibration.measuredValue' | translate }}</label>
    <input [readOnly]="this.calibrationType != TypeOfMeasure.CALIBRATION"
           [disabled]="this.calibrationType != TypeOfMeasure.CALIBRATION" class="p-inputtext mr-1" pInputText
           [(ngModel)]="this.saveNaSensorCalibrationDataRequest.calibrationPoints[0].valueY">
    <p-button [disabled]="this.calibrationType != TypeOfMeasure.MANUAL"
              (onClick)="measureToCalibration(0)"
              label="Run measure">
    </p-button>
  </div>
</div>
<div class="calibration-row">
  <div class="mb-3">
    <label class="mr-1">{{'hydroponics.calibration.averageOfMeasured' | translate}}: {{averageValues[0]}}</label>
  </div>
</div>
<div class="calibration-row">
  <div class="mb-3">
    <label class="mr-1">{{'hydroponics.calibration.calculatedByCalibration' | translate}}: {{calculatedValues[0]}}</label>
  </div>
</div>
<div class="calibration-row">
  <div class="mb-3">
    <label class="mr-1">{{config.data.dataType}}</label>
    <input class="p-inputtext" pInputText [(ngModel)]="this.saveNaSensorCalibrationDataRequest.calibrationPoints[1].valueX">
  </div>
  <div class="mb-3 ml-5">
    <label class="mr-1">{{'hydroponics.calibration.measuredValue' | translate}}</label>
    <input [disabled]="this.calibrationType != TypeOfMeasure.CALIBRATION" class="p-inputtext mr-1" pInputText
           [(ngModel)]="this.saveNaSensorCalibrationDataRequest.calibrationPoints[1].valueY">
    <p-button [disabled]="this.calibrationType != TypeOfMeasure.MANUAL" (onClick)="measureToCalibration(1)"
              label="Run measure"></p-button>
  </div>
</div>
<div class="calibration-row">
  <div class="mb-3">
    <label class="mr-1">{{'hydroponics.calibration.averageOfMeasured' | translate}}: {{averageValues[1]}}</label>
  </div>
</div>
<div class="calibration-row">
  <div class="mb-3">
    <label class="mr-1">{{'hydroponics.calibration.calculatedByCalibration' | translate}}: {{calculatedValues[1]}}</label>
  </div>
</div>

<div class="buttons">
  <p-button class="mr-1" (onClick)="closeCalibration()" label="{{ 'hydroponics.button.cancel' | translate }}"></p-button>
  <p-button [disabled]="!isFormValid()" (onClick)="saveCalibration()"
            label="{{ 'hydroponics.button.save' | translate }}"></p-button>
</div>


<div class="mat-elevation-z8">
  <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>

  <p-table [value]="dataSource"
           [paginator]="true"
           [rows]="pageSize"
           [lazy]="true"
           [first]="first"
           [totalRecords]="totalRows"
           [rowsPerPageOptions]="pageSizeOptions"
           (onPage)="pageChanged($event)"
           paginatorDropdownAppendTo="body">
    <ng-template pTemplate="header">
      <tr>
        <th>{{ 'hydroponics.calibration.table.recordDate' | translate}}</th>
        <th>{{ 'hydroponics.calibration.table.calibrationDate' | translate}}</th>
        <th>{{ 'hydroponics.calibration.table.referenceValue_1' | translate}}</th>
        <th>{{ 'hydroponics.calibration.table.measuredValue_1' | translate}}</th>
        <th>{{ 'hydroponics.calibration.table.referenceValue_2' | translate}}</th>
        <th>{{ 'hydroponics.calibration.table.measuredValue_2' | translate}}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-element>
      <tr>
        <td>{{element.timestamp | date: dateService.longdateFormatPattern.toString()}}</td>
        <td>{{element.calibrationDate | date: dateService.longdateFormatPattern.toString()}}</td>
        <td>{{element.calibrationPoints[0].valueX}}</td>
        <td>{{element.calibrationPoints[0].valueY}}</td>
        <td>{{element.calibrationPoints[1].valueX}}</td>
        <td>{{element.calibrationPoints[1].valueY}}</td>
      </tr>
    </ng-template>
  </p-table>
</div>
