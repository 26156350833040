/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { LocationDto } from '../models/location-dto';
import { LocationStatusRequest } from '../models/location-status-request';
import { OrganizationTreeDto } from '../models/organization-tree-dto';
import { PlantProfileSaveRequest } from '../models/plant-profile-save-request';

@Injectable({
  providedIn: 'root',
})
export class LocationManagementImplService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation setLocationStatus
   */
  static readonly SetLocationStatusPath = '/location/set-status';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setLocationStatus()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setLocationStatus$Response(params: {
    context?: HttpContext
    body: LocationStatusRequest
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, LocationManagementImplService.SetLocationStatusPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setLocationStatus$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setLocationStatus(params: {
    context?: HttpContext
    body: LocationStatusRequest
  }
): Observable<void> {

    return this.setLocationStatus$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation savePlantProfileToLocation
   */
  static readonly SavePlantProfileToLocationPath = '/location/save-location-plantprofile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `savePlantProfileToLocation()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  savePlantProfileToLocation$Response(params: {
    context?: HttpContext
    body: PlantProfileSaveRequest
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, LocationManagementImplService.SavePlantProfileToLocationPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `savePlantProfileToLocation$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  savePlantProfileToLocation(params: {
    context?: HttpContext
    body: PlantProfileSaveRequest
  }
): Observable<void> {

    return this.savePlantProfileToLocation$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation savePlantProfileToDevice
   */
  static readonly SavePlantProfileToDevicePath = '/location/save-device-plantprofile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `savePlantProfileToDevice()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  savePlantProfileToDevice$Response(params: {
    context?: HttpContext
    body: PlantProfileSaveRequest
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, LocationManagementImplService.SavePlantProfileToDevicePath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `savePlantProfileToDevice$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  savePlantProfileToDevice(params: {
    context?: HttpContext
    body: PlantProfileSaveRequest
  }
): Observable<void> {

    return this.savePlantProfileToDevice$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getOrganizationTreeWithStatuses
   */
  static readonly GetOrganizationTreeWithStatusesPath = '/location/organization';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrganizationTreeWithStatuses()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganizationTreeWithStatuses$Response(params?: {
    'X-Authenticated-User'?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OrganizationTreeDto>> {

    const rb = new RequestBuilder(this.rootUrl, LocationManagementImplService.GetOrganizationTreeWithStatusesPath, 'get');
    if (params) {
      rb.header('X-Authenticated-User', params['X-Authenticated-User'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OrganizationTreeDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOrganizationTreeWithStatuses$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganizationTreeWithStatuses(params?: {
    'X-Authenticated-User'?: string;
    context?: HttpContext
  }
): Observable<OrganizationTreeDto> {

    return this.getOrganizationTreeWithStatuses$Response(params).pipe(
      map((r: StrictHttpResponse<OrganizationTreeDto>) => r.body as OrganizationTreeDto)
    );
  }

  /**
   * Path part for operation countOpenAlertByDevice
   */
  static readonly CountOpenAlertByDevicePath = '/location/open-alert-count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `countOpenAlertByDevice()` instead.
   *
   * This method doesn't expect any request body.
   */
  countOpenAlertByDevice$Response(params: {
    deviceId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<number>> {

    const rb = new RequestBuilder(this.rootUrl, LocationManagementImplService.CountOpenAlertByDevicePath, 'get');
    if (params) {
      rb.query('deviceId', params.deviceId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `countOpenAlertByDevice$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  countOpenAlertByDevice(params: {
    deviceId: string;
    context?: HttpContext
  }
): Observable<number> {

    return this.countOpenAlertByDevice$Response(params).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

  /**
   * Path part for operation getHierarchy
   */
  static readonly GetHierarchyPath = '/location/hierarchy';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHierarchy()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHierarchy$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<LocationDto>>> {

    const rb = new RequestBuilder(this.rootUrl, LocationManagementImplService.GetHierarchyPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<LocationDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getHierarchy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHierarchy(params?: {
    context?: HttpContext
  }
): Observable<Array<LocationDto>> {

    return this.getHierarchy$Response(params).pipe(
      map((r: StrictHttpResponse<Array<LocationDto>>) => r.body as Array<LocationDto>)
    );
  }

}
