import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {DashboardService} from "../../../dashboard/dashboard.service";
import {TranslateService} from "@ngx-translate/core";
import {Subject} from "rxjs";


@Component({
  selector: 'app-base',
  templateUrl: './sensorboard.component.html',
  styleUrls: ['./sensorboard.component.sass']
})
export class SensorboardComponent implements OnInit, OnDestroy {
  readonly BASE_SENSOR_DATA_TYPE: string = 'BASE_SENSOR';
  readonly IMPEDANCE_SENSOR_DATA_TYPE: string = 'IMPEDANCE_SENSOR';

  dataType = this.BASE_SENSOR_DATA_TYPE;
  @Input() chartFilterDateChangeSubject: Subject<Date[]> = new Subject<Date[]>();

  chartFilterDateChangeSubjectSensor: Subject<Date[]> = new Subject<Date[]>();

  constructor(public dashboardService: DashboardService,
              public translate: TranslateService) {
  }

  ngOnInit(): void {
    if (this.chartFilterDateChangeSubject){
      this.chartFilterDateChangeSubject.subscribe(rangeDates => {
        this.chartFilterDateChangeSubjectSensor.next(rangeDates);
      });
    }
  }

  dataTypeChangedEvent(dataType: string) {
    this.dataType = dataType;
  }

  ngOnDestroy(): void {
    this.chartFilterDateChangeSubject.unsubscribe();
  }
}
