<p-tree *ngIf="isMultiple"
        [value]="tree"
        layout="horizontal"
        selectionMode="multiple"
        [(selection)]="selectedNodes"
        [metaKeySelection]="false"
        (selectionChange)="emitLocationChange()">
  <ng-template let-node pTemplate="LEAF">
    <div class="tree-node m-2">
      <div>
        <label>{{node.label}}</label>
      </div>
    </div>
  </ng-template>
  <ng-template let-node pTemplate="ROOT">
    <div class="tree-node m-2">
      <div>
        <label>{{node.label}}</label>
      </div>
    </div>
  </ng-template>
  <ng-template let-node pTemplate="MIDDLE">
    <div class="tree-node m-2">
      <div>
        <label>{{node.label}}</label>
      </div>
    </div>
  </ng-template>
</p-tree>

<p-tree *ngIf="!isMultiple"
        [value]="tree"
        layout="horizontal"
        selectionMode="single"
        [(selection)]="selectedNode"
        [metaKeySelection]="false"
        (selectionChange)="emitLocationChange()">
  <ng-template let-node pTemplate="LEAF">
    <div class="tree-node m-2">
      <div>
        <label>{{node.label}}</label>
      </div>
    </div>
  </ng-template>
  <ng-template let-node pTemplate="ROOT">
    <div class="tree-node m-2">
      <div>
        <label>{{node.label}}</label>
      </div>
    </div>
  </ng-template>
  <ng-template let-node pTemplate="MIDDLE">
    <div class="tree-node m-2">
      <div>
        <label>{{node.label}}</label>
      </div>
    </div>
  </ng-template>
</p-tree>
