<mat-form-field class="selector" appearance="fill">
  <mat-label>{{ 'hydroponics.devices.dataType' | translate }}</mat-label>
  <mat-select [(value)]="dataType" (change)="dataTypeChangedEvent($event.target.value)">
    <mat-option [value]="'BASE_SENSOR'">
      {{ 'hydroponics.devices.baseDataType' | translate }}
    </mat-option>
    <mat-option [value]="'IMPEDANCE_SENSOR'">
      {{ 'hydroponics.devices.impedanceDataType' | translate }}
    </mat-option>
  </mat-select>
</mat-form-field>

<container-element [ngSwitch]="dataType">
  <base-sensor *ngSwitchCase="this.BASE_SENSOR_DATA_TYPE" [device]=dashboardService.device
    [chartFilterDateChangeSubjectSensor]="chartFilterDateChangeSubjectSensor"></base-sensor>
  <app-impedance-sensor *ngSwitchCase="this.IMPEDANCE_SENSOR_DATA_TYPE" [device]=dashboardService.device></app-impedance-sensor>
</container-element>

