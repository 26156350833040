import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ChartFilterService {
  private initializationSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor() { }

  markInitialized(): void {
    this.initializationSubject.next(true);
  }

  isInitialized(): BehaviorSubject<boolean> {
    return this.initializationSubject;
  }
}
