import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {NodeElement} from "../locations/locations.component";

@Injectable({
  providedIn: 'root'
})
export class LocationTreeService {
  private initializationSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private root: NodeElement;
  private selectedNode: NodeElement;
  constructor() { }

  markInitialized(nodeElement: NodeElement): void {
    if (this.initializationSubject.value != true){
      this.root = nodeElement;
      this.initializationSubject.next(true);
    }
  }

  getRoot(){
    return this.root;
  }

  getSelectedNode(){
    return this.selectedNode;
  }

  isInitialized(): BehaviorSubject<boolean> {
    return this.initializationSubject;
  }

  setSelectedNode(selectedNode: NodeElement) {
    this.selectedNode = selectedNode;
  }
}
