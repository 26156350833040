import {Component, EventEmitter, Input, OnInit, Output,} from '@angular/core';
import {Chart, ChartData} from 'chart.js';
import {TranslateService} from '@ngx-translate/core';
import {MatDialog} from "@angular/material/dialog";
import "chartjs-adapter-moment";
import {ImpedanceSensorCalibrationComponent} from "../../impedance/impedance-sensor-calibration/impedance-sensor-calibration.component";
import {defaultChartOptions} from "../../../../util/chart-settings";

export enum SensorDataType {
  IMPEDANCE = 'Impedance',
  IMPEDANCE_PHASE = 'ImpedancePhase'
}

export interface SensorDataExportEvent {
  dataType: SensorDataType;
}

export interface SensorDataShowInTableEvent {
  dataType: String;
}

export interface SensorDataShowManualMeasurementEvent {
  dataType: String;
}

@Component({
  selector: 'app-impedance-sensor-data',
  templateUrl: './impedance-sensor-data.component.html',
  styleUrls: ['./impedance-sensor-data.component.sass'],
})
export class ImpedanceSensorDataComponent implements OnInit {


  @Input()
  chartData: Array<any>;


  @Input() dataType: String;
  @Input() data: ChartData;
  @Input() canBeCalibrated?: boolean;
  @Input() deviceId: string;

  @Input() frequenciesList: number[];

  @Output() onExport: EventEmitter<SensorDataExportEvent> = new EventEmitter();
  @Output() onShowInTable: EventEmitter<SensorDataShowInTableEvent> = new EventEmitter();
  @Output() onShowManualMeasurements: EventEmitter<SensorDataShowManualMeasurementEvent> = new EventEmitter();
  @Output() openExport: EventEmitter<SensorDataShowInTableEvent> = new EventEmitter();

  public title: string;

  constructor(private translate: TranslateService, public dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.translate
      .get('hydroponics.common.measurement.' + this.dataType)
      .subscribe((message: any) => (this.title = message));
  }


  showInTable(): void {
    this.onShowInTable.emit({
      dataType: this.dataType,
    });
  }

  openExportComponent() {
    this.openExport.emit({dataType: this.dataType});
  }

  isNaN(n: number) {
    return Number.isNaN(n);
  }

  openDialog(): void {
    this.dialog.open(ImpedanceSensorCalibrationComponent, {
      data: {
        dataType: this.dataType,
        deviceId: this.deviceId,
        frequenciesList: this.frequenciesList
      }
    });
  }


  public chartOptions: any = defaultChartOptions;

  public chart: Chart;
  start: number = 3;
  end: number = 6;

  showManualMeasurements() {
    this.onShowManualMeasurements.emit({
      dataType: this.dataType,
    });
  }
}
