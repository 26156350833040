import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  AlertNotificationDto
} from "../../../../generated/hydroponics-device-management-api/models/alert-notification-dto";
import {
  AlertManagementImplService
} from "../../../../generated/hydroponics-device-management-api/services/alert-management-impl.service";
import {LoadAlertsAndWorksheetsNumberService} from "../../../service/load-alerts-and-worksheets-number.service";

interface PageEvent {
  first: number;
  rows: number;
  page: number;
  pageCount: number;
}

@Component({
  selector: 'app-alert-table',
  templateUrl: './alert-table.component.html',
  styleUrls: ['./alert-table.component.sass']
})
export class AlertTableComponent implements OnInit {

  @Input() deviceId: string;
  @Input() dataType: string;
  @Input() visible!: boolean;
  @Output() closeTable = new EventEmitter<void>();
  @Output() onHideAlert = new EventEmitter<number>();

  alerts!: AlertNotificationDto[];
  pageSizeOptions: number[] = [5, 10, 15, 30, 50, 100];
  isLoading: boolean;
  totalRecords = 0;
  rows = 5;
  first = 0;
  currentPage = 0;

  constructor(private readonly alertManagementImplService: AlertManagementImplService,
              private readonly loadAlertsAndWorksheetsNumberService: LoadAlertsAndWorksheetsNumberService
  ) {
  }

  ngOnInit(): void {
    this.getAlerts();
  }

  private getAlerts() {
    this.isLoading = true;
    if (this.deviceId != undefined && this.deviceId != null){
      this.alertManagementImplService.getUserAlertsByDevice({
        "X-Authenticated-User": null,
        pageable: {page: this.currentPage, size: this.rows},
        deviceId: this.deviceId,
        dataType: this.dataType
      }).subscribe(value => {
        this.alerts = value.content;
        this.totalRecords = value.totalElements;
        this.isLoading = false;
      });
    } else {
      this.alertManagementImplService.getUserAlerts({
        "X-Authenticated-User": null,
        pageable: {page: this.currentPage, size: this.rows}
      }).subscribe(value => {
        this.alerts = value.content;
        this.totalRecords = value.totalElements;
        this.isLoading = false;
      });
    }
  }

  actionOnClose() {
    this.closeTable.emit();
  }

  // the logic of the function is not final
  // hideAlert(alertNotificationId: number, dataType: string) {
  //   this.alertManagementImplService.hideAlert({alertNotificationId}).subscribe(value => {
  //     this.onHideAlert.emit(alertNotificationId);
  //     this.getAlerts();
  //     this.loadAlertsNumberService.(alertNotificationId, dataType);
  //   });
  // }

  pageChanged(event: PageEvent) {
    this.rows = event.rows;
    this.first = event.first;
    this.currentPage = event.first / event.rows;

    this.getAlerts();
  }

  closeAlert(alertNotificationId: number) {
    this.alertManagementImplService.closeAlert({body: alertNotificationId}).subscribe(value => {
      this.onHideAlert.emit(alertNotificationId);
      this.getAlerts();
      this.loadAlertsAndWorksheetsNumberService.emitEvent();
    });
  }
}
