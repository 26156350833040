/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DeviceOperationDto } from '../models/device-operation-dto';
import { GetDeviceOperationsResponse } from '../models/get-device-operations-response';
import { OperateOnDeviceRequest } from '../models/operate-on-device-request';
import { OperateOnDeviceResponse } from '../models/operate-on-device-response';

@Injectable({
  providedIn: 'root',
})
export class DeviceOperationControllerImplService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation operateOnDevice
   */
  static readonly OperateOnDevicePath = '/device-operation/operate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `operateOnDevice()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  operateOnDevice$Response(params: {
    deviceId: string;
    context?: HttpContext
    body: OperateOnDeviceRequest
  }
): Observable<StrictHttpResponse<OperateOnDeviceResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DeviceOperationControllerImplService.OperateOnDevicePath, 'post');
    if (params) {
      rb.query('deviceId', params.deviceId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OperateOnDeviceResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `operateOnDevice$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  operateOnDevice(params: {
    deviceId: string;
    context?: HttpContext
    body: OperateOnDeviceRequest
  }
): Observable<OperateOnDeviceResponse> {

    return this.operateOnDevice$Response(params).pipe(
      map((r: StrictHttpResponse<OperateOnDeviceResponse>) => r.body as OperateOnDeviceResponse)
    );
  }

  /**
   * Path part for operation getDeviceTypeOperations
   */
  static readonly GetDeviceTypeOperationsPath = '/device-operation/device-type-operations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDeviceTypeOperations()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDeviceTypeOperations$Response(params: {
    deviceId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<GetDeviceOperationsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DeviceOperationControllerImplService.GetDeviceTypeOperationsPath, 'get');
    if (params) {
      rb.query('deviceId', params.deviceId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetDeviceOperationsResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDeviceTypeOperations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDeviceTypeOperations(params: {
    deviceId: string;
    context?: HttpContext
  }
): Observable<GetDeviceOperationsResponse> {

    return this.getDeviceTypeOperations$Response(params).pipe(
      map((r: StrictHttpResponse<GetDeviceOperationsResponse>) => r.body as GetDeviceOperationsResponse)
    );
  }

  /**
   * Path part for operation getDeviceOperation
   */
  static readonly GetDeviceOperationPath = '/device-operation/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDeviceOperation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDeviceOperation$Response(params: {
    deviceOperationId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<DeviceOperationDto>> {

    const rb = new RequestBuilder(this.rootUrl, DeviceOperationControllerImplService.GetDeviceOperationPath, 'get');
    if (params) {
      rb.query('deviceOperationId', params.deviceOperationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DeviceOperationDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDeviceOperation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDeviceOperation(params: {
    deviceOperationId: number;
    context?: HttpContext
  }
): Observable<DeviceOperationDto> {

    return this.getDeviceOperation$Response(params).pipe(
      map((r: StrictHttpResponse<DeviceOperationDto>) => r.body as DeviceOperationDto)
    );
  }

}
