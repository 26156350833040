<div class="header-container">
  <div class="header-content flex align-items-center justify-content-end">
    <!--<div class="header-content-item flex align-items-center justify-content-end header-item-large">
      <div class="p-input-icon-left w-100 header-content-search">
          <i class="p-button-icon pi pi-search"></i>
          <input type="text" pInputText placeholder="Search" />
      </div>
    </div>-->
    <div class="header-content-item flex align-items-center justify-content-evenly header-item-small header-content-profile">
        <div class="header-content-user-name">{{(this.authService.userProfile | async)?.lastName}} {{(this.authService.userProfile | async)?.firstName}}</div>
        <!--<a pStyleClass="@next" enterFromClass="ng-hidden" enterActiveClass="px-scalein" leaveToClass="ng-hidden"
           leaveActiveClass="px-fadeout"  pRipple class="cursor-pointer" (click)="menu.toggle($event)">
          <img class="header-content-avatar" src="assets/demo/img/avatar-1.jpg" alt="Profile" />
        </a>
        <p-menu #menu popup="true" [model]="items"></p-menu>-->
      <a (click)="logout()" style="cursor: pointer;">
        <i class="pi pi-sign-out sign-out-icon"></i>
      </a>
    </div>
    <!--<div class="header-content-item flex align-items-center justify-content-end header-item-small">
      <div class="header-content-item__back">
        <button pbutton type="button" class="p-button">
          <span class="p-button-icon pi pi-arrow-left" aria-hidden="true"></span>
          <span class="p-button-text">Vissza</span>
        </button>
      </div>
    </div>-->
  </div>
</div>
