import {Injectable} from '@angular/core';
import {DeviceDto} from "../../generated/hydroponics-device-management-api/models/device-dto";
import {DeviceTypeDto} from "../../generated/hydroponics-device-management-api/models/device-type-dto";
import {LocationReferenceDto} from "../../generated/hydroponics-device-management-api/models/location-reference-dto";
import {DeviceGroupDto} from "../../generated/hydroponics-device-management-api/models/device-group-dto";


export class ExtDeviceDTO implements DeviceDto {
  deviceId?: string;
  deviceMac?: string;
  deviceType?: DeviceTypeDto;
  locationReferenceDTO?: LocationReferenceDto;
  name?: string;
  searchName?: string;
  deviceGroup?: ExtDeviceGroupDTO;
}

export class ExtDeviceGroupDTO implements DeviceGroupDto {
  locationReferenceDTO?: LocationReferenceDto;
  name?: string;
  devices?: ExtDeviceDTO[];
  searchName?: string;
}

@Injectable({
  providedIn: 'root'
})

export class DashboardService {

  device: ExtDeviceDTO;

  constructor() {
  }
}
