import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NodeElement, NodeRoleType, NodeType} from "../locations/locations.component";
import {OrganizationTreeDto} from "../../../generated/hydroponics-device-management-api/models/organization-tree-dto";
import {LocationDto} from "../../../generated/hydroponics-device-management-api/models/location-dto";
import {DeviceAdditionDto} from "../../../generated/hydroponics-device-management-api/models/device-addition-dto";
import {DeviceToLocationDto} from "../../../generated/hydroponics-device-management-api/models/device-to-location-dto";
import {
  LocationManagementImplService
} from "../../../generated/hydroponics-device-management-api/services/location-management-impl.service";
import {LocationTreeService} from "./location-tree.service";

const PAUSED = "PAUSED";
const ACTIVE = "ACTIVE";

export interface LocationTreeLocationChangeEvent {
  selectedNodes: NodeElement[];
  selectedNode: NodeElement;
}

export enum DeviceTypeEnum {
  IMPEDANCE_SENSOR ="IMPEDANCE_SENSOR",
  NUTRITION_DISPENSER = "NUTRITION_DISPENSER",
  SENSORBOARD = "SENSORBOARD",
}

@Component({
  selector: 'app-location-tree',
  templateUrl: './location-tree.component.html',
  styleUrls: ['./location-tree.component.sass']
})
export class LocationTreeComponent implements OnInit {
  tree: NodeElement[];
  selectedNodes: NodeElement[] = [];
  selectedNode: NodeElement = undefined;

  @Input() extendedTree: boolean;
  @Input() deviceTypes: DeviceTypeEnum[] = [];
  @Input() isOnlyDeviceSelectable: boolean = false;
  @Input() isMultiple: boolean = true;

  @Output() onLocationChange: EventEmitter<LocationTreeLocationChangeEvent> = new EventEmitter();
  @Output() onDialogContentLoad: EventEmitter<boolean> = new EventEmitter();

  constructor(private readonly locationService: LocationManagementImplService,
              private locationTreeService: LocationTreeService) { }

  ngOnInit(): void {
    this.locationService.getOrganizationTreeWithStatuses().subscribe(organizationTree => {
      this.buildTree(organizationTree);
      this.onDialogContentLoad.emit();
      // ha kész a fa, jelezzük a servicben és kell az organization root.
      this.locationTreeService.markInitialized(this.tree[0]);
    });
  }

  emitLocationChange() {
    this.onLocationChange.emit({
      selectedNodes: this.selectedNodes,
      selectedNode: this.selectedNode
    });
  }

  private buildTree(organizationTree: OrganizationTreeDto) {
    let establishments: NodeElement[] = [];
    let hasRecentAlertEstablishments: boolean[] = [];
    let orgSumValues: { alerts: number, worksheets: number, hasRecentAlerts: boolean } = { alerts: 0 , worksheets: 0, hasRecentAlerts: false};

    for (let establishment of organizationTree.organization.establishments) {
      let locations: NodeElement[] = [];

      for (let location of establishment.locations) {
        let child = this.buildLocationDevicesRecursively(location, organizationTree.deviceAdditions, true, establishment.city, undefined);
        locations.push(child);
      }

      let hasRecentAlertLocations: boolean[] = [];
      let establishmentSumValues: { alerts: number, worksheets: number, hasRecentAlerts: boolean } = { alerts: 0 , worksheets: 0, hasRecentAlerts: false };
      locations.forEach(location =>{
        establishmentSumValues.alerts += location.alerts;
        establishmentSumValues.worksheets += location.worksheets;
        hasRecentAlertLocations.push(location.hasRecentAlerts)
        establishmentSumValues.hasRecentAlerts = hasRecentAlertLocations.some(value => value === true);
      })

      establishments.push({
        id: establishment.city,
        nodeType: NodeType.ESTABLISHMENT,
        label: establishment.city,
        paused: false,
        expanded: locations && locations.length > 0,
        children: locations,
        worksheets: establishmentSumValues.worksheets,
        alerts: establishmentSumValues.alerts,
        chartAlerts: null,
        type: NodeRoleType.MIDDLE,
        hasRecentAlerts: establishmentSumValues.hasRecentAlerts,
        selectable: !this.isOnlyDeviceSelectable
      })

      orgSumValues.worksheets += establishmentSumValues.worksheets;
      orgSumValues.alerts += establishmentSumValues.alerts;
      hasRecentAlertEstablishments.push(establishmentSumValues.hasRecentAlerts)
      orgSumValues.hasRecentAlerts = hasRecentAlertEstablishments.some(value => value === true);
    }

    this.tree = [{
      id: organizationTree.organization.id,
      nodeType: NodeType.ORGANIZATION,
      label: organizationTree.organization.name,
      paused: false,
      expanded: true,
      children: establishments,
      worksheets: orgSumValues.worksheets,
      alerts: orgSumValues.alerts,
      hasRecentAlerts: orgSumValues.hasRecentAlerts,
      chartAlerts: null,
      type: NodeRoleType.ROOT,
      selectable: !this.isOnlyDeviceSelectable
    }];
  }

  private buildLocationDevicesRecursively(location: LocationDto, deviceAdditions: {[p: string]: DeviceAdditionDto}, profileEditable, settlementLabel: string, locationsLabel: string){
    let locationNode: NodeElement = {
      id: location.id,
      nodeType: NodeType.LOCATION,
      label: location.name,
      paused: location.status == PAUSED,
      worksheets: 0,
      alerts: 0,
      hasRecentAlerts: false,
      chartAlerts: null,
      type: NodeRoleType.LEAF,
      plantProfile: location.plantProfile ? location.plantProfile : undefined,
      defaultProfile: true,
      profileEditable: profileEditable,
      selectable: !this.isOnlyDeviceSelectable
    };

    if (location.children || location.deviceToLocations){
      let childrenNodes: NodeElement[] = [];
      let hasRecentErrorChildren: boolean[] = [];
      let nodeSumValues: { alerts: number, worksheets: number, hasRecentAlerts: boolean } = { alerts: 0 , worksheets: 0, hasRecentAlerts: false};

      if (location.children) {
        for (let child of location.children) {
          let labelToLocation = locationsLabel ? locationsLabel + " - " + location.name : location.name;
          let childrenNode = this.buildLocationDevicesRecursively(child, deviceAdditions, location.plantProfile == null, settlementLabel, labelToLocation);
          childrenNodes.push(childrenNode)
          nodeSumValues.alerts += childrenNode.alerts;
          nodeSumValues.worksheets += childrenNode.worksheets;
          hasRecentErrorChildren.push(childrenNode.hasRecentAlerts)
          nodeSumValues.hasRecentAlerts = hasRecentErrorChildren.some(value => value === true);
        }
      }

      if (location.deviceToLocations && location.deviceToLocations.length > 0) {
        let locationsLabelToDevice = locationsLabel + " - " + location.name
        this.buildDeviceLeaves(location.deviceToLocations, childrenNodes, nodeSumValues, deviceAdditions, location.plantProfile == null, locationsLabelToDevice,
          settlementLabel);
      }

      locationNode.worksheets = nodeSumValues.worksheets;
      locationNode.alerts = nodeSumValues.alerts;
      locationNode.hasRecentAlerts = nodeSumValues.hasRecentAlerts;

      if (childrenNodes.length > 0){
        locationNode.children = childrenNodes;
        locationNode.expanded = true;
        locationNode.type = NodeRoleType.MIDDLE;
      }
    }

    return locationNode;
  }

  private buildDeviceLeaves(deviceToLocations: Array<DeviceToLocationDto>,
                            devices: NodeElement[],
                            deviceSumValues: {alerts: number, worksheets: number, hasRecentAlerts: boolean },
                            deviceAdditions: {[p: string]: DeviceAdditionDto},
                            profileEditable,
                            locationsLabel: string,
                            settlementLabel: string) {
    for (let deviceToLoc of deviceToLocations) {
      if (deviceToLoc.device) {
        let device = deviceToLoc.device;
        if (device.deviceType){
          let type = device.deviceType.type
          if (this.deviceTypes.includes(DeviceTypeEnum[type])){
            let devAddition = deviceAdditions ? deviceAdditions[device.deviceId]: null;

            let deviceNode: NodeElement = {
              id: device.deviceId,
              nodeType: NodeType.DEVICE,
              label: deviceToLoc.device.deviceMac ? deviceToLoc.device.name + " (" + deviceToLoc.device.deviceMac + ")" : deviceToLoc.device.name,
              paused: device.status == PAUSED,
              worksheets: devAddition ? devAddition.worksheetCount: 0,
              alerts: devAddition ? devAddition.deviceAlertsCountDTO.sum: 0,
              hasRecentAlerts: devAddition ? Object.values(devAddition.hasRecentDeviceAlertsDTO).some(value => value === true): false,
              chartAlerts: devAddition ? devAddition.deviceAlertsCountDTO : null,
              plantProfile: device.plantProfile ? device.plantProfile : undefined,
              type: NodeRoleType.LEAF,
              defaultProfile: true,
              profileEditable: profileEditable
            }

            deviceNode.deviceName = deviceNode.label;
            deviceNode.settlement = settlementLabel;
            deviceNode.locations = locationsLabel;

            deviceSumValues.alerts += deviceNode.alerts;
            deviceSumValues.worksheets += deviceNode.worksheets;
            deviceSumValues.hasRecentAlerts = deviceNode.hasRecentAlerts;
            devices.push(deviceNode)
          }
        }
      }
    }
  }
}
