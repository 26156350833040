<p-toast></p-toast>
<div id="wrapper" style="width: 100%;" class="layout-dark layout-container layout-drawer">
  <app-demo-app-header *ngIf="this.router.url === '/demo'; else elseBlock"></app-demo-app-header>
  <ng-template  #elseBlock><app-header></app-header></ng-template>
  <div id="page-wrapper" class="layout-content-wrapper">
    <app-menu></app-menu>
    <div id="page-content" class="layout-content" role="main">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
