import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';

import {AuthComponent} from "../../auth/auth.component";
import {
  DASHBOARD_ROUTE,
  PLANT_PROFILE_ROUTE,
  PROFILE_ROUTE,
  DOSAGE_ROUTE,
  LOCATIONS_ROUTE,
  CALIBRATION_ROUTE,
  SENSORS_ROUTE,
  WORKSHEET
} from "../../app-routing.module";
import {MenuItem} from "primeng/api";

@Component({
  selector: 'app-menu',
  templateUrl: './app-menu.component.html',
  styleUrls: ['./app-menu.component.sass']
})
export class AppMenuComponent implements OnInit {
  menuModel: MenuItem[];
  @ViewChild('mainNav') menu!: ElementRef;

  constructor(public readonly authService: AuthComponent) { }

  ngOnInit(): void {
    this.menuModel = [
      {
        label: '' +
          'Home',
        icon: 'pi pi-home',
        routerLink: [DASHBOARD_ROUTE],
      },
      {
        label: 'hydroponics.navigation.plant-profiles',
        icon: 'pi pi-id-card',
        routerLink: [PLANT_PROFILE_ROUTE],
      },
      {
        label: 'hydroponics.navigation.locations',
        icon: 'pi pi-building',
        routerLink: [LOCATIONS_ROUTE],
      },
      {
        label: 'hydroponics.navigation.sensors',
        icon: 'pi pi-chart-bar',
        routerLink: [SENSORS_ROUTE],
      },
      {
        label: 'hydroponics.navigation.dosage',
        icon: 'pi pi-palette',
        routerLink: [DOSAGE_ROUTE],
      },
      {
        label: 'hydroponics.navigation.calibration',
        icon: 'pi pi-sliders-h',
        routerLink: [CALIBRATION_ROUTE],
      },
      {
        label: 'hydroponics.navigation.events',
        icon: 'pi pi-briefcase',
        routerLink: [WORKSHEET],
      },
      {
        label: 'hydroponics.navigation.profile',
        icon: 'pi pi-user',
        routerLink: [PROFILE_ROUTE],
      },
      {
        label: 'hydroponics.navigation.logout',
        icon: 'pi pi-sign-out',
        command: () => this.authService.logout()
      }
    ];
  }

  onMouseEnter(): void{
    this.menu.nativeElement.classList.add('active');
  }

  onMouseLeave(): void{
    this.menu.nativeElement.classList.remove('active');
  }

}
