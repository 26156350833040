import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {finalize, tap} from "rxjs";
import {LoadingSpinnerService} from "./loading-spinner.service";
import {KeycloakService} from "keycloak-angular";

@Injectable({
  providedIn: 'root'
})
export class ApiCallInterceptor implements HttpInterceptor {

  constructor(private loadingSpinner: LoadingSpinnerService,
              private readonly keycloak: KeycloakService,) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    this.loadingSpinner.show();
    return next.handle(req)
      .pipe(
        finalize(() => {
          this.loadingSpinner.hide();
        })
      ).pipe(tap(() => {
        },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status !== 401) {
              return;
            }
            this.keycloak.login();
          }
        }
      ));
  }
}
