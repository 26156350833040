<div [ngClass]="{'dashboard-container': isWorksheetsBarVisible}">
  <div class="flex flex-wrap align-content-start align-items-start">
    <ng-container *ngFor="let deviceKey of deviceDataKeys()">
      <ng-container *ngFor="let chartKey of chartDataKeys(deviceKey)">
        <app-sensor-data
          *ngIf="!isFavOnly || isFav(deviceKey, chartKey)"
          [ngClass]="'flex-item'"
          [favourite]="isFav(deviceKey,chartKey)"
          [dataType]="chartKey"
          [frequencies]="frequencies"
          [option]="chartOptions"
          [calibrationTypes]="calibrationTypes"
          [operationTypes]="operationTypes"
          [data]="deviceDataValues(deviceKey, chartKey)"
          [deviceData]="getDeviceData(deviceKey)"
          [deviceId]="deviceKey">
        </app-sensor-data>
      </ng-container>
    </ng-container>
  </div>
  <ng-container *ngIf="isWorksheetsBarVisible">
    <div class="flex-column flex-wrap">
      <app-worksheet-create></app-worksheet-create>

      <ng-container *ngFor="let workElement of workElements">
        <app-worksheet
          [workElement]="workElement">
        </app-worksheet>
      </ng-container>
    </div>
  </ng-container>
</div>


