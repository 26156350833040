<div class="dashboard">

  <div [formGroup]="dateRange" class="card">
    <div class="actions">
      <div class="filters">
        <mat-form-field>
          <input
            matInput
            [ngxMatDatetimePicker]="pickerStart"
            [placeholder]="'hydroponics.common.startTimePlaceholder' | translate"
            [min]="firstSensorDataTimestamp"
            [max]="rangeEndMaxDate"
            formControlName="start"
            (dateChange)="loadImpedanceSensorData()"/>
          <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
          <ngx-mat-datetime-picker #pickerStart [hideTime]="false"></ngx-mat-datetime-picker>
        </mat-form-field>
        <mat-form-field>
          <input
            matInput
            [ngxMatDatetimePicker]="pickerEnd"
            [placeholder]="'hydroponics.common.endTimePlaceholder' | translate"
            formControlName="end"
            [max]="rangeEndMaxDate"
            [min]="dateRange.value.start"
            (dateChange)="loadImpedanceSensorData()"/>
          <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
          <mat-datepicker-toggle matSuffix (click)="clearEndDate()">
            <mat-icon matDatepickerToggleIcon>clear</mat-icon>
          </mat-datepicker-toggle>
          <ngx-mat-datetime-picker #pickerEnd [hideTime]="false"></ngx-mat-datetime-picker>
        </mat-form-field>
      </div>
    </div>
  </div>

  <mat-form-field appearance="fill">
    <mat-label>Frequencies</mat-label>
    <mat-select [formControl]="frequencies" [(value)]="pickedFrequencies" multiple
                (selectionChange)="frequenceSelectionChanged()">
      <mat-option *ngFor="let topping of this.frequenciesList"
                  [value]="topping">{{topping}}</mat-option>
    </mat-select>
  </mat-form-field>
  <button class="calibrationButton" mat-raised-button mat-dialog-close="true" color="primary"
          (click)="selectAllFrequencies()">{{'hydroponics.common.selectAll'| translate}}
  </button>
  <button class="calibrationButton" mat-raised-button mat-dialog-close="true" color="primary"
          (click)="deSelectAllFrequencies()">{{'hydroponics.common.clear'| translate}}
  </button>
  <div>
    <button mat-raised-button color="primary"
            (click)="openManualMeasure()">{{'hydroponics.manualMeasure.manualMeasure' | translate}}</button>
  </div>

  <div class="flex">
    <div class="flex-item card">
      <app-impedance-sensor-data
        [chartData]="impedanceChartData"
        [dataType]="'impedance'"
        [canBeCalibrated]="true"
        [frequenciesList]="this.frequenciesList"
        [deviceId]="this.device.deviceId"
        type="line"
        (onShowInTable)="showInTable($event)"
        (onShowManualMeasurements)="showManualMeasurements($event)"
        (openExport)="openExport($event)">
      </app-impedance-sensor-data>
    </div>

    <div class="flex-item card">
      <app-impedance-sensor-data
        [chartData]="impedancePhaseChartData"
        [dataType]="'impedancePhase'"
        [canBeCalibrated]="false"
        [deviceId]="this.device.deviceId"
        type="line"
        (onShowInTable)="showInTable($event)"
        (onShowManualMeasurements)="showManualMeasurements($event)"
        (openExport)="openExport($event)">
      </app-impedance-sensor-data>
    </div>

    <!-- Footer -->
  </div>
</div>
