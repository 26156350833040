<div class="card">
  <h2>{{ 'hydroponics.nutritionDispenser.deviceParameters' | translate}}</h2>
  <ng-container *hasRole="'refillDispenser'">
    <button *ngIf="settingValues == false" mat-raised-button class="change-button" color="primary"
            (click)="settingValues = true">{{ 'hydroponics.nutritionDispenser.changeParameters' | translate}}
    </button>
  </ng-container>
  <div *ngIf="settingValues == false">
    <table class="parameter-table">
      <tr>
        <td>{{'hydroponics.nutritionDispenser.substance' | translate}}:</td>
        <td>{{this.nutritionDispenserDeviceDto ? this.nutritionDispenserDeviceDto.substance : 'N/A' }}</td>
      </tr>
      <tr>
        <td>{{'hydroponics.nutritionDispenser.quantity' | translate}}:</td>
        <td>{{this.nutritionDispenserDeviceDto ? this.nutritionDispenserDeviceDto.quantity : 'N/A'}}</td>
      </tr>
      <tr>
        <td>{{'hydroponics.nutritionDispenser.concentrate' | translate}}:</td>
        <td>{{this.nutritionDispenserDeviceDto ? this.nutritionDispenserDeviceDto.concentratum : 'N/A'}}</td>
      </tr>
    </table>
  </div>
  <div *ngIf="settingValues == true" class="input-fields">
    <mat-form-field appearance="outline">
      <mat-label>{{'hydroponics.nutritionDispenser.substance' | translate}}</mat-label>
      <input matInput [(ngModel)]="newNutritionDispenserDeviceDto.substance">
    </mat-form-field>
    <br>
    <mat-form-field appearance="outline">
      <mat-label>{{'hydroponics.nutritionDispenser.quantity' | translate}}</mat-label>
      <input matInput type="number" [(ngModel)]="newNutritionDispenserDeviceDto.quantity">
      <span matSuffix>ml &nbsp;</span>
    </mat-form-field>
    <br>

    <mat-form-field appearance="outline">
      <mat-label>{{'hydroponics.nutritionDispenser.concentrate' | translate}}</mat-label>
      <input matInput type="number" [(ngModel)]="newNutritionDispenserDeviceDto.concentratum">
      <span matSuffix>ppm &nbsp;</span>
    </mat-form-field>
    <div class="calibrationButton">
      <button mat-raised-button color="warn"
              (click)="settingValues = false">{{ 'hydroponics.common.cancel' | translate}}</button>
      <button mat-raised-button color="primary"
              (click)="settingValues = false; saveDeviceParameters()">{{ 'hydroponics.common.save' | translate}}</button>
    </div>
  </div>


  <h2 id="device-parameter-change-history">Device parameter change history</h2>

  <div class="mat-elevation-z8 history-table">
    <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
    <table mat-table [dataSource]="dataSource">

      <ng-container matColumnDef="substance">
        <th mat-header-cell *matHeaderCellDef> {{ 'hydroponics.nutritionDispenser.substance' | translate}}</th>
        <td mat-cell *matCellDef="let element"> {{element.substance}} </td>
      </ng-container>

      <ng-container matColumnDef="quantity">
        <th mat-header-cell *matHeaderCellDef> {{ 'hydroponics.nutritionDispenser.quantity' | translate}}</th>
        <td mat-cell *matCellDef="let element"> {{element.quantity}} ml</td>
      </ng-container>

      <ng-container matColumnDef="concentratum">
        <th mat-header-cell *matHeaderCellDef> {{ 'hydroponics.nutritionDispenser.concentrate' | translate}}</th>
        <td mat-cell *matCellDef="let element"> {{element.concentratum}} ppm</td>
      </ng-container>

      <ng-container matColumnDef="modify_date">
        <th mat-header-cell *matHeaderCellDef> {{ 'hydroponics.nutritionDispenser.modifyDate' | translate}}</th>
        <td mat-cell
            *matCellDef="let element"> {{element.changeDate  | date: dateService.longdateFormatPattern.toString()}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [length]="totalRows" [pageIndex]="currentPage" [pageSize]="pageSize"
                   [pageSizeOptions]="pageSizeOptions" (page)="pageChanged($event)" aria-label="Select page">
    </mat-paginator>
  </div>
</div>

