<ng-container *ngIf="isWorksheetsBarVisible">
  <div class="flex-column flex-wrap">
    <app-worksheet-create></app-worksheet-create>

    <ng-container *ngFor="let workElement of workElement">
      <app-worksheet
        [workElement]="workElement"
        [isExtended]="true"
        (onWorksheetChange)="onWorksheetChange()">
      </app-worksheet>
    </ng-container>
  </div>
</ng-container>
