<p-tree [value]="tree" layout="horizontal" selectionMode="single" [(selection)]="selectedNode">
  <ng-template let-node pTemplate="ROOT">
    <div class="tree-node">
      <div>
        <h6>{{node.label}}</h6>
        <div>{{'hydroponics.location.worksheets' | translate}}: <span class="worksheet-count">{{node.worksheets}}</span></div>
        <div>{{'hydroponics.location.alerts' | translate}}: <span class="alert-count">{{node.alerts}}</span></div>
      </div>
    </div>
  </ng-template>
  <ng-template let-node pTemplate="MIDDLE">
    <div class="tree-node">
      <div class="flex flex-row justify-content-between">
        <h6 [ngStyle]="node.hasRecentAlerts ? { 'color': 'red' } : {}">{{node.label}}</h6>
        <div class="flex flex-row align-items-start">
          <div class="stop-button"
               [ngClass]="{'paused': node.paused}">
          </div>
          <div *ngIf="node.nodeType != 'ESTABLISHMENT'" #menu (click)="showContextMenu(node, contextMenu, $event);" class="context-menu-button">
            <span class="pi pi-bars"></span>
          </div>
        </div>
      </div>
      <div>
        <div>{{'hydroponics.location.worksheets' | translate}}: <span class="worksheet-count">{{node.worksheets}}</span></div>
        <div>{{'hydroponics.location.alerts' | translate}}: <span class="alert-count">{{node.alerts}}</span></div>
        <div *ngIf="(node.nodeType == 'LOCATION' || node.nodeType == 'DEVICE') && node.plantProfile">
          <p-divider></p-divider>
          <div class="plant-svg"></div>
          <span>{{node.plantProfile.name}}</span>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template let-node pTemplate="LEAF">
    <div class="tree-node flex justify-content-center align-items-center">
      <div *ngIf="node.nodeType == 'DEVICE'" class="sensor-icon"></div>
      <div>
        <div class="flex flex-row justify-content-between">
          <h6 class="margin-auto" [ngStyle]="node.hasRecentAlerts ? { 'color': 'red' } : {}">{{node.label}}</h6>
          <div class="flex flex-row align-items-start">
            <div class="stop-button"
                 [ngClass]="{'paused': node.paused}">
            </div>
            <div #menu (click)="showContextMenu(node, contextMenu, $event);" class="context-menu-button">
              <span class="pi pi-bars"></span>
            </div>
          </div>
        </div>
        <div>
          <div>{{'hydroponics.location.worksheets' | translate}}: <span class="worksheet-count">{{node.worksheets}}</span></div>
          <div>{{'hydroponics.location.alerts' | translate}}: <span class="alert-count">{{node.alerts}}</span></div>
          <div *ngIf="(node.nodeType == 'LOCATION' || node.nodeType == 'DEVICE') && node.plantProfile">
            <p-divider></p-divider>
            <div class="plant-svg"></div>
            <span>{{node.plantProfile.name}}</span>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</p-tree>
<p-contextMenu #contextMenu [model]="contextMenuItems"></p-contextMenu>

<p-dialog modal="true" [(visible)]="measureDialogVisible">
  <ng-template pTemplate="headless" let-message>
    <div class="flex flex-column align-items-center p-0 surface-overlay border-round">
      <h5 class="mb-0">{{ confirmMessage }}</h5>
      <textarea class="m-4 w-full p-inputtextarea p-inputtext" rows="5" cols="30" pInputTextarea [(ngModel)]="eventText"></textarea>
      <div class="flex align-items-center gap-2 mt-4">
        <button pButton label="{{'hydroponics.button.no' | translate}}" (click)="measureDialogVisible = false" class="p-button-outlined w-8rem "></button>
        <button pButton label="{{'hydroponics.button.yes' | translate}}" (click)="acceptStatusChange()" class="w-8rem"></button>
      </div>
    </div>
  </ng-template>
</p-dialog>

<p-dialog modal="true" [(visible)]="dialogVisible">
  <div *ngIf="selectedNode">
    <app-plant-profile-attribute-manager
      [title] = "'hydroponics.location.typeOfPlantQuestion' | translate"
      [isViewOnly] = "false"
      [plantProfileEditable] = selectedNode.profileEditable
      [defaultProfile] = selectedNode.defaultProfile
      [id] = selectedNode.id
      [type] = getProfileType()
      [selectedProfile] = selectedNode.plantProfile
      (onPlantProfileSave) = "onPlantProfileSave($event)"
      [loadPlantProfileSubject]="loadPlantProfileSubject">
    </app-plant-profile-attribute-manager>
  </div>
</p-dialog>
<app-alert-table [visible]=this.alertDialogVisible
                 (closeTable)="tableClosed()"
                 >
</app-alert-table>
