import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DashboardComponent} from './dashboard/dashboard.component';
import {AppAuthGuard} from "./auth/app-auth-guard.guard";
import {ProfileComponent} from "./profile/profile/profile.component";
import {AuthComponent} from "./auth/auth.component";
import {MatDivider} from "@angular/material/divider";
import {DemoComponent} from "./demo/demo.component";
import {DeviceOperationFlowComponent} from "./device-operation-flow/device-operation-flow.component";
import {PlantProfileComponent} from "./components/plant-profile/plant-profile.component";
import {LocationsComponent} from "./components/locations/locations.component";
import {DosageComponent} from "./components/dosage/dosage.component";
import {
  SensorMeasureDashboardComponent
} from "./components/sensor-measure-dashboard/sensor-measure-dashboard.component";
import {WorksheetDashboardComponent} from "./components/worksheet-dashboard/worksheet-dashboard.component";
import {CalibrationBoardComponent} from "./components/calibration-board/calibration-board.component";

export const DEFAULT_ROUTE = '';
export const DASHBOARD_ROUTE = 'dashboard';
export const DEVICE_OPERATION_FLOW_ROUTE = 'device-operation-flow';
export const PROFILE_ROUTE = 'profile';
export const DEMO_ROUTE = 'demo';
export const PLANT_PROFILE_ROUTE = 'plant-profiles';
export const DOSAGE_ROUTE = 'dosage';
export const LOCATIONS_ROUTE = 'locations';
export const SENSORS_ROUTE = 'sensors';
export const CALIBRATION_ROUTE = 'calibration';
export const WORKSHEET = 'worksheet';

const routes: Routes = [
  {path: WORKSHEET +  '/:type/:id', component: WorksheetDashboardComponent, canActivate: [AppAuthGuard]},
  {path: WORKSHEET, component: WorksheetDashboardComponent, canActivate: [AppAuthGuard]},
  {path: PROFILE_ROUTE, component: ProfileComponent, canActivate: [AppAuthGuard]},
  {path: PLANT_PROFILE_ROUTE, component: PlantProfileComponent, canActivate: [AppAuthGuard]},
  {path: DOSAGE_ROUTE, component: DosageComponent, canActivate: [AppAuthGuard]},
  {path: LOCATIONS_ROUTE, component: LocationsComponent, canActivate: [AppAuthGuard]},
  {path: SENSORS_ROUTE, component: SensorMeasureDashboardComponent, canActivate: [AppAuthGuard]},
  {path: CALIBRATION_ROUTE, component: CalibrationBoardComponent, canActivate: [AppAuthGuard]},
  {path: DASHBOARD_ROUTE, component: DashboardComponent, canActivate: [AppAuthGuard]},
  {path: DEVICE_OPERATION_FLOW_ROUTE, component: DeviceOperationFlowComponent, canActivate: [AppAuthGuard]},
  {path: DEMO_ROUTE, component: DemoComponent},
  //Keycloak guard default
  {path: '**', redirectTo: DASHBOARD_ROUTE},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AppAuthGuard, AuthComponent, MatDivider],
})
export class AppRoutingModule {

}
