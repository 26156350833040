<app-chart-filter
  [deviceTypes]="[DeviceTypeEnum.SENSORBOARD]"
  [isOnlyDeviceSelectable]="false"
  (onDateChange)="onDateChange($event)"
  (onAttributeChange)="onAttributeChange($event)"
  (onFrequenciesChange)="onFrequenciesChange($event)"
  (onLocationChange)="locationChanged($event)"
  [isFrequencyFilter]="false"
  [usedAttributes]="Array.of(SensorDataType.EC, SensorDataType.PH, SensorDataType.ORP)">
</app-chart-filter>

<div>
  <app-sensor-charts
    [operationTypes]="[OperationType.CALIBRATION, OperationType.OPTIMAL_ATTRIBUTE]"
    [calibrationTypes]="['EC','PH','ORP']"
    [isWorksheetsBarVisible]="false"
    [chartFilterChangeSubjectSensor]="chartFilterChangeSubjectSensor"
    [chartFilterFrequenciesChangeSubjectSensor]="chartFilterFrequenciesChangeSubjectSensor"
    [chartFilterAttributeChangeSubjectSensor]="chartFilterAttributeChangeSubjectSensor">
  </app-sensor-charts>
</div>
