<div class="flex flex-column align-items-center justify-content-center">
  <h4 class="mb-3">{{title}}</h4>
  <div class="mb-3">
    <label>{{'hydroponics.plantProfiles.plantName' | translate}}:</label>
    <p-dropdown class="plant-selector" appendTo="body" *ngIf="plantProfiles.length" [options]="plantProfiles" [(ngModel)]="selectedProfile" optionLabel="name"
                [disabled]="!plantProfileEditable && type != ProfileType.DEFAULT" (onChange)="onChangeProfile()"
                [showClear]="true" placeholder="{{'hydroponics.plantProfiles.selectPlant' | translate}}"></p-dropdown>
  </div>

  <p-checkbox *ngIf="type != ProfileType.DEFAULT" [disabled]="!plantProfileEditable" [(ngModel)]="defaultProfile"
              (onChange)="onUseDefaultAttributes()" class="mb-3"
              [binary]="true" inputId="binary" label="{{'hydroponics.location.useDefaultAttributes' | translate}}"></p-checkbox>

  <div class="d-grid grid-fit-400 w-full align-items-center place-content-center">
    <ng-container *ngIf="selectedProfile">
      <ng-container *ngFor="let attribute of modifiedAttributes" >
        <div class="d-grid align-items-center grid-c-50-50 mb-3" >
          <div
            class="flex align-items-center justify-content-start">{{ 'hydroponics.common.measurement.' + attribute.code | translate }}
            :
          </div>
          <div class="flex align-items-center justify-content-start">
            <input [disabled]="defaultProfile || !plantProfileEditable" pInputText id="minValue" type="text"
                   [(ngModel)]="attribute.minValue" class="p-inputtext input-small"/> -
            <input [disabled]="defaultProfile || !plantProfileEditable" pInputText id="maxValue" type="text"
                   [(ngModel)]="attribute.maxValue" class="p-inputtext input-small"/>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <p-button *ngIf="plantProfileEditable" label="{{'hydroponics.button.save' | translate}}" (onClick)="plantProfileSave()"></p-button>
</div>
