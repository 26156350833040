import { Component, OnInit } from '@angular/core';
import {DeviceDto} from "../../../generated/hydroponics-device-management-api/models/device-dto";

@Component({
  selector: 'app-demo-app-header',
  templateUrl: './demo-app-header.component.html',
  styleUrls: ['./../../template/header/header.component.sass']
})
export class DemoAppHeaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
