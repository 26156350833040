import {Component} from '@angular/core';
import {BaseSensorComponent} from "../../components/device/sensorboard/base-sensor/base-sensor.component";
import {
  DeviceManagementImplService
} from "../../../generated/hydroponics-device-management-api/services/device-management-impl.service";
import {ExcelService} from "../../util/excel.service";
import {TranslateService} from "@ngx-translate/core";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatDialog} from "@angular/material/dialog";
import {DemoSensorManagementImplService} from "../demo-sensor-management-impl.service";
import {MessageService} from "../../service/message.service";
import {
  WorkbookControllerImplService
} from "../../../generated/hydroponics-device-management-api/services/workbook-controller-impl.service";

@Component({
  selector: 'app-demo-natrium-sensor',
  templateUrl: '../../components/device/sensorboard/base-sensor/base-sensor.component.html',
  styleUrls: ['../../components/device/sensorboard/base-sensor/base-sensor.component.sass']
})
export class DemoNatriumSensorComponent extends BaseSensorComponent {

  constructor(deviceManagementImplService: DeviceManagementImplService,
              sensorManagementService: DemoSensorManagementImplService,
              excel: ExcelService,
              workbookService: WorkbookControllerImplService,
              translate: TranslateService,
              snackBar: MatSnackBar,
              dialog: MatDialog,
              messageService: MessageService,) {
    super(deviceManagementImplService,
      sensorManagementService,
      excel,
      workbookService,
      translate,
      snackBar,
      dialog,
      messageService,
    );
  }
}
