import { InjectionToken } from '@angular/core';
import { ApiConfigurationParams as HydroponicsDeviceManagementApiConfigurationParams} from 'src/generated/hydroponics-device-management-api/api-configuration';
import { ApiConfigurationParams as HydroponicsUserManagementApiConfigurationParams} from 'src/generated/hydroponics-user-management-api/api-configuration';
import {BehaviorSubject, Observable, skipWhile, takeWhile} from "rxjs";
import {KeycloakProfile} from "keycloak-js";
export interface Configuration {
  production: boolean;
  deviceManagementApiUrl: string;
  userManagementApiUrl: string;
}

// We use a dependency injection token to access the configuration in our application.
export const configurationToken = new InjectionToken('Configuration');

const configJson=new BehaviorSubject(null);
export const configurationPath = '/configuration/configuration.json';
let keycloakUrl: string;

export function getHydroponicsDeviceManagementApiConfigurationParams(): HydroponicsDeviceManagementApiConfigurationParams {
  const config: HydroponicsDeviceManagementApiConfigurationParams = {};
  // configJson.pipe(skipWhile(val => !!val)).subscribe(configuration=>{
  //   config.rootUrl = configuration.deviceManagementApiUrl;
  // });
  // return config;
  fetch(configurationPath)
    .then(response => response.json())
    .then(configuration => {
      config.rootUrl = configuration.deviceManagementApiUrl;
    })
    .catch(error => {
      console.error('Cannot find configuration file: ' + configurationPath, error);
      throw error;
    });
  return config;
}
export function getHydroponicsUserManagementApiConfigurationParams(): HydroponicsUserManagementApiConfigurationParams {
  const config: HydroponicsUserManagementApiConfigurationParams = {};
  fetch(configurationPath)
    .then(response => response.json())
    .then(configuration => {
      config.rootUrl = configuration.userManagementApiUrl;
    })
    .catch(error => {
      console.error('Cannot find configuration file: ' + configurationPath, error);
      throw error;
    });
  return config;
}

export function getConfig() {
  let keycloakUrl: string;

  return fetch(configurationPath)
    .then(response => response.json())
    .then(configuration => {
      configJson.next(configuration);
      return configuration;
    })
    .catch(error => {
      console.error('Cannot find configuration file: ' + configurationPath, error);
      throw error;
    });

}

// function getConfigData(): Observable<any> {
//   // let keycloakUrl: string;
//   return configJson.pipe(takeWhile(value => !!value));
// }
