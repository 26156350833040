<p-card *ngIf="this.numberOfTotalElements > 0" class="alert-card">
  <i class="pi pi-exclamation-triangle mr-2 vertical-align-middle"></i>

  <i [ngSwitch]="this.numberOfTotalElements">
    <i *ngSwitchCase="1" class="font-style-normal">
      {{ this.alerts[0].alert.description }}</i>

    <i *ngSwitchDefault class="font-style-normal">
      {{ this.numberOfTotalElements + " " }} {{ 'hydroponics.alert.error_message' | translate }}</i>
  </i>

  <i class="pi pi-list" (click)="showDialog()"></i>

  <app-alert-table [visible]=this.visible
                   (closeTable)="tableClosed()"
                   (onHideAlert)="getAlerts()">
  </app-alert-table>
</p-card>
