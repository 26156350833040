import {Directive, Input, Renderer2, TemplateRef, ViewContainerRef} from '@angular/core';
import {AuthComponent} from "../../auth.component";
import {Roles} from "../roles";

@Directive({
  selector: '[hasRole]',
})
export class RoleDirective {

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
    private renderer: Renderer2,
    private permissionService: AuthComponent
  ) { }

  @Input()
  set hasRole(userPermission : Roles) {
    this.decideView(Roles[userPermission]);
  }

  decideView(userPermission: Roles) {
    if(this.permissionService.hasRole(userPermission.toString())){
      this.showComponent();
    }else{
      this.removeComponent();
    }
  }

  disableComponent(): void {
    this.viewContainerRef.clear();
    const viewRootElement : HTMLElement = this.viewContainerRef.createEmbeddedView(
      this.templateRef
    ).rootNodes[0];
    viewRootElement.setAttribute('style', 'background-color: grey');
    this.renderer.setProperty(viewRootElement, 'disabled', true);
  }

  enableComponent(): void {
    this.viewContainerRef.clear();
    const viewRootElement : HTMLElement = this.viewContainerRef.createEmbeddedView(
      this.templateRef
    ).rootNodes[0];
    this.renderer.setProperty(viewRootElement, 'disabled', false);
  }

  removeComponent(): void {
    this.viewContainerRef.clear();
  }

  showComponent(): void {
    this.viewContainerRef.clear();
    this.viewContainerRef.createEmbeddedView(
      this.templateRef
    ).rootNodes[0];
  }


}
