/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PageWorksheetDto } from '../models/page-worksheet-dto';
import { Pageable } from '../models/pageable';
import { SaveWorksheetCommentRequestDto } from '../models/save-worksheet-comment-request-dto';
import { SaveWorksheetRequestDto } from '../models/save-worksheet-request-dto';
import { WorksheetCommentDto } from '../models/worksheet-comment-dto';
import { WorksheetRequestDto } from '../models/worksheet-request-dto';

@Injectable({
  providedIn: 'root',
})
export class WorksheetControllerImplService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation closeWorksheet
   */
  static readonly CloseWorksheetPath = '/worksheet/close-worksheet';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `closeWorksheet()` instead.
   *
   * This method doesn't expect any request body.
   */
  closeWorksheet$Response(params: {
    worksheetId: number;
    'X-Authenticated-User'?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, WorksheetControllerImplService.CloseWorksheetPath, 'put');
    if (params) {
      rb.query('worksheetId', params.worksheetId, {});
      rb.header('X-Authenticated-User', params['X-Authenticated-User'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `closeWorksheet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  closeWorksheet(params: {
    worksheetId: number;
    'X-Authenticated-User'?: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.closeWorksheet$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getWorksheets
   */
  static readonly GetWorksheetsPath = '/worksheet/get-worksheets';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWorksheets()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getWorksheets$Response(params: {
    pageable: Pageable;
    context?: HttpContext
    body: WorksheetRequestDto
  }
): Observable<StrictHttpResponse<PageWorksheetDto>> {

    const rb = new RequestBuilder(this.rootUrl, WorksheetControllerImplService.GetWorksheetsPath, 'post');
    if (params) {
      rb.query('pageable', params.pageable, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PageWorksheetDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getWorksheets$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getWorksheets(params: {
    pageable: Pageable;
    context?: HttpContext
    body: WorksheetRequestDto
  }
): Observable<PageWorksheetDto> {

    return this.getWorksheets$Response(params).pipe(
      map((r: StrictHttpResponse<PageWorksheetDto>) => r.body as PageWorksheetDto)
    );
  }

  /**
   * Path part for operation createWorksheet
   */
  static readonly CreateWorksheetPath = '/worksheet/create-worksheet';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createWorksheet()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createWorksheet$Response(params: {
    'X-Authenticated-User'?: string;
    context?: HttpContext
    body: SaveWorksheetRequestDto
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, WorksheetControllerImplService.CreateWorksheetPath, 'post');
    if (params) {
      rb.header('X-Authenticated-User', params['X-Authenticated-User'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createWorksheet$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createWorksheet(params: {
    'X-Authenticated-User'?: string;
    context?: HttpContext
    body: SaveWorksheetRequestDto
  }
): Observable<void> {

    return this.createWorksheet$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation createWorksheetComment
   */
  static readonly CreateWorksheetCommentPath = '/worksheet/create-worksheet-comment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createWorksheetComment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createWorksheetComment$Response(params: {
    'X-Authenticated-User'?: string;
    context?: HttpContext
    body: SaveWorksheetCommentRequestDto
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, WorksheetControllerImplService.CreateWorksheetCommentPath, 'post');
    if (params) {
      rb.header('X-Authenticated-User', params['X-Authenticated-User'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createWorksheetComment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createWorksheetComment(params: {
    'X-Authenticated-User'?: string;
    context?: HttpContext
    body: SaveWorksheetCommentRequestDto
  }
): Observable<void> {

    return this.createWorksheetComment$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getWorksheetComments
   */
  static readonly GetWorksheetCommentsPath = '/worksheet/get-worksheet-comments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWorksheetComments()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWorksheetComments$Response(params: {
    worksheetId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<WorksheetCommentDto>>> {

    const rb = new RequestBuilder(this.rootUrl, WorksheetControllerImplService.GetWorksheetCommentsPath, 'get');
    if (params) {
      rb.query('worksheetId', params.worksheetId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<WorksheetCommentDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getWorksheetComments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWorksheetComments(params: {
    worksheetId: number;
    context?: HttpContext
  }
): Observable<Array<WorksheetCommentDto>> {

    return this.getWorksheetComments$Response(params).pipe(
      map((r: StrictHttpResponse<Array<WorksheetCommentDto>>) => r.body as Array<WorksheetCommentDto>)
    );
  }

  /**
   * Path part for operation getCountWorksheets
   */
  static readonly GetCountWorksheetsPath = '/worksheet/get-count-worksheets';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCountWorksheets()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCountWorksheets$Response(params: {
    deviceId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<number>> {

    const rb = new RequestBuilder(this.rootUrl, WorksheetControllerImplService.GetCountWorksheetsPath, 'get');
    if (params) {
      rb.query('deviceId', params.deviceId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCountWorksheets$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCountWorksheets(params: {
    deviceId: string;
    context?: HttpContext
  }
): Observable<number> {

    return this.getCountWorksheets$Response(params).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

}
