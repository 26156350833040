import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService as PrimeMessageService } from 'primeng/api';

/**
 * Hiba üzenetek megjelenítéséért felel
 */
@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor(private primeMessageService: PrimeMessageService,
              private translate: TranslateService) {}

  success(messageParam?: string, pureMessage: boolean = false) {
    let snackMessage = messageParam;
    if (snackMessage && !pureMessage) {
      this.translate.get(snackMessage).subscribe((message: any) => {
        snackMessage = message;
        this.showSuccessMessage(snackMessage);
      });
    } else {
      this.showSuccessMessage(snackMessage || 'Success');
    }
  }

  error(messageParam?: string, pureMessage: boolean = false) {
    let snackMessage = messageParam;
    if (snackMessage && !pureMessage) {
      this.translate.get(snackMessage).subscribe((message: any) => {
        snackMessage = message;
        this.showErrorMessage(snackMessage);
      });
    } else {
      this.showErrorMessage(snackMessage || 'An error occurred');
    }
  }

  warning(messageParam?: string, pureMessage: boolean = false) {
    let snackMessage = messageParam;
    if (snackMessage && !pureMessage) {
      this.translate.get(snackMessage).subscribe((message: any) => {
        snackMessage = message;
        this.showWarningMessage(snackMessage);
      });
    } else {
      this.showWarningMessage(snackMessage || 'Warn');
    }
  }

  private showSuccessMessage(message: string) {
    this.primeMessageService.add({severity: 'success', summary: 'Success', detail: message, life: 5000});
  }

  private showWarningMessage(message: string) {
    this.primeMessageService.add({severity: 'warn', summary: 'Warn', detail: message, life: 5000});
  }

  private showErrorMessage(message: string) {
    this.primeMessageService.add({severity: 'error', summary: 'Error', detail: message, life: 5000});
  }
}
