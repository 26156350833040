import {Component, Input, OnInit} from '@angular/core';
import {DeviceDto} from "../../../../generated/hydroponics-device-management-api/models/device-dto";
import {NutritionDispenserService} from "./nutrition-dispenser.service";
import {DeviceTypeDto} from "../../../../generated/hydroponics-device-management-api/models/device-type-dto";
import {LocationReferenceDto} from "../../../../generated/hydroponics-device-management-api/models/location-reference-dto";
import {DeviceGroupDto} from "../../../../generated/hydroponics-device-management-api/models/device-group-dto";
import {FormControl} from "@angular/forms";

class ExtDeviceDTO implements DeviceDto {
  deviceId?: string;
  deviceMac?: string;
  deviceType?: DeviceTypeDto;
  locationReferenceDTO?: LocationReferenceDto;
  name?: string;
  searchName?: string;
  deviceGroup?: ExtDeviceGroupDTO;
}

class ExtDeviceGroupDTO implements DeviceGroupDto {
  locationReferenceDTO?: LocationReferenceDto;
  name?: string;
  devices?: ExtDeviceDTO[];
  searchName?: string;
}

@Component({
  selector: 'app-nutrition-dispenser',
  templateUrl: './nutrition-dispenser.component.html',
  styleUrls: ['./nutrition-dispenser.component.sass']
})
export class NutritionDispenserComponent implements OnInit {
  _device: ExtDeviceDTO;
  groupedDevice = false;
  devices: ExtDeviceDTO[];
  public devicesForm = new FormControl<ExtDeviceDTO>({});


  @Input() set device(device: DeviceDto) {
    this._device = device;
    this.refreshData();
  }

  get device(): ExtDeviceDTO {
    return this._device;
  }

  constructor(private nutritionDispenserService: NutritionDispenserService) {
    this.refreshData();
  }

  private refreshData() {
    this.nutritionDispenserService.initNutritionDeviceCurrentParameters(this._device.deviceId);
  }

  ngOnInit(): void {
    if (this._device.deviceGroup != null) {
      this.groupedDevice = true;
      this.devices = this._device.deviceGroup.devices;
      this.devicesForm.setValue(this.devices[0]);
    }
  }

  deviceSelected(selectedDevice: ExtDeviceDTO) {
    this._device = selectedDevice;
  }
}
