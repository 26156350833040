import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {firstValueFrom, Subject, Subscription} from "rxjs";
import {WorksheetRequestDto} from "../../../generated/hydroponics-device-management-api/models/worksheet-request-dto";
import {EventRequestDto} from "../../../generated/hydroponics-device-management-api/models/event-request-dto";
import * as moment from "moment-timezone";
import {ChartDevice} from "../sensor-measure-dashboard/sensor-measure-dashboard.component";
import {ExtendedChartData} from "../sensor-charts/sensor-charts.component";
import {WorksheetDto} from "../../../generated/hydroponics-device-management-api/models/worksheet-dto";
import {EventDto} from "../../../generated/hydroponics-device-management-api/models/event-dto";
import {
  WorksheetControllerImplService
} from "../../../generated/hydroponics-device-management-api/services/worksheet-controller-impl.service";
import {
  EventManagerControllerImplService
} from "../../../generated/hydroponics-device-management-api/services/event-manager-controller-impl.service";
import {ChartFilterService} from "../chart-filter/chart-filter.service";
import {LoadAlertsAndWorksheetsNumberService} from "../../service/load-alerts-and-worksheets-number.service";

@Component({
  selector: 'app-worksheet-event-list',
  templateUrl: './worksheet-event-list.component.html',
  styleUrls: ['./worksheet-event-list.component.sass']
})
export class WorksheetEventListComponent implements OnInit, OnDestroy {

  @Input('chartFilterChangeSubjectSensor') chartFilterChangeSubjectSensor: Subject<any>;
  @Input() isWorksheetsBarVisible: boolean = true;

  private chartDevices: ChartDevice[];
  protected workElement: WorksheetDto[] | EventDto[];
  private locationIds: any[];
  protected rangeDates: Date[];
  protected deviceData: { [p: string]: ExtendedChartData } = {}
  private loadAlertsAndWorksheetsNumberServiceSubscription: Subscription;

  constructor(private readonly worksheetControllerImplService: WorksheetControllerImplService,
              private readonly eventControllerImplService: EventManagerControllerImplService,
              public chartFilterService: ChartFilterService,
              private loadAlertsAndWorksheetsNumberService: LoadAlertsAndWorksheetsNumberService,) {
  }

  ngOnInit(): void {
    console.log(this.chartFilterChangeSubjectSensor)
    if (this.chartFilterChangeSubjectSensor) {
      this.chartFilterChangeSubjectSensor.subscribe(changeParams => {
        this.rangeDates = changeParams.rangeDates;
        this.chartDevices = changeParams.chartDevices;
        this.locationIds = changeParams.locationIds;

        this.deviceData = {};
        console.log(this.chartDevices)

        if ((this.chartDevices && this.chartDevices.length > 0) || (this.locationIds && this.locationIds.length > 0)) {
          if (this.isWorksheetsBarVisible == true) {
            this.loadData(this.rangeDates[0], this.rangeDates[1]);
          }
        }
      });
    }

    this.loadAlertsAndWorksheetsNumberServiceSubscription = this.loadAlertsAndWorksheetsNumberService.event$.subscribe(value => {
      this.loadData(this.rangeDates[0], null);
    });

    this.chartFilterService.markInitialized();
  }

  private loadData(startDate: Date, endDate: Date) {
    this.workElement = []
    let deviceIds = []

    if (this.chartDevices && this.chartDevices.length > 0) {
      this.chartDevices.forEach((chartDevice, key) => {
        deviceIds.push(chartDevice.deviceId)
      })
    }

    let request: WorksheetRequestDto | EventRequestDto = {
      startDate: moment(startDate).tz('Europe/Budapest').format('yyyy-MM-DDTHH:mm:ss.SSS'),
      endDate: endDate == null ? null : moment(endDate).tz('Europe/Budapest').format('yyyy-MM-DDTHH:mm:ss.SSS'),
      deviceIds: deviceIds,
      locationIds: this.locationIds
    }

    const recentWorksheets = firstValueFrom(this.worksheetControllerImplService.getAllWorksheets({
      body: request,
    }));
    const recentEvents = firstValueFrom(this.eventControllerImplService.getAllEvents({
      body: request,
    }));

    Promise.all([recentEvents, recentWorksheets]).then(([worksheets, events]) => {
      console.log([...events,...worksheets])
      this.workElement = [...events, ...worksheets].sort((a, b) => {
        return new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime(); // Csökkenő sorrend
      });
    });
  }

  onWorksheetChange() {
    this.loadData(this.rangeDates[0], null);
  }

  ngOnDestroy(): void {
    if (this.chartFilterChangeSubjectSensor) {
      this.chartFilterChangeSubjectSensor.unsubscribe();
    }
  }
}
