<app-chart-filter
  (onDateChange)="onDateChange($event)"
  (onLocationChange)="onLocationChange($event)"
  (onAttributeChange)="onAttributeChange($event)"
  (onFrequenciesChange)="onFrequenciesChange($event)"
  [deviceTypes]="[DeviceTypeEnum.SENSORBOARD]"
  [isWorksheetFilter]="false">
</app-chart-filter>
<div>
  <div [ngClass]="{'dashboard-container': true}">
    <app-sensor-charts
      [operationTypes]="[OperationType.OPTIMAL_ATTRIBUTE,OperationType.DOWNLOAD_AUTO,OperationType.DOWNLOAD_MANUAL, OperationType.M_MEASURE]"
      [chartFilterChangeSubjectSensor]="chartFilterChangeSubjectSensor"
      [chartFilterFrequenciesChangeSubjectSensor]="chartFilterFrequenciesChangeSubjectSensor"
      [chartFilterAttributeChangeSubjectSensor]="chartFilterAttributeChangeSubjectSensor">
    </app-sensor-charts>
    <app-worksheet-event-list
      [chartFilterChangeSubjectSensor]="chartFilterChangeSubjectSensor">
    </app-worksheet-event-list>
  </div>
  <h3 class="flex flex-wrap align-content-start align-items-start no-chosen-device-or-location-warning"
      *ngIf="this.chartDevices.length == 0 && this.locationIds.length == 0">
    {{ 'hydroponics.worksheet.noLocationOrDeviceChosen' | translate }}
  </h3>
</div>
